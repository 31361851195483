import { Epic } from 'redux-observable';
import { timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { TEST_RUNS_REFRESH_INTERVAL, TRAINING_DETAIL_REFRESH_INTERVAL } from '../../constants';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, takeUntilAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';
import {
  createTraining,
  fetchTrainingDetails,
  fetchTrainings,
  pauseTraining,
  startTraining,
  stopTraining,
  labelRecordings,
  updateTrainingDetails,
} from './actions';

const fetchTrainingsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { trainingService }) =>
  action$.pipe(
    filter(isActionOf(fetchTrainings.request)),
    switchMap(({ payload }) =>
      timer(0, TEST_RUNS_REFRESH_INTERVAL).pipe(
        takeUntilAction(action$, fetchTrainings.cancel),
        switchMap(() =>
          trainingService
            .getTrainings(payload)
            .pipe(
              map(fetchTrainings.success),
              catchErrorAndHandleWithAction(fetchTrainings.failure),
              takeUntilAction(action$, fetchTrainings.cancel)
            )
        )
      )
    )
  );

const createTrainingEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { trainingService }) =>
  action$.pipe(
    filter(isActionOf(createTraining.request)),
    switchMap(({ payload }) =>
      trainingService
        .createNewTraining(payload)
        .pipe(map(createTraining.success), catchErrorAndHandleWithAction(createTraining.failure))
    )
  );

const fetchTrainingDetailsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { trainingService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTrainingDetails.request)),
    switchMap(({ payload }) =>
      timer(0, TRAINING_DETAIL_REFRESH_INTERVAL).pipe(
        takeUntilAction(action$, fetchTrainingDetails.cancel),
        switchMap(() =>
          trainingService
            .getTrainingDetails(payload)
            .pipe(
              map(fetchTrainingDetails.success),
              catchErrorAndHandleWithAction(fetchTrainingDetails.failure),
              takeUntilAction(action$, fetchTrainingDetails.cancel)
            )
        )
      )
    )
  );

const pauseTrainingEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { trainingService }) =>
  action$.pipe(
    filter(isActionOf(pauseTraining.request)),
    switchMap(({ payload }) =>
      trainingService
        .pauseTraining(payload)
        .pipe(map(pauseTraining.success), catchErrorAndHandleWithAction(pauseTraining.failure))
    )
  );

const startTrainingEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { trainingService }) =>
  action$.pipe(
    filter(isActionOf(startTraining.request)),
    switchMap(({ payload }) =>
      trainingService
        .startTraining(payload)
        .pipe(map(startTraining.success), catchErrorAndHandleWithAction(startTraining.failure))
    )
  );
const stopTrainingEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { trainingService }) =>
  action$.pipe(
    filter(isActionOf(stopTraining.request)),
    switchMap(({ payload }) =>
      trainingService
        .stopTraining(payload)
        .pipe(map(stopTraining.success), catchErrorAndHandleWithAction(stopTraining.failure))
    )
  );

const labelRecordingsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { trainingService }) =>
  action$.pipe(
    filter(isActionOf(labelRecordings.request)),
    switchMap(({ payload }) =>
      trainingService
        .labelRecordings(payload)
        .pipe(
          map(labelRecordings.success),
          catchErrorAndHandleWithAction(labelRecordings.failure),
          takeUntilAction(action$, labelRecordings.cancel)
        )
    )
  );

const updateTrainingDetailsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { trainingService }
) =>
  action$.pipe(
    filter(isActionOf(updateTrainingDetails.request)),
    switchMap(({ payload }) =>
      trainingService
        .updateDescription(payload)
        .pipe(
          map(updateTrainingDetails.success),
          catchErrorAndHandleWithAction(updateTrainingDetails.failure),
          takeUntilAction(action$, updateTrainingDetails.cancel)
        )
    )
  );

export default [
  fetchTrainingsEpic,
  fetchTrainingDetailsEpic,
  createTrainingEpic,
  pauseTrainingEpic,
  startTrainingEpic,
  stopTrainingEpic,
  labelRecordingsEpic,
  updateTrainingDetailsEpic
];
