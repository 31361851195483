import { CREATED_END_STR, CREATED_START_STR } from '../../constants';
import { Field, FieldProps } from 'formik';
import { Input, Select } from '@porsche/ui-kit-react';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles, makeStyles } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import { FilterConfiguration } from '../../models';
import React from 'react';
import cx from 'clsx';
import { getModalStyles } from './ListFilterModal';

type Props = {
  setFieldValue: (field: string, value: any) => void;
  formConfiguration: FilterConfiguration;
};

const useStyles = makeStyles(createStyles({ ...getModalStyles() }), { name: 'CycleFailureFilter' });

export const CycleFailureFilter: React.FC<Props> = ({ setFieldValue, formConfiguration }) => {
  const c = useStyles({});

  const idStr = 'humanReadableId';
  // const cycleNumberStr = 'currentCycle';
  const temperatureStr = 'temperature';
  const customerRelevantStr = 'customerRelevant';
  const carModelStr = 'carModel';
  const componentNameStr = 'componentName';

  return (
    <>
      <div className={c.inputContainerRow}>
        <Field name={idStr}>
          {({ field }: FieldProps) => (
            <Input
              name={field.name}
              type="text"
              className={cx(c.input, c.fixedWidthInput)}
              value={field.value || ''}
              placeholder={'Aufnahme-ID'}
              onChange={(val) => setFieldValue(field.name, val)}
            />
          )}
        </Field>
        <Field name={componentNameStr}>
          {({ field }: FieldProps) => (
            <Select
              clearable={true}
              className={cx(c.input, c.select, c.fixedWidthInput, c.porscheStyleFix)}
              placeholder={'Komponente'}
              value={field.value}
              options={formConfiguration.elementConfigurations
                .find((filterConfiguration) => filterConfiguration.name === field.name)
                .options.map((option) => ({ value: option, label: option }))}
              onChange={(val) => setFieldValue(field.name, val)}
            />
          )}
        </Field>
        <Field name={carModelStr}>
          {({ field }: FieldProps) => (
            <Select
              clearable={true}
              className={cx(c.input, c.select, c.fixedWidthInput, c.porscheStyleFix)}
              placeholder={'Station'}
              value={field.value}
              options={formConfiguration.elementConfigurations
                .find((filterConfiguration) => filterConfiguration.name === field.name)
                .options.map((option) => ({ value: option, label: option }))}
              onChange={(val) => setFieldValue(field.name, val)}
            />
          )}
        </Field>
        {/* <Field name={cycleNumberStr}>
          {({ field }: FieldProps) => (
            <Input
              name={field.name}
              type="number"
              className={cx(c.input, c.fixedWidthInput)}
              value={field.value || ''}
              placeholder={'Zyklus'}
              onChange={(val) => setFieldValue(field.name, +val)}
            />
          )}
        </Field> */}
      </div>

      <div className={c.inputContainerRow}>
        <Field name={temperatureStr}>
          {({ field }: FieldProps) => (
            <Input
              name={field.name}
              type="number"
              className={cx(c.input, c.fixedWidthInput)}
              value={field.value || ''}
              placeholder={'Temperatur (°C)'}
              onChange={(val) => setFieldValue(field.name, +val)}
            />
          )}
        </Field>
        <Field name={customerRelevantStr}>
          {({ field }: FieldProps) => (
            <Select
              clearable={true}
              className={cx(c.input, c.select, c.fixedWidthInput, c.porscheStyleFix)}
              placeholder={'Kundenrelevant'}
              value={field.value}
              options={formConfiguration.elementConfigurations
                .find((filterConfiguration) => filterConfiguration.name === field.name)
                .options.map((option) => ({
                  value: option,
                  label: option === 'true' ? 'ja' : option === 'false' ? 'nein' : '-',
                }))}
              onChange={(val) => setFieldValue(field.name, val)}
            />
          )}
        </Field>
      </div>
      <div className={c.inputContainerRow}>
        <Field name={CREATED_START_STR}>
          {({ field }: FieldProps) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                autoOk
                clearable
                className={cx(c.firstDateTimeInput, c.dateTimeInput)}
                label="Von"
                inputVariant="outlined"
                variant="dialog"
                ampm={false}
                clearLabel={<span>Löschen</span>}
                cancelLabel={<span>Abbrechen</span>}
                format="dd.MM.yy - HH:mm"
                value={field.value ?? null}
                onChange={(date) => setFieldValue(field.name, date)}
                DialogProps={{ style: { zIndex: 13000 } }}
              />
            </MuiPickersUtilsProvider>
          )}
        </Field>
        <Field name={CREATED_END_STR}>
          {({ field }: FieldProps) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                autoOk
                clearable
                className={c.dateTimeInput}
                label="Bis"
                inputVariant="outlined"
                variant="dialog"
                ampm={false}
                clearLabel={<span>Löschen</span>}
                cancelLabel={<span>Abbrechen</span>}
                format="dd.MM.yy - HH:mm"
                value={field.value ?? null}
                onChange={(date) => setFieldValue(field.name, date)}
                DialogProps={{ style: { zIndex: 13000 } }}
              />
            </MuiPickersUtilsProvider>
          )}
        </Field>
      </div>
    </>
  );
};
