import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import {
  Cycle,
  DetailsQuery,
  DetailsWithRecordingClientQuery,
  FilteredCycles,
  FilteredTestRuns,
  HttpError,
  NewTestRun,
  TestRun,
  TestRunOverview,
} from '../../models';
import { FilteredTestRunCyclesRequestType, FilterRequestType } from '../../models/requestFilter.model';

export const fetchTestRunList = createAsyncAction(
  '@testRuns/FETCH_TEST_RUN_LIST',
  '@testRuns/FETCH_TEST_RUN_LIST_SUCCESS',
  '@testRuns/FETCH_TEST_RUN_LIST_FAILURE',
  '@testRuns/FETCH_TEST_RUN_LIST_CANCEL'
)<FilterRequestType, FilteredTestRuns, HttpError, void>();

export const resetTestRunList = createAction('@testRuns/RESET_TEST_RUN_LIST')<void>();

export const fetchOverview = createAsyncAction(
  '@testRuns/FETCH_TEST_RUN_OVERVIEW',
  '@testRuns/FETCH_TEST_RUN_OVERVIEW_SUCCESS',
  '@testRuns/FETCH_TEST_RUN_OVERVIEW_FAILURE',
  '@testRuns/FETCH_TEST_RUN_OVERVIEW_CANCEL'
)<void, TestRunOverview, HttpError, void>();
export const resetOverview = createAction('@testRuns/RESET_TEST_RUN_OVERVIEW')<void>();

export const createTestRun = createAsyncAction(
  '@testRuns/CREATE_TEST_RUN',
  '@testRuns/CREATE_TEST_RUN_SUCCESS',
  '@testRuns/CREATE_TEST_RUN_FAILURE'
)<NewTestRun, TestRun, HttpError>();

export const fetchFilteredTestRunCycles = createAsyncAction(
  '@testRuns/FETCH_TEST_RUN_CYCLES',
  '@testRuns/FETCH_TEST_RUN_CYCLES_SUCCESS',
  '@testRuns/FETCH_TEST_RUN_CYCLES_FAILURE',
  '@testRuns/FETCH_TEST_RUN_CYCLES_CANCEL'
)<FilteredTestRunCyclesRequestType, FilteredCycles, HttpError, void>();

export const pauseTestRun = createAsyncAction(
  '@testRuns/PAUSE_TEST_RUN',
  '@testRuns/PAUSE_TEST_RUN_SUCCESS',
  '@testRuns/PAUSE_TEST_RUN_FAILURE'
)<DetailsWithRecordingClientQuery, TestRun, HttpError>();

export const startTestRun = createAsyncAction(
  '@testRuns/START_TEST_RUN',
  '@testRuns/START_TEST_RUN_SUCCESS',
  '@testRuns/START_TEST_RUN_FAILURE'
)<DetailsWithRecordingClientQuery, TestRun, HttpError>();

export const stopTestRun = createAsyncAction(
  '@testRuns/STOP_TEST_RUN',
  '@testRuns/STOP_TEST_RUN_SUCCESS',
  '@testRuns/STOP_TEST_RUN_FAILURE'
)<DetailsWithRecordingClientQuery, TestRun, HttpError>();

export const fetchTestRunDetails = createAsyncAction(
  '@testRuns/FETCH_TEST_RUN_DETAILS',
  '@testRuns/FETCH_TEST_RUN_DETAILS_SUCCESS',
  '@testRuns/FETCH_TEST_RUN_DETAILS_FAILURE',
  '@testRuns/FETCH_TEST_RUN_DETAILS_CANCEL'
)<DetailsQuery, TestRun, HttpError, void>();

export const updateCycleFailure = createAction('@testRuns/UPDATE_CYCLE_FAILURE')<Cycle>();

export const updateTestRunDetails = createAsyncAction(
  '@testRuns/UPDATE_TEST_RUN_DETAILS',
  '@testRuns/UPDATE_TEST_RUN_DETAILS_SUCCESS',
  '@testRuns/UPDATE_TEST_RUN_DETAILS_FAILURE',
  '@testRuns/UPDATE_TEST_RUN_DETAILS_CANCEL'
)<TestRun, TestRun, HttpError, void>();

export const updateTestRunInTestRunList = createAction('@testRuns/UPDATE_TEST_RUN_DETAILS_IN_TEST_RUN_LIST')<TestRun>();
export const resetStartTestRunStatus = createAction('@testRuns/RESET_START_TEST_RUN_STATUS')<void>();
export const resetSnackbar = createAction('@testRuns/RESET_TEST_RUN_DETAILS_SNACKBAR')<void>();

export type TestRunAction =
  | ActionType<typeof fetchTestRunList>
  | ActionType<typeof resetTestRunList>
  | ActionType<typeof createTestRun>
  | ActionType<typeof pauseTestRun>
  | ActionType<typeof startTestRun>
  | ActionType<typeof stopTestRun>
  | ActionType<typeof fetchTestRunDetails>
  | ActionType<typeof fetchFilteredTestRunCycles>
  | ActionType<typeof updateTestRunInTestRunList>
  | ActionType<typeof updateTestRunDetails>
  | ActionType<typeof resetStartTestRunStatus>
  | ActionType<typeof resetSnackbar>
  | ActionType<typeof fetchOverview>
  | ActionType<typeof resetOverview>
  | ActionType<typeof updateCycleFailure>;
