// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    FilterDTO,
    NewTestRunDTO,
    TestRunDTO,
    TestRunListDTO,
    TestRunsOverviewDTO,
    UpdateTestRunDTO,
} from '../models';

export interface CreateTestRunUsingPOSTRequest {
    newTestRun: NewTestRunDTO;
}

export interface GetAllUsingPOSTRequest {
    filterDTO: FilterDTO;
}

export interface GetTestRunByIdUsingGETRequest {
    testRunId: string;
    fromDate?: string;
    toDate?: string;
}

export interface PauseTestRunUsingGETRequest {
    recordingClientId: string;
    testRunId: string;
}

export interface StartTestRunUsingGETRequest {
    recordingClientId: string;
    testRunId: string;
}

export interface StopTestRunUsingGETRequest {
    recordingClientId: string;
    testRunId: string;
}

export interface UpdateTestRunUsingPATCHRequest {
    testRunId: string;
    updates: UpdateTestRunDTO;
}

/**
 * no description
 */
export class TestRunControllerApi extends BaseAPI {

    /**
     * createTestRun
     */
    createTestRunUsingPOST({ newTestRun }: CreateTestRunUsingPOSTRequest): Observable<TestRunDTO>
    createTestRunUsingPOST({ newTestRun }: CreateTestRunUsingPOSTRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TestRunDTO>
    createTestRunUsingPOST({ newTestRun }: CreateTestRunUsingPOSTRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestRunDTO>>
    createTestRunUsingPOST({ newTestRun }: CreateTestRunUsingPOSTRequest, opts?: OperationOpts): Observable<TestRunDTO | RawAjaxResponse<TestRunDTO>> {
        throwIfNullOrUndefined(newTestRun, 'createTestRunUsingPOST');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<TestRunDTO>({
            url: '/testRuns/new',
            method: 'POST',
            headers,
            body: newTestRun,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * getAll
     */
    getAllUsingPOST({ filterDTO }: GetAllUsingPOSTRequest): Observable<TestRunListDTO>
    getAllUsingPOST({ filterDTO }: GetAllUsingPOSTRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TestRunListDTO>
    getAllUsingPOST({ filterDTO }: GetAllUsingPOSTRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestRunListDTO>>
    getAllUsingPOST({ filterDTO }: GetAllUsingPOSTRequest, opts?: OperationOpts): Observable<TestRunListDTO | RawAjaxResponse<TestRunListDTO>> {
        throwIfNullOrUndefined(filterDTO, 'getAllUsingPOST');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<TestRunListDTO>({
            url: '/testRuns',
            method: 'POST',
            headers,
            body: filterDTO,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * getTestRunById
     */
    getTestRunByIdUsingGET({ testRunId, fromDate, toDate }: GetTestRunByIdUsingGETRequest): Observable<TestRunDTO>
    getTestRunByIdUsingGET({ testRunId, fromDate, toDate }: GetTestRunByIdUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TestRunDTO>
    getTestRunByIdUsingGET({ testRunId, fromDate, toDate }: GetTestRunByIdUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestRunDTO>>
    getTestRunByIdUsingGET({ testRunId, fromDate, toDate }: GetTestRunByIdUsingGETRequest, opts?: OperationOpts): Observable<TestRunDTO | RawAjaxResponse<TestRunDTO>> {
        throwIfNullOrUndefined(testRunId, 'getTestRunByIdUsingGET');

        const fromDateParam = fromDate ? `from_date=${encodeURI(fromDate)}` : "";
        const toDateParam = toDate ? `to_date=${encodeURI(toDate)}` : "";

        const queryParams = (fromDateParam && toDateParam) ? `?${fromDateParam}&${toDateParam}` : "";

        return this.request<TestRunDTO>({
            url: `/testRuns/{testRunId}${queryParams}`.replace('{testRunId}', encodeURI(testRunId)),
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * overview
     */
    overviewUsingGET(): Observable<TestRunsOverviewDTO>
    overviewUsingGET(opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TestRunsOverviewDTO>
    overviewUsingGET(opts?: OperationOpts): Observable<RawAjaxResponse<TestRunsOverviewDTO>>
    overviewUsingGET(opts?: OperationOpts): Observable<TestRunsOverviewDTO | RawAjaxResponse<TestRunsOverviewDTO>> {
        return this.request<TestRunsOverviewDTO>({
            url: '/testRuns/overview',
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * pauseTestRun
     */
    pauseTestRunUsingGET({ recordingClientId, testRunId }: PauseTestRunUsingGETRequest): Observable<TestRunDTO>
    pauseTestRunUsingGET({ recordingClientId, testRunId }: PauseTestRunUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TestRunDTO>
    pauseTestRunUsingGET({ recordingClientId, testRunId }: PauseTestRunUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestRunDTO>>
    pauseTestRunUsingGET({ recordingClientId, testRunId }: PauseTestRunUsingGETRequest, opts?: OperationOpts): Observable<TestRunDTO | RawAjaxResponse<TestRunDTO>> {
        throwIfNullOrUndefined(recordingClientId, 'pauseTestRunUsingGET');
        throwIfNullOrUndefined(testRunId, 'pauseTestRunUsingGET');

        return this.request<TestRunDTO>({
            url: '/testRuns/{testRunId}/pause/{recordingClientId}'.replace('{recordingClientId}', encodeURI(recordingClientId)).replace('{testRunId}', encodeURI(testRunId)),
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * startTestRun
     */
    startTestRunUsingGET({ recordingClientId, testRunId }: StartTestRunUsingGETRequest): Observable<TestRunDTO>
    startTestRunUsingGET({ recordingClientId, testRunId }: StartTestRunUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TestRunDTO>
    startTestRunUsingGET({ recordingClientId, testRunId }: StartTestRunUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestRunDTO>>
    startTestRunUsingGET({ recordingClientId, testRunId }: StartTestRunUsingGETRequest, opts?: OperationOpts): Observable<TestRunDTO | RawAjaxResponse<TestRunDTO>> {
        throwIfNullOrUndefined(recordingClientId, 'startTestRunUsingGET');
        throwIfNullOrUndefined(testRunId, 'startTestRunUsingGET');

        return this.request<TestRunDTO>({
            url: '/testRuns/{testRunId}/start/{recordingClientId}'.replace('{recordingClientId}', encodeURI(recordingClientId)).replace('{testRunId}', encodeURI(testRunId)),
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * stopTestRun
     */
    stopTestRunUsingGET({ recordingClientId, testRunId }: StopTestRunUsingGETRequest): Observable<TestRunDTO>
    stopTestRunUsingGET({ recordingClientId, testRunId }: StopTestRunUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TestRunDTO>
    stopTestRunUsingGET({ recordingClientId, testRunId }: StopTestRunUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestRunDTO>>
    stopTestRunUsingGET({ recordingClientId, testRunId }: StopTestRunUsingGETRequest, opts?: OperationOpts): Observable<TestRunDTO | RawAjaxResponse<TestRunDTO>> {
        throwIfNullOrUndefined(recordingClientId, 'stopTestRunUsingGET');
        throwIfNullOrUndefined(testRunId, 'stopTestRunUsingGET');

        return this.request<TestRunDTO>({
            url: '/testRuns/{testRunId}/stop/{recordingClientId}'.replace('{recordingClientId}', encodeURI(recordingClientId)).replace('{testRunId}', encodeURI(testRunId)),
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * updateTestRun
     */
    updateTestRunUsingPATCH({ testRunId, updates }: UpdateTestRunUsingPATCHRequest): Observable<TestRunDTO>
    updateTestRunUsingPATCH({ testRunId, updates }: UpdateTestRunUsingPATCHRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TestRunDTO>
    updateTestRunUsingPATCH({ testRunId, updates }: UpdateTestRunUsingPATCHRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestRunDTO>>
    updateTestRunUsingPATCH({ testRunId, updates }: UpdateTestRunUsingPATCHRequest, opts?: OperationOpts): Observable<TestRunDTO | RawAjaxResponse<TestRunDTO>> {
        throwIfNullOrUndefined(testRunId, 'updateTestRunUsingPATCH');
        throwIfNullOrUndefined(updates, 'updateTestRunUsingPATCH');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<TestRunDTO>({
            url: '/testRuns/{testRunId}'.replace('{testRunId}', encodeURI(testRunId)),
            method: 'PATCH',
            headers,
            body: updates,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

}
