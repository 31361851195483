import { Epic } from 'redux-observable';
import { timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  TEST_RUNS_REFRESH_INTERVAL,
  TEST_RUN_DETAIL_REFRESH_INTERVAL,
  TEST_RUN_OVERVIEW_REFRESH_INTERVAL,
} from '../../constants';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, takeUntilAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';
import {
  createTestRun,
  fetchFilteredTestRunCycles,
  fetchTestRunDetails,
  fetchTestRunList,
  fetchOverview,
  pauseTestRun,
  startTestRun,
  stopTestRun,
  updateTestRunDetails,
} from './actions';

const fetchTestRunOverviewEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { testRunService }
) =>
  action$.pipe(
    filter(isActionOf(fetchOverview.request)),
    switchMap(() =>
      timer(0, TEST_RUN_OVERVIEW_REFRESH_INTERVAL).pipe(
        takeUntilAction(action$, fetchOverview.cancel),
        switchMap(() =>
          testRunService
            .getTestRunOverview()
            .pipe(
              map(fetchOverview.success),
              catchErrorAndHandleWithAction(fetchOverview.failure),
              takeUntilAction(action$, fetchOverview.cancel)
            )
        )
      )
    )
  );

const fetchTestRunsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { testRunService }) =>
  action$.pipe(
    filter(isActionOf(fetchTestRunList.request)),
    switchMap(({ payload }) =>
      timer(0, TEST_RUNS_REFRESH_INTERVAL).pipe(
        takeUntilAction(action$, fetchTestRunList.cancel),
        switchMap(() =>
          testRunService
            .getTestRuns(payload)
            .pipe(
              map(fetchTestRunList.success),
              catchErrorAndHandleWithAction(fetchTestRunList.failure),
              takeUntilAction(action$, fetchTestRunList.cancel)
            )
        )
      )
    )
  );

const createTestRunEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { testRunService }) =>
  action$.pipe(
    filter(isActionOf(createTestRun.request)),
    switchMap(({ payload }) =>
      testRunService
        .createTestRun(payload)
        .pipe(map(createTestRun.success), catchErrorAndHandleWithAction(createTestRun.failure))
    )
  );

const fetchTestRunDetailsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { testRunService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTestRunDetails.request)),
    switchMap(({ payload }) =>
      timer(0, TEST_RUN_DETAIL_REFRESH_INTERVAL).pipe(
        takeUntilAction(action$, fetchTestRunDetails.cancel),
        switchMap(() =>
          testRunService
            .getTestRunDetails(payload)
            .pipe(
              map(fetchTestRunDetails.success),
              catchErrorAndHandleWithAction(fetchTestRunDetails.failure),
              takeUntilAction(action$, fetchTestRunDetails.cancel)
            )
        )
      )
    )
  );

const fetchFilteredCycleFailureListForTestRunEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { cycleFailureService }
) =>
  action$.pipe(
    filter(isActionOf(fetchFilteredTestRunCycles.request)),
    switchMap(({ payload }) =>
      timer(0, TEST_RUN_DETAIL_REFRESH_INTERVAL).pipe(
        takeUntilAction(action$, fetchFilteredTestRunCycles.cancel),
        switchMap(() =>
          cycleFailureService
            .getCyclesByTestRunId(payload.filter, payload.id)
            .pipe(
              map(fetchFilteredTestRunCycles.success),
              catchErrorAndHandleWithAction(fetchFilteredTestRunCycles.failure),
              takeUntilAction(action$, fetchFilteredTestRunCycles.cancel)
            )
        )
      )
    )
  );

const pauseTestRunEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { testRunService }) =>
  action$.pipe(
    filter(isActionOf(pauseTestRun.request)),
    switchMap(({ payload }) =>
      testRunService
        .pauseTestRun(payload)
        .pipe(map(pauseTestRun.success), catchErrorAndHandleWithAction(pauseTestRun.failure))
    )
  );

const startTestRunEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { testRunService }) =>
  action$.pipe(
    filter(isActionOf(startTestRun.request)),
    switchMap(({ payload }) =>
      testRunService
        .startTestRun(payload)
        .pipe(map(startTestRun.success), catchErrorAndHandleWithAction(startTestRun.failure))
    )
  );
const stopTestRunEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { testRunService }) =>
  action$.pipe(
    filter(isActionOf(stopTestRun.request)),
    switchMap(({ payload }) =>
      testRunService
        .stopTestRun(payload)
        .pipe(map(stopTestRun.success), catchErrorAndHandleWithAction(stopTestRun.failure))
    )
  );

const updateTestRunDetailsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { testRunService }
) =>
  action$.pipe(
    filter(isActionOf(updateTestRunDetails.request)),
    switchMap(({ payload }) =>
      testRunService
        .updateDescription(payload)
        .pipe(
          map(updateTestRunDetails.success),
          catchErrorAndHandleWithAction(updateTestRunDetails.failure),
          takeUntilAction(action$, updateTestRunDetails.cancel)
        )
    )
  );

export default [
  fetchTestRunsEpic,
  createTestRunEpic,
  fetchFilteredCycleFailureListForTestRunEpic,
  pauseTestRunEpic,
  startTestRunEpic,
  stopTestRunEpic,
  fetchTestRunDetailsEpic,
  updateTestRunDetailsEpic,
  fetchTestRunOverviewEpic,
];
