// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface TestRunInfoDTO
 */
export interface TestRunInfoDTO {
    /**
     * @type {number}
     * @memberof TestRunInfoDTO
     */
    actualCycleCount: number;
    /**
     * @type {string}
     * @memberof TestRunInfoDTO
     */
    carModel: string;
    /**
     * @type {string}
     * @memberof TestRunInfoDTO
     */
    componentName: string;
    /**
     * @type {string}
     * @memberof TestRunInfoDTO
     */
    humanReadableId: string;
    /**
     * @type {string}
     * @memberof TestRunInfoDTO
     */
    id: string;
    /**
     * @type {number}
     * @memberof TestRunInfoDTO
     */
    ioPercent: number;
    /**
     * @type {number}
     * @memberof TestRunInfoDTO
     */
    nioPercent: number;
    /**
     * @type {string}
     * @memberof TestRunInfoDTO
     */
    state: TestRunInfoDTOStateEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum TestRunInfoDTOStateEnum {
    CREATED = 'CREATED',
    RUNNING = 'RUNNING',
    STANDBY = 'STANDBY',
    PAUSED = 'PAUSED',
    FINISHED = 'FINISHED',
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE'
}

