import { getType } from 'typesafe-actions';
import { INITIAL_ACTION_STATUS } from '../../constants';
import { ActionStatus, HttpError, Training } from '../../models';
import { FilterRequestType } from '../../models/requestFilter.model';
import { replaceIn, withKey } from '../../utils';
import * as fromActions from './actions';

type State = {
  readonly trainings: Training[];
  readonly totalTrainings: number;
  readonly requestFilter: FilterRequestType;
  readonly previousRequestFilter: FilterRequestType;
  readonly isLoading: boolean;
  readonly error: HttpError;
  readonly createTrainingStatus: ActionStatus;
  readonly startTrainingStatus: ActionStatus;
  readonly pauseTrainingStatus: ActionStatus;
  readonly stopTrainingStatus: ActionStatus;
  readonly isSameFilter: boolean;
  readonly trainingDescriptionUpdateStatus: TrainingDescriptionUpdateStatus;

};

export type TrainingDescriptionUpdateStatus = {
  isPending: boolean;
  isSuccess: boolean;
  error?: HttpError;
};

const initialTrainingDescriptionUpdateStatus: TrainingDescriptionUpdateStatus = {
  isPending: false,
  isSuccess: false,
  error: undefined,
};

const initialState: State = {
  trainings: undefined,
  totalTrainings: undefined,
  isLoading: false,
  error: undefined,
  requestFilter: undefined,
  previousRequestFilter: undefined,
  isSameFilter: false,
  createTrainingStatus: { ...INITIAL_ACTION_STATUS },
  startTrainingStatus: { ...INITIAL_ACTION_STATUS },
  pauseTrainingStatus: { ...INITIAL_ACTION_STATUS },
  stopTrainingStatus: { ...INITIAL_ACTION_STATUS },
  trainingDescriptionUpdateStatus: { ...initialTrainingDescriptionUpdateStatus },
};

let tempTraining: Training = undefined;
let tempHumanReadableId = undefined;
let newTraining: Training = undefined;

const HUMAN_READABLE_ID = 'humanReadableId';
const ID_STR = 'id';

export const trainingsReducer = (state = initialState, action: fromActions.TrainingAction): State => {
  switch (action.type) {
    /*
     *------------------------------- Fetch
     */
    case getType(fromActions.fetchTrainings.request):
      return {
        ...state,
        isLoading: true,
        error: null,
        createTrainingStatus: { ...INITIAL_ACTION_STATUS },
        startTrainingStatus: { ...INITIAL_ACTION_STATUS },
        pauseTrainingStatus: { ...INITIAL_ACTION_STATUS },
        stopTrainingStatus: { ...INITIAL_ACTION_STATUS },
        requestFilter: action.payload,
        previousRequestFilter: state.previousRequestFilter ? { ...state.requestFilter } : null,
        isSameFilter: state.requestFilter?.page > state.previousRequestFilter?.page,
      };
    case getType(fromActions.fetchTrainings.success):
      /*
       * This block is required to set the highlight of the created training when using the button on the overview page
       */
      let tempTrainings: Training[];
      let tempTrainingStatus: ActionStatus;
      if (newTraining) {
        const createdTrainingIndex = action.payload.trainings.findIndex((training) => training.id === newTraining.id);
        if (createdTrainingIndex !== -1) {
          tempTrainings = [...action.payload.trainings];
          tempTrainings[createdTrainingIndex].wasCreated = true;
          tempTrainingStatus = {
            id: tempTrainings[createdTrainingIndex].id,
            isPending: false,
            isSuccess: true,
          };
        }
      }
      newTraining = null;

      return {
        ...state,
        isLoading: false,
        trainings: tempTrainings ?? [...action.payload.trainings],
        totalTrainings: action.payload.total,
        previousRequestFilter: { ...state.requestFilter },
        createTrainingStatus: tempTrainingStatus ?? { ...INITIAL_ACTION_STATUS },
      };
    case getType(fromActions.fetchTrainings.failure):
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        requestFilter: { ...state.previousRequestFilter },
      };

    /*
     *------------------------------- Create
     */
    case getType(fromActions.createTraining.request):
      newTraining = null;
      return {
        ...state,
        startTrainingStatus: { ...INITIAL_ACTION_STATUS },
        pauseTrainingStatus: { ...INITIAL_ACTION_STATUS },
        stopTrainingStatus: { ...INITIAL_ACTION_STATUS },
        createTrainingStatus: {
          ...INITIAL_ACTION_STATUS,
          isPending: true,
        },
      };
    case getType(fromActions.createTraining.success):
      newTraining = { ...action.payload };
      newTraining.wasCreated = true;
      let trainings;
      if (state.trainings?.length > 0) {
        trainings = [newTraining, ...state.trainings] as Training[];
      } else {
        trainings = [newTraining] as Training[];
      }
      return {
        ...state,
        trainings: trainings,
        createTrainingStatus: {
          id: action.payload.id,
          isPending: false,
          isSuccess: true,
        },
      };
    case getType(fromActions.createTraining.failure):
      return {
        ...state,
        createTrainingStatus: {
          isPending: false,
          isSuccess: false,
          error: action.payload,
        },
      };

    /**
     * Training Run Actions
     * */
    /*
     *------------------------------- Start
     */
    case getType(fromActions.startTraining.request):
      tempTraining = state.trainings.find((training) => training.id === action.payload.id);
      tempHumanReadableId = tempTraining?.id ?? '';
      return {
        ...state,
        createTrainingStatus: { ...INITIAL_ACTION_STATUS },
        pauseTrainingStatus: { ...INITIAL_ACTION_STATUS },
        stopTrainingStatus: { ...INITIAL_ACTION_STATUS },
        startTrainingStatus: { ...INITIAL_ACTION_STATUS, id: tempHumanReadableId, isPending: true },
      };

    case getType(fromActions.startTraining.success):
      return {
        ...state,
        startTrainingStatus: {
          ...state.startTrainingStatus,
          isPending: false,
          isSuccess: true,
        },
        trainings: replaceIn(state.trainings, withKey(action.payload.id, ID_STR), (item) => ({
          ...item,
          ...action.payload,
        })),
      };

    case getType(fromActions.startTraining.failure):
      return {
        ...state,
        trainings:
          action.payload.status === 502
            ? replaceIn(state.trainings, withKey(state.startTrainingStatus.id, HUMAN_READABLE_ID), (item) => ({
              ...item,
              status: 'offline',
            }))
            : state.trainings,
        startTrainingStatus: {
          ...state.startTrainingStatus,
          isPending: false,
          isSuccess: false,
          error: action.payload,
        },
      };
    /*
     *------------------------------- Pause
     */
    case getType(fromActions.pauseTraining.request):
      tempTraining = state.trainings.find((training) => training.id === action.payload.id);
      tempHumanReadableId = tempTraining?.id ?? '';
      return {
        ...state,
        createTrainingStatus: { ...INITIAL_ACTION_STATUS },
        startTrainingStatus: { ...INITIAL_ACTION_STATUS },
        stopTrainingStatus: { ...INITIAL_ACTION_STATUS },
        pauseTrainingStatus: { ...INITIAL_ACTION_STATUS, id: tempHumanReadableId, isPending: true },
      };

    case getType(fromActions.pauseTraining.success):
      return {
        ...state,
        pauseTrainingStatus: {
          ...state.pauseTrainingStatus,
          isPending: false,
          isSuccess: true,
        },
        trainings: replaceIn(state.trainings, withKey(action.payload.id, ID_STR), (item) => ({
          ...item,
          ...action.payload,
        })),
      };

    case getType(fromActions.pauseTraining.failure):
      return {
        ...state,
        trainings:
          action.payload.status === 502
            ? replaceIn(state.trainings, withKey(state.pauseTrainingStatus.id, HUMAN_READABLE_ID), (item) => ({
              ...item,
              status: 'offline',
            }))
            : state.trainings,
        pauseTrainingStatus: {
          ...state.pauseTrainingStatus,
          isPending: false,
          isSuccess: false,
          error: action.payload,
        },
      };
    /*
     *------------------------------- Stop
     */
    case getType(fromActions.stopTraining.request):
      tempTraining = state.trainings.find((training) => training.id === action.payload.id);
      tempHumanReadableId = tempTraining?.id ?? '';
      return {
        ...state,
        createTrainingStatus: { ...INITIAL_ACTION_STATUS },
        startTrainingStatus: { ...INITIAL_ACTION_STATUS },
        pauseTrainingStatus: { ...INITIAL_ACTION_STATUS },
        stopTrainingStatus: {
          ...INITIAL_ACTION_STATUS,
          id: tempHumanReadableId,
          isPending: true,
        },
      };

    case getType(fromActions.stopTraining.success):
      return {
        ...state,
        stopTrainingStatus: {
          ...state.stopTrainingStatus,
          isPending: false,
          isSuccess: true,
        },
        trainings: replaceIn(state.trainings, withKey(action.payload.id, ID_STR), (item) => ({
          ...item,
          ...action.payload,
        })),
      };

    case getType(fromActions.stopTraining.failure):
      return {
        ...state,
        trainings:
          action.payload.status === 502
            ? replaceIn(state.trainings, withKey(state.stopTrainingStatus.id, HUMAN_READABLE_ID), (item) => ({
              ...item,
              status: 'offline',
            }))
            : state.trainings,
        stopTrainingStatus: {
          ...state.stopTrainingStatus,
          isPending: false,
          isSuccess: false,
          error: action.payload,
        },
      };

    /*
     *------------------------------- Reset Create Training Status
     */

    case getType(fromActions.resetCreateTrainingStatus):
      newTraining = null;
      return {
        ...state,
        createTrainingStatus: { ...INITIAL_ACTION_STATUS },
      };

    /*
     *------------------------------- Reset Start Training Status
     */

    case getType(fromActions.resetStartTrainingStatus):
      return {
        ...state,
        startTrainingStatus: { ...INITIAL_ACTION_STATUS },
      };


    /*
   *------------------------------- Update Training Details
   */
    case getType(fromActions.updateTrainingDetails.request):
      return {
        ...state,
        trainingDescriptionUpdateStatus: {
          ...initialTrainingDescriptionUpdateStatus,
          isPending: true,
        },
      };
    case getType(fromActions.updateTrainingDetails.success):
      {
        // update all trainings list
        const updateTraining = action.payload;
        let updatedTrainings = [];
        state.trainings.forEach(training => {
          if (training.id === updateTraining.id) {
            updatedTrainings.push(updateTraining)
          } else {
            updatedTrainings.push(training)
          }
        });
        return {
          ...state,
          trainings: updatedTrainings,
          trainingDescriptionUpdateStatus: {
            ...initialTrainingDescriptionUpdateStatus,
            isSuccess: true,
          },
        };
      }

    case getType(fromActions.updateTrainingDetails.failure):
      return {
        ...state,
        trainingDescriptionUpdateStatus: {
          ...initialTrainingDescriptionUpdateStatus,
          error: action.payload,
        },
      };

    /*
 *------------------------------- Reset Snackbar
 */
    case getType(fromActions.resetSnackbar):
      return {
        ...state,
        trainingDescriptionUpdateStatus: {
          ...initialTrainingDescriptionUpdateStatus,
        },
      };

    default:
      return state;
  }
};
