import { AUTH_HEADER_KEY, ID_TOKEN, REDIRECT_URL } from '../constants';
import { environment } from '../environments';
import { Configuration, Middleware, RequestArgs } from '../proxy';
import { getLocalStorageValue, removeLocalStorageValue, setLocalStorageValue } from '../utils';
import { AuthService } from './auth.service';

export class AuthInterceptor extends Configuration {
  private static config: AuthInterceptor;

  private constructor() {
    const middleware: Middleware[] = [
      {
        pre(context: RequestArgs): RequestArgs {
          const token = getLocalStorageValue<string>(ID_TOKEN)
          const contextUrl = context.url
          const notOnLoginUrl = contextUrl.indexOf('/login') < 0
          if (notOnLoginUrl && !AuthService.isTokenValid(token)) {
            // this exception is taken care of in catchErrorAndHandleWithAction
            setLocalStorageValue(REDIRECT_URL, window.location.href);
            console.error(`Currently on URL: ${contextUrl} - should contain login`);
            console.error(`INVALID_TOKEN, token is: ${token}. Redirecting to retrieve a new token...`);
            return null;
          }
          const redirectUrl = getLocalStorageValue<string>(REDIRECT_URL);
          if (redirectUrl) {
            removeLocalStorageValue(REDIRECT_URL);
            //redirect user to his desired location
            window.location.href = redirectUrl;
          }
          return {
            ...context,
            headers: {
              ...context.headers,
              [AUTH_HEADER_KEY]: `Bearer ${getLocalStorageValue<string>(ID_TOKEN)}`,
            },
          };
        },
      },
    ];

    super({ middleware, basePath: environment.apiEndpointUrl });
  }

  public static get Instance(): AuthInterceptor {
    return AuthInterceptor.config || (AuthInterceptor.config = new this());
  }
}
