// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CycleDTO
 */
export interface CycleDTO {
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    audioFile: string;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    carModel?: string;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    componentName?: string;
    /**
     * @type {Date}
     * @memberof CycleDTO
     */
    created: Date;
    /**
     * @type {number}
     * @memberof CycleDTO
     */
    currentCycle: number;
    /**
     * @type {boolean}
     * @memberof CycleDTO
     */
    customerRelevant?: boolean;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    description?: string;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    flacFile?: string;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    humanReadableId?: string;
    /**
     * @type {number}
     * @memberof CycleDTO
     */
    humidity?: number;
    /**
     * @type {string}
     * @memberof CycleDTO
     */

    error?: boolean;

    id: string;
    /**
     * @type {boolean}
     * @memberof CycleDTO
     */
    modelFinding?: boolean;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    nextAnomalyId?: string;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    previousAnomalyId?: string;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    referenceSpectrogram?: string;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    referenceSpectrogramFile?: string;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    spectrogram?: string;
    /**
     * @type {number}
     * @memberof CycleDTO
     */
    temperature?: number;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    testRunId: string;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    trainingLabel?: CycleDTOTrainingLabelEnum;
    /**
     * @type {string}
     * @memberof CycleDTO
     */
    orderNum?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CycleDTOTrainingLabelEnum {
    IO = 'IO',
    NIO = 'NIO',
    NONE = 'NONE'
}

