import * as React from 'react';

import { Snackbar, SnackbarContent, makeStyles } from '@material-ui/core';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SNACKBAR_AUTO_HIDE_DURATION } from '../constants';
import { colors } from '../colors';
import cx from 'clsx';

type Props = {
  isSnackbarOpen: boolean;
  closeSnackbar: () => void;
  type: 'success' | 'error' | 'warning';
  children: JSX.Element;
};

const useStyles = makeStyles(
  {
    success: {
      backgroundColor: colors.snackbarSuccess,
    },
    error: {
      backgroundColor: colors.snackbarError,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    content: {
      padding: '10px 34px 11px 21px',
      fontSize: 20,
      fontWeight: 'bold',
      borderRadius: 0,
      fontFamily: `'Porsche Next', 'Arial Narrow', Arial, sans-serif`,
      boxShadow: 'none',
    },
    icon: {
      padding: '5px 15px 5px 5px',
    },
  },
  { name: 'CustomSnackbar' }
);

export const CustomSnackbar: React.FC<Props> = ({ isSnackbarOpen, closeSnackbar, type, children }) => {
  const c = useStyles({});
  return (
    isSnackbarOpen && (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isSnackbarOpen}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        onClose={closeSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        onClick={closeSnackbar}
      >
        <SnackbarContent
          className={cx(c.content, c[type])}
          message={
            <span className={c.message}>
              {type === 'success' && <CheckCircleIcon className={c.icon} />}
              {type === 'error' && <CancelIcon className={c.icon} />}
              {children}
            </span>
          }
        />
      </Snackbar>
    )
  );
};
