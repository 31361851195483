import { combineEpics } from 'redux-observable';
import cycleFailureEpics from './cycle-failures/epics';
import testRunEpics from './test-runs/epics';
import initialConfigEpics from './config/epics';
import appEpics from './app/epics';
import trainingEpics from './trainings/epics';

const rootEpic = combineEpics(
  ...appEpics,
  ...cycleFailureEpics,
  ...testRunEpics,
  ...initialConfigEpics,
  ...trainingEpics
);

export default rootEpic;
