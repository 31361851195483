import * as React from 'react';

import { CustomSnackbar, Header, LoadingMask, NewTestRunFormModal, TestRunTable } from '../components';
import {
  CREATED_STR,
  END_TEST_STR,
  INITIAL_NAMED_ACTION_STATUS,
  INITIAL_REQUEST_FILTER_TEST_RUNS,
  KEYDOWN_EVENT,
  PAUSE_TEST_STR,
  START_TEST_STR,
} from '../constants';
import { NamedActionStatus, NamedSnackbarStatus, initialSnackbarStatus, FilterRequestType } from '../models';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  createTestRun,
  fetchTestRunList,
  getCreateTestRunStatus,
  getIsLoading,
  getLastRequestFilter,
  getPauseTestRunStatus,
  getStartTestRunStatus,
  getStopTestRunStatus,
  getTestRunControlPrefilling,
  getTestRuns,
  getTotalTestRuns,
  newTestRunConfigurationsSelector,
  pauseTestRun,
  resetStartTestRunStatus,
  showNewTestrunButton,
  startTestRun,
  stopTestRun,
} from '../store/test-runs';
import { extractQueryParamAsJSON, generateDayStartEnd, onArrowKeyPagination, setFilterQueryParamsInURL } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ListFilterModal } from '../components/Form/ListFilterModal';
import { PButton } from '@porsche-design-system/components-react';
import { Pagination } from '@porsche/ui-kit-react';
import { RouteComponentProps } from 'react-router';
import { getTestRunListFilterConfigurations } from '../store/cycle-failures';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateRangePicker from '../components/DateRangePicker';
import moment from 'moment';


export const getFilterButtonStyles = () =>
  createStyles({
    filterButton: {
      float: 'right',
      margin: 5,
      '& .MuiInputBase-input:first-child': {
        width: 200,
      },
    },
    header: {
      display: 'flex',
    },
    headerTitle: {
      flex: 1,
    },
  });
export const getPaginationStyles = () =>
  createStyles({
    paginationFix: {
      margin: '75px 0',
      '&>ul': {
        listStyleType: 'none',
        paddingInlineStart: 0,
      },
      '&>i': {
        marginTop: 19,
      },
      '&>a': {
        marginTop: 19,
      },
    },
  });

const useStyles = makeStyles(
  createStyles({
    ...getFilterButtonStyles(),
    ...getPaginationStyles(),
  }),
  { name: 'TestRunList' },
);

export const TestRunList: React.FC<RouteComponentProps> = ({ history, location }) => {
  const c = useStyles({});
  const dispatch = useDispatch();
  const testRuns = useSelector(getTestRuns);
  const totalTestRuns = useSelector(getTotalTestRuns);
  const newTestRunConfigurations = useSelector(newTestRunConfigurationsSelector);
  const testRunControlPrefilling = useSelector(getTestRunControlPrefilling);
  const isLoading = useSelector(getIsLoading);
  const createTestRunState = useSelector(getCreateTestRunStatus);
  const startTestRunState = useSelector(getStartTestRunStatus);
  const pauseTestRunState = useSelector(getPauseTestRunStatus);
  const stopTestRunState = useSelector(getStopTestRunStatus);
  const filterConfigurations = useSelector(getTestRunListFilterConfigurations);

  const displayTestrunButton = useSelector(showNewTestrunButton);



  const [isNewTestRunModalOpen, setIsNewTestRunModalOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState<NamedSnackbarStatus>(initialSnackbarStatus);
  const [actionStatus, setActionStatus] = useState<NamedActionStatus>({ ...INITIAL_NAMED_ACTION_STATUS });
  const openNewTestRunModal = () => {
    removeOnKeyDown();
    setIsNewTestRunModalOpen(true);
  };

  const closeNewTestRunModal = () => {
    addOnKeyDown();
    setIsNewTestRunModalOpen(false);
  };

  const lastRequestFilter = useSelector(getLastRequestFilter);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const openFilterModal = () => {
    removeOnKeyDown();
    setIsFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    addOnKeyDown();
    setIsFilterModalOpen(false);
  };
  const closeSnackbar = () => setSnackbarState((prevState) => ({ ...prevState, isOpen: false }));

  const onKeyDown = (e) => onArrowKeyPagination(e, lastRequestFilter, totalTestRuns, loadTestRunPage);
  const addOnKeyDown = () => document.addEventListener(KEYDOWN_EVENT, onKeyDown, false);
  const removeOnKeyDown = () => document.removeEventListener(KEYDOWN_EVENT, onKeyDown, false);

  const [, setReqFilter] = useState<FilterRequestType>({ filterConditions: [] });

  const [start, end] = generateDayStartEnd()
  const [startDate, setStartDate] = useState<string>(start);
  const [endDate, setEndDate] = useState<string>(end);

  useEffect(() => {
    if (!isFilterModalOpen && !isNewTestRunModalOpen) {
      addOnKeyDown();
    }
    return () => removeOnKeyDown();
  });
  useEffect(() => {
    setSnackbarState({
      isOpen:
        createTestRunState.isSuccess ||
        startTestRunState.isSuccess ||
        pauseTestRunState.isSuccess ||
        stopTestRunState.isSuccess ||
        !!createTestRunState.error ||
        !!startTestRunState.error ||
        !!pauseTestRunState.error ||
        !!stopTestRunState.error,
      error: createTestRunState.error ?? startTestRunState.error ?? pauseTestRunState.error ?? stopTestRunState.error,
      objectName:
        ((createTestRunState.isSuccess || !!createTestRunState.error) && `Test ${createTestRunState?.id ?? ''}`) ||
        startTestRunState.id ||
        pauseTestRunState.id ||
        stopTestRunState.id,
      actionName:
        ((createTestRunState.isSuccess || !!createTestRunState.error) && 'angelegt') ||
        ((startTestRunState.isSuccess || !!startTestRunState.error) && 'gestartet') ||
        ((pauseTestRunState.isSuccess || !!pauseTestRunState.error) && 'pausiert') ||
        ((stopTestRunState.isSuccess || !!stopTestRunState.error) && 'beendet'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createTestRunState.isSuccess,
    startTestRunState.isSuccess,
    pauseTestRunState.isSuccess,
    stopTestRunState.isSuccess,
    createTestRunState.error,
    startTestRunState.error,
    pauseTestRunState.error,
    stopTestRunState.error,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActionStatus({
      isFinished:
        createTestRunState.isSuccess ||
        startTestRunState.isSuccess ||
        pauseTestRunState.isSuccess ||
        stopTestRunState.isSuccess ||
        !!createTestRunState.error ||
        !!startTestRunState.error ||
        !!pauseTestRunState.error ||
        !!stopTestRunState.error,
      isPending:
        createTestRunState.isPending ||
        startTestRunState.isPending ||
        pauseTestRunState.isPending ||
        stopTestRunState.isPending,
      actionName:
        (startTestRunState.isPending && START_TEST_STR) ||
        (pauseTestRunState.isPending && PAUSE_TEST_STR) ||
        (stopTestRunState.isPending && END_TEST_STR),
    });
  }, [createTestRunState, startTestRunState, pauseTestRunState, stopTestRunState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const urlFilter = decodeURIEncodedFilterJSONString(location.search);
    dispatch(fetchTestRunList.request(urlFilter));

    const filterConditions = urlFilter.filterConditions;
    const startDayFilter = filterConditions.find((cond) => cond.name === CREATED_STR && cond.operator === "gt")?.values;
    const endDayFilter = filterConditions.find((cond) => cond.name === CREATED_STR && cond.operator === "lt")?.values;
    if (startDayFilter) {
      if (Array.isArray(startDayFilter)) {
        setStartDate(startDayFilter[0])
      } else {
        setStartDate(startDayFilter);
      }
    }

    if (endDayFilter) {
      if (Array.isArray(endDayFilter)) {
        setEndDate(endDayFilter[0])
      } else {
        setEndDate(endDayFilter);
      }
    }

    return () => { dispatch(fetchTestRunList.cancel()) };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const decodeURIEncodedFilterJSONString = (queryParams?: string) => {
    if (!queryParams) {
      return {
        ...INITIAL_REQUEST_FILTER_TEST_RUNS, filterConditions: [{
          'name': CREATED_STR,
          'operator': 'gt',
          'values': [startDate],
        },
        {
          'name': CREATED_STR,
          'operator': 'lt',
          'values': [endDate],
        },
        ]
      };
    }
    return { ...INITIAL_REQUEST_FILTER_TEST_RUNS, filterConditions: extractQueryParamAsJSON(queryParams, 'filter') };
  };

  const loadTestRunPage = (event: React.MouseEvent<HTMLElement, MouseEvent> | KeyboardEvent, page: number) => {
    const newFilter = { ...lastRequestFilter };
    newFilter.page = page;
    dispatch(fetchTestRunList.request(newFilter));
  };


  const renderLabel: Function = (start, end): string => {
    const startDay = moment(start).format('DD.MM.YYYY');
    const endDay = moment(end).format('DD.MM.YYYY');
    return startDay !== endDay ? `${startDay} - ${endDay}` : startDay;
  };


  return (
    <>
      <Header title={'Alle Prüfstationen'}>
        {displayTestrunButton && <PButton icon="add" onClick={openNewTestRunModal}>
          Neuen Test anlegen
        </PButton>
        }
        <PButton
          icon='filter'
          onClick={openFilterModal}
          loading={isLoading}
          disabled={isLoading}
          variant={lastRequestFilter?.filterConditions?.length > 0 ? 'primary' : 'secondary'}
          className={c.filterButton}
        >
          Filter
        </PButton>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateRangePicker
            className={c.filterButton}
            autoOk
            clearable
            disableToolbar
            label='Zeitraum'
            emptyLabel={renderLabel(startDate, endDate)}
            labelFunc={renderLabel}
            inputVariant='outlined'
            clearLabel={<span>Löschen</span>}
            cancelLabel={<span>Abbrechen</span>}
            value={[Date.parse(startDate), Date.parse(endDate)]}
            onChange={
              (args) => {
                const [defaultstart, defaultend] = generateDayStartEnd();
                const start = args?.begin.toISOString() ?? defaultstart;
                setReqFilter((oldState) => {
                  setStartDate(start);
                  // add one day to the endday because otherwise it's at 00:00:00 and not included.
                  // Adding one day and setting the clock to 23:59:59
                  let endDay = args?.end;
                  if (endDay) {
                    endDay.setDate(endDay.getDate() + 1)
                    endDay = new Date(endDay.getTime() - 1000)
                  } else {
                    endDay = new Date(Date.parse(defaultend))
                  }

                  setEndDate(endDay.toISOString());
                  const newFilterState: FilterRequestType = ({
                    ...oldState,
                    filterConditions: [...oldState.filterConditions.filter(el => el.name !== CREATED_STR), {
                      'name': CREATED_STR,
                      'operator': 'gt',
                      'values': [new Date(Date.parse(start))],
                    },
                    {
                      'name': CREATED_STR,
                      'operator': 'lt',
                      'values': [endDay],
                    }

                    ],
                  });
                  setFilterQueryParamsInURL(newFilterState, history);
                  dispatch(fetchTestRunList.request(newFilterState));
                  return newFilterState;
                });
              }}
          />

        </MuiPickersUtilsProvider>
      </Header>

      {
        isFilterModalOpen && (
          <ListFilterModal
            filterType={'testRuns'}
            onClose={closeFilterModal}
            isOpen={isFilterModalOpen}
            lastRequestFilter={lastRequestFilter}
            formConfiguration={filterConfigurations}
            onFormSubmit={(args) => {

              setFilterQueryParamsInURL(args, history);
              dispatch(fetchTestRunList.request(args));
              if (args.filterConditions.length === 0) {
                const [start, end] = generateDayStartEnd()
                setStartDate(start);
                setEndDate(end);
              }
            }}
          />
        )
      }
      {
        newTestRunConfigurations && isNewTestRunModalOpen && (
          <NewTestRunFormModal
            isOpen={isNewTestRunModalOpen}
            onClose={closeNewTestRunModal}
            formPrefilling={newTestRunConfigurations}
            onFormSubmit={(args) => dispatch(createTestRun.request(args))}
          />
        )
      }
      <TestRunTable
        testRuns={testRuns}
        pauseTestRun={(args) => dispatch(pauseTestRun.request(args))}
        startTestRun={(args) => dispatch(startTestRun.request(args))}
        stopTestRun={(args) => dispatch(stopTestRun.request(args))}
        testRunActionStatus={actionStatus}
        startTestRunStatus={startTestRunState}
        testRunControlPrefilling={testRunControlPrefilling}
        resetStartTestRunStatus={() => dispatch(resetStartTestRunStatus())}
        startDate={startDate}
        endDate={endDate}
      />
      <LoadingMask isLoading={isLoading} />
      <CustomSnackbar
        isSnackbarOpen={snackbarState.isOpen}
        closeSnackbar={closeSnackbar}
        type={snackbarState.error ? 'error' : 'success'}
      >
        <>
          {snackbarState.error ? (
            <span id="message-id">{`${snackbarState.objectName} konnte nicht ${snackbarState.actionName} werden: ${snackbarState.error.message ?? 'Unbekanter Fehler.'
              }`}</span>
          ) : (
            <span id="message-id">{`${snackbarState.objectName} wurde erfolgreich ${snackbarState.actionName}.`}</span>
          )}
        </>
      </CustomSnackbar>
      {/* //TODO replace Pagination with PPagination (porsche design system) as soon as their events for page change work */}
      {lastRequestFilter && (
        <Pagination
          totalItemsCount={totalTestRuns ?? 0}
          itemsPerPage={INITIAL_REQUEST_FILTER_TEST_RUNS.size}
          activePage={lastRequestFilter?.page ?? 1}
          onClick={loadTestRunPage}
          className={c.paginationFix}
        />
      )}
    </>
  );
};
