import * as React from 'react';

import { ALL_CYCLES, CYCLE_FAILURE_LIST_TABLE_HEADERS } from '../constants';
import { Table, TableBody, TableHeadRow } from '.';
import { createStyles, makeStyles } from '@material-ui/core';
import { createUrlWithParams, sitemap } from '../routes';

import { Cycle } from '../models';
import { CycleFailureDetailModal } from './CycleFailureDetailModal';
import InfoIcon from '@material-ui/icons/Info';
import { RowType } from './Table';
import { colors } from '../colors';
import moment from 'moment';
import { useState } from 'react';

type Props = {
  cycles: Cycle[];
  totalCycles?: number;
  isFromAllCyclesPage?: boolean;
};
export const getRecordingTableStyles = () =>
  createStyles({
    io: {
      color: colors.statusGreen,
      fontWeight: 'bold',
    },
  });

const useStyles = makeStyles(
  createStyles({
    ...getRecordingTableStyles(),
  }),
  { name: 'CycleFailureTable' }
);

export const CycleFailureTable: React.FC<Props> = ({ cycles, totalCycles = 1, isFromAllCyclesPage }) => {
  const classes = useStyles({});
  const [detailModalId, setDetailModalId] = useState<string>(undefined);
  const openDetailModal = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, id: string) => {
    e?.stopPropagation();
    e?.preventDefault();
    setDetailModalId(id);
  };
  const closeDetailModal = () => setDetailModalId(undefined);

  const createDetailViewPath = (data: RowType) => {
    const url = createUrlWithParams(sitemap.recordings.detail.path, {
      id: data.entityId,
      recordingClientId: data.recordingClientId,
    });
    return isFromAllCyclesPage ? { pathname: url, state: { from: ALL_CYCLES } } : url;
  };

  return (
    <Table>
      {cycles?.length > 0 && !!detailModalId && (
        <CycleFailureDetailModal
          isOpen={!!detailModalId}
          onClose={closeDetailModal}
          cycle={cycles.find((cycle) => cycle.id === detailModalId)}
        />
      )}
      <TableHeadRow types={CYCLE_FAILURE_LIST_TABLE_HEADERS} />
      <TableBody
        data={cycles?.map<RowType>((c) => ({
          recordingClientId: c.testRunId,
          entityId: c.id,
          cols: [
            { text: c.currentCycle?.toString() ?? '-' },
            { text: c.orderNum ?? '-' },
            { text: c.created ? moment(c.created).format('DD.MM.YYYY') : '-' },
            { text: c.created ? moment(c.created).format('HH:mm:ss') : '-' },
            { text: c.carModel },
            { text: c.componentName },
            { text: c.modelFinding ? 'n.i.O.' : <span className={classes.io}>i.O.</span> },
            { text: <InfoIcon onClick={(e) => openDetailModal(e, c.id)} />, isDetail: true },
          ],
        }))}
        type={CYCLE_FAILURE_LIST_TABLE_HEADERS}
        routeTo={createDetailViewPath}
      />
    </Table>
  );
};
