import { getType } from 'typesafe-actions';
import { ID_TOKEN } from '../../constants';
import { environment } from '../../environments';
import { User } from '../../models';
import { mapRoleToUser, removeLocalStorageValue } from '../../utils';
import * as fromActions from './actions';

type State = {
  user?: User;
};
const initialState: State = {};

export const appReducer = (state = initialState, action: fromActions.AppAction) => {
  switch (action.type) {
    case getType(fromActions.logout.request):
      removeLocalStorageValue(ID_TOKEN);
      window.location.href = environment.cognitoUrl;

      return {
        ...state,
        user: undefined,
      };

    case getType(fromActions.logout.success):
    case getType(fromActions.logout.failure):
      return {
        ...state,
      };

    // -------------------------------

    case getType(fromActions.setUserRole):
      return {
        ...state,
        user: mapRoleToUser(action.payload),
      };

    default:
      return state;
  }
};
