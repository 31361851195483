import { getType } from 'typesafe-actions';
import { INITIAL_ACTION_STATUS } from '../../constants';
import { ActionStatus, Cycle, HttpError, TestRun,  TestRunOverview } from '../../models';
import { FilterRequestType } from '../../models/requestFilter.model';
import { replaceIn, withKey } from '../../utils';
import * as fromActions from './actions';

type State = {
  readonly overview: TestRunOverview;
  readonly testRuns: TestRun[];
  readonly totalTestRuns: number;
  readonly requestFilter: FilterRequestType;
  readonly previousRequestFilter: FilterRequestType;
  readonly currentCycles: Cycle[];
  readonly isLoading: boolean;
  readonly error: HttpError;
  readonly createTestRunStatus: ActionStatus;
  readonly startTestRunStatus: ActionStatus;
  readonly pauseTestRunStatus: ActionStatus;
  readonly stopTestRunStatus: ActionStatus;
  readonly isSameFilter: boolean;
};

export const initialTestRunListState: State = {
  overview: undefined,
  testRuns: undefined,
  totalTestRuns: undefined,
  currentCycles: undefined,
  isLoading: false,
  error: undefined,
  requestFilter: undefined,
  previousRequestFilter: undefined,
  isSameFilter: false,
  createTestRunStatus: { ...INITIAL_ACTION_STATUS },
  startTestRunStatus: { ...INITIAL_ACTION_STATUS },
  pauseTestRunStatus: { ...INITIAL_ACTION_STATUS },
  stopTestRunStatus: { ...INITIAL_ACTION_STATUS },
};

let tempTestRun: TestRun = undefined;
let tempHumanReadableId = undefined;
let newTestRun: TestRun = undefined;
const HUMAN_READABLE_ID = 'humanReadableId';
const ID_STR = 'id';

export const testRunListReducer = (state = initialTestRunListState, action: fromActions.TestRunAction): State => {
  switch (action.type) {
    /*
     *------------------------------- Overview
     */

    case getType(fromActions.fetchOverview.request):
      return {
        ...state,
        isLoading: true,
        error: null,
        createTestRunStatus: { ...INITIAL_ACTION_STATUS },
      };
    case getType(fromActions.fetchOverview.success):
      return {
        ...state,
        isLoading: false,
        overview: action.payload,
      };
    case getType(fromActions.fetchOverview.failure):
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    /*
     *------------------------------- Reset Overview
     */
    case getType(fromActions.resetOverview):
      return {
        ...state,
        overview: null,
      };

    /*
     *------------------------------- Fetch
     */
    case getType(fromActions.fetchTestRunList.request):
      return {
        ...state,
        isLoading: true,
        error: null,
        createTestRunStatus: { ...INITIAL_ACTION_STATUS },
        startTestRunStatus: { ...INITIAL_ACTION_STATUS },
        pauseTestRunStatus: { ...INITIAL_ACTION_STATUS },
        stopTestRunStatus: { ...INITIAL_ACTION_STATUS },
        requestFilter: action.payload,
        previousRequestFilter: state.previousRequestFilter ? { ...state.requestFilter } : null,
        isSameFilter: state.requestFilter?.page > state.previousRequestFilter?.page,
      };
    case getType(fromActions.fetchTestRunList.success):
      /*
       * This block is required to set the highlight of the created test run when using the button on the overview page
       */
      let tempTestRuns: TestRun[];
      let tempTestRunStatus: ActionStatus;
      if (newTestRun) {
        const createdTestRunIndex = action.payload.testRuns.findIndex((testRun) => testRun.id === newTestRun.id);
        if (createdTestRunIndex !== -1) {
          tempTestRuns = [...action.payload.testRuns];
          tempTestRuns[createdTestRunIndex].wasCreated = true;
          tempTestRunStatus = {
            id: tempTestRuns[createdTestRunIndex].humanReadableId,
            isPending: false,
            isSuccess: true,
          };
        }
      }
      newTestRun = null;

      return {
        ...state,
        isLoading: false,
        testRuns: tempTestRuns ?? [...action.payload.testRuns],
        totalTestRuns: action.payload.total,
        previousRequestFilter: { ...state.requestFilter },
        createTestRunStatus: tempTestRunStatus ?? { ...INITIAL_ACTION_STATUS },
      };
    case getType(fromActions.fetchTestRunList.failure):
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        requestFilter: { ...state.previousRequestFilter },
      };

    /*
     *------------------------------- Create
     */
    case getType(fromActions.createTestRun.request):
      newTestRun = null;
      return {
        ...state,
        startTestRunStatus: { ...INITIAL_ACTION_STATUS },
        pauseTestRunStatus: { ...INITIAL_ACTION_STATUS },
        stopTestRunStatus: { ...INITIAL_ACTION_STATUS },
        createTestRunStatus: {
          ...INITIAL_ACTION_STATUS,
          isPending: true,
        },
      };
    case getType(fromActions.createTestRun.success):
      newTestRun = { ...action.payload };
      newTestRun.wasCreated = true;
      let testRuns;
      if (state.testRuns?.length > 0) {
        testRuns = [newTestRun, ...state.testRuns] as TestRun[];
      } else {
        testRuns = [newTestRun] as TestRun[];
      }
      return {
        ...state,
        testRuns: testRuns,
        createTestRunStatus: {
          id: action.payload.humanReadableId,
          isPending: false,
          isSuccess: true,
        },
      };
    case getType(fromActions.createTestRun.failure):
      return {
        ...state,
        createTestRunStatus: {
          isPending: false,
          isSuccess: false,
          error: action.payload,
        },
      };

    /**
     * Test Run Actions
     * */
    /*
     *------------------------------- Start
     */
    case getType(fromActions.startTestRun.request):
      tempTestRun = state.testRuns.find((testRun) => testRun.id === action.payload.id);
      tempHumanReadableId = tempTestRun?.humanReadableId ?? tempTestRun?.id ?? '';
      return {
        ...state,
        createTestRunStatus: { ...INITIAL_ACTION_STATUS },
        pauseTestRunStatus: { ...INITIAL_ACTION_STATUS },
        stopTestRunStatus: { ...INITIAL_ACTION_STATUS },
        startTestRunStatus: { ...INITIAL_ACTION_STATUS, id: tempHumanReadableId, isPending: true },
      };

    case getType(fromActions.startTestRun.success):
      return {
        ...state,
        startTestRunStatus: {
          ...state.startTestRunStatus,
          isPending: false,
          isSuccess: true,
        },
        testRuns: replaceIn(state.testRuns, withKey(action.payload.id, ID_STR), (item) => ({
          ...item,
          ...action.payload,
        })),
      };

    case getType(fromActions.startTestRun.failure):
      return {
        ...state,
        testRuns:
          action.payload.status === 502
            ? replaceIn(state.testRuns, withKey(state.startTestRunStatus.id, HUMAN_READABLE_ID), (item) => ({
                ...item,
                status: 'offline',
              }))
            : state.testRuns,
        startTestRunStatus: {
          ...state.startTestRunStatus,
          isPending: false,
          isSuccess: false,
          error: action.payload,
        },
      };
    /*
     *------------------------------- Pause
     */
    case getType(fromActions.pauseTestRun.request):
      tempTestRun = state.testRuns.find((testRun) => testRun.id === action.payload.id);
      tempHumanReadableId = tempTestRun?.humanReadableId ?? tempTestRun?.id ?? '';
      return {
        ...state,
        createTestRunStatus: { ...INITIAL_ACTION_STATUS },
        startTestRunStatus: { ...INITIAL_ACTION_STATUS },
        stopTestRunStatus: { ...INITIAL_ACTION_STATUS },
        pauseTestRunStatus: { ...INITIAL_ACTION_STATUS, id: tempHumanReadableId, isPending: true },
      };

    case getType(fromActions.pauseTestRun.success):
      return {
        ...state,
        pauseTestRunStatus: {
          ...state.pauseTestRunStatus,
          isPending: false,
          isSuccess: true,
        },
        testRuns: replaceIn(state.testRuns, withKey(action.payload.id, ID_STR), (item) => ({
          ...item,
          ...action.payload,
        })),
      };

    case getType(fromActions.pauseTestRun.failure):
      return {
        ...state,
        testRuns:
          action.payload.status === 502
            ? replaceIn(state.testRuns, withKey(state.pauseTestRunStatus.id, HUMAN_READABLE_ID), (item) => ({
                ...item,
                status: 'offline',
              }))
            : state.testRuns,
        pauseTestRunStatus: {
          ...state.pauseTestRunStatus,
          isPending: false,
          isSuccess: false,
          error: action.payload,
        },
      };
    /*
     *------------------------------- Stop
     */
    case getType(fromActions.stopTestRun.request):
      tempTestRun = state.testRuns.find((testRun) => testRun.id === action.payload.id);
      tempHumanReadableId = tempTestRun?.humanReadableId ?? tempTestRun?.id ?? '';
      return {
        ...state,
        createTestRunStatus: { ...INITIAL_ACTION_STATUS },
        startTestRunStatus: { ...INITIAL_ACTION_STATUS },
        pauseTestRunStatus: { ...INITIAL_ACTION_STATUS },
        stopTestRunStatus: {
          ...INITIAL_ACTION_STATUS,
          id: tempHumanReadableId,
          isPending: true,
        },
      };

    case getType(fromActions.stopTestRun.success):
      return {
        ...state,
        stopTestRunStatus: {
          ...state.stopTestRunStatus,
          isPending: false,
          isSuccess: true,
        },
        testRuns: replaceIn(state.testRuns, withKey(action.payload.id, ID_STR), (item) => ({
          ...item,
          ...action.payload,
        })),
      };

    case getType(fromActions.stopTestRun.failure):
      return {
        ...state,
        testRuns:
          action.payload.status === 502
            ? replaceIn(state.testRuns, withKey(state.stopTestRunStatus.id, HUMAN_READABLE_ID), (item) => ({
                ...item,
                status: 'offline',
              }))
            : state.testRuns,
        stopTestRunStatus: {
          ...state.stopTestRunStatus,
          isPending: false,
          isSuccess: false,
          error: action.payload,
        },
      };

    /*
     *------------------------------- Reset Start Test Run Status
     */

    case getType(fromActions.resetStartTestRunStatus):
      return {
        ...state,
        startTestRunStatus: { ...INITIAL_ACTION_STATUS },
      };

    default:
      return state;
  }
};
