import * as React from 'react';

import { ActionStatus, Recording, RecordingLabelType } from '../models';
import { PButton, PIcon } from '@porsche-design-system/components-react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { LabelConfirmationModal } from '.';
import { colors } from '../colors';
import { getDetailTableStyles } from './CycleFailureDetailModal';
import { getModalStyles } from './Form/ListFilterModal';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { showAudioPlayButton } from '../store/config/selectors';
import { useSelector } from 'react-redux';



const useStyles = makeStyles(
  createStyles({
    ...getDetailTableStyles(),
    ...getModalStyles(),
    cardWrapper: {
      maxWidth: 569,
      display: 'inline-block',
      marginRight: 58,
      marginBottom: 58,
      verticalAlign: 'top',
      'minWidth': 550,
    },
    card: {
      border: '2px solid lightgray',
      padding: 24,
    },
    wrapperSpectogram: {
      display: 'flex',
    },
    spectogramDetails: {
      flex: 1,
      '& span': {
        display: 'block',
        lineHeight: '2.5em',
      },
      '& p': {
        fontWeight: 'bold',
        display: 'inline',
      },
    },
    soundFileWrapper: {
      display: 'flex',
      flex: 1,
    },

    gridSpectogramContainer: {
      display: 'grid',
      gridTemplateColumns: `repeat(4, auto)`,
      paddingTop: '1em',
      minWidth: '90%',
    },
    centerRecordText: {
      display: 'flex',
      justifyContent: 'center',
    },
    spectrogramImage: {
      width: '100%',
    },
    actions: {
      marginTop: '3.5em',
    },
    nioButton: {
      float: 'right',
    },
    ioButton: {
      float: 'right',
    },
    undoButton: {
      float: 'left',
    },
  }),
  { name: 'TrainingRecordingDetailCard' },
);
type Props = {
  recording: Recording;
  recordings: Recording[];
  labelRecording: (labelType: RecordingLabelType) => void;
  selectedRecordingsCount: number;
  trainingDetailUpdateStatus: ActionStatus;
};
export const TrainingRecordingDetailCard: React.FC<Props> = ({
  recording,
  recordings,
  labelRecording,
  selectedRecordingsCount,
  trainingDetailUpdateStatus,
}) => {
  const c = useStyles({ selectedRecordingsCount });
  const createDetails = (recording) => [
    [
      {
        key: 'Teile-Nr.: ',
        value: recording?.orderNum ?? '-',
      },
      {
        key: 'Datum: ',
        value: moment(recording?.created).format('DD.MM.YYYY'),
      },
      {
        key: 'Uhrzeit: ',
        value: `${moment(recording?.created).format('HH:mm:ss')} Uhr`,
      },
      {
        key: 'Temperatur(°C): ',
        value: recording?.temperature ?? '-',
      },
    ],
  ];

  const [details, setDetails] = useState(createDetails(recording));

  useEffect(() => {
    setDetails(createDetails(recording));
  }, [recording]);

  const IOButton = withStyles(() => ({
    root: {
      backgroundColor: colors.snackbarSuccess,
      '&:hover': {
        backgroundColor: colors.snackbarSuccessDarker,
      },
      marginRight: '2em',
      borderRadius: 0,
      boxShadow: 'none',
      height: 55,
      textTransform: 'none',
      fontFamily: `'Porsche Next', 'Arial Narrow', Arial, sans-serif`,
      width: 100,
    },
  }))(Button);

  const NIOButton = withStyles(() => ({
    root: {
      backgroundColor: colors.snackbarError,
      '&:hover': {
        backgroundColor: colors.snackbarErrorDarker,
      },
      borderRadius: 0,
      boxShadow: 'none',
      height: 55,
      textTransform: 'none',
      fontFamily: `'Porsche Next', 'Arial Narrow', Arial, sans-serif`,
      width: 100,
    },
  }))(Button);

  const renderGrid = () => {
    return (
      <div className={c.card}>
        <div className={c.key}>
          {`Sie haben ${selectedRecordingsCount} Dateien ausgewählt.`}
        </div>
        {renderGridItem()}
      </div>
    );
  };


  const renderGridItem = () => {
    return (
      <div className={c.gridSpectogramContainer}>
        {recordings.map(item => (
          <div key={item.id}>
            <span className={c.centerRecordText}>{`Aufnahme ${item.currentCycle}`}</span>
            <img alt={'Spectrogram'} className={c.spectrogramImage}
              src={item.spectrogramURL} title={`${item.created}`} key={item.id} />
          </div>
        ))}
      </div>
    );
  };

  const [confirmLabel, setConfirmLabel] = useState<RecordingLabelType>(undefined);
  const isSubmitDisabled = !recording && selectedRecordingsCount < 1;

  const showAudioPlay: boolean = useSelector(showAudioPlayButton);


  return (
    <>
      {!!confirmLabel && (
        <LabelConfirmationModal
          isOpen={!!confirmLabel}
          onClose={() => setConfirmLabel(undefined)}
          onConfirm={() => labelRecording(confirmLabel)}
          selectedCount={selectedRecordingsCount}
          trainingDetailUpdateStatus={trainingDetailUpdateStatus}
          label={confirmLabel}
        />
      )}
      <div className={c.cardWrapper}>
        {recording && selectedRecordingsCount === 1 ? (
          <div className={c.card}>
            <div className={c.wrapperSpectogram}>
              <div className={c.spectogramDetails}>
                <>
                  <span> <p>Aufnahme: </p> {recording.currentCycle}</span>
                </>
                {details.map((detailRow, index) => (
                  <div key={index}>
                    {detailRow.map((item) => (
                      <div key={item.key + item.value}>
                        <span>
                          <p>{item.key}</p>
                          {item.value}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
                {showAudioPlay &&
                  <audio id={'video-controls'} style={{ marginTop: '2.5em', width: '65%', borderRadius: 15, color: 'black', backgroundColor: 'white' }}
                    src={recording.audioFileURL} preload='auto' controls />
                }
              </div>

              <div className={c.soundFileWrapper}>
                <img src={recording.spectrogramURL} className={c.spectrogramImage} alt='Spectrogram' />
              </div>
            </div>
          </div>
        ) : (
          renderGrid()
        )}
        <div className={c.actions}>
          <PButton
            variant='tertiary'
            icon='replay'
            className={c.undoButton}
            disabled={isSubmitDisabled}
            onClick={() => {
              if (selectedRecordingsCount > 1) {
                !!recording && setConfirmLabel('NONE');
              } else {
                !!recording && labelRecording('NONE');
              }
            }}
          >
            Beschriftungen zurücksetzen
          </PButton>
          <NIOButton
            variant='contained'
            color='primary'
            className={c.nioButton}
            disabled={isSubmitDisabled}
            onClick={() => {
              if (selectedRecordingsCount > 1) {
                setConfirmLabel('NIO');
              } else {
                labelRecording('NIO');
              }
            }}
          >
            <PIcon theme={'dark'} name={'close'} />
            n.i.O.
          </NIOButton>
          <IOButton
            variant='contained'
            color='primary'
            className={c.ioButton}
            disabled={isSubmitDisabled}
            onClick={() => {
              if (selectedRecordingsCount > 1) {
                setConfirmLabel('IO');
              } else {
                labelRecording('IO');
              }
            }}
          >
            <PIcon theme={'dark'} name={'check'} />
            i.O.
          </IOButton>
        </div>
      </div>
    </>
  );
};