import * as React from 'react';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import { colors } from '../colors';
import cx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  {
    header: {
      minWidth: 1011,
      marginBottom: 50,
    },
    headerTitle: {
      fontSize: 36,
      margin: '12px 55px 0 0 ',
      display: 'inline-block',
    },
    backNavigation: {
      fontSize: 16,
      marginTop: -36,
      marginBottom: 12,
    },
    backNavigationIcon: {
      fontSize: 16,
      marginLeft: -28,
      marginRight: 11,
      color: colors.porscheRed,
    },
    link: {
      fontSize: 16,
      textDecoration: 'none',
      color: colors.black,
    },
  },
  { name: 'Header' }
);

export type HeaderNavigationProps = {
  navigationTitle?: string;
  navigationPath?: string;
};
type Props = HeaderNavigationProps & {
  title: string;
  children?: JSX.Element | JSX.Element[];
  headerClass?: string;
  headerTitleClass?: string;
};
export const Header: React.FC<Props> = ({
  title,
  children,
  navigationTitle,
  navigationPath,
  headerClass,
  headerTitleClass,
}) => {
  const c = useStyles({});
  return (
    <>
      {navigationTitle && (
        <Link className={c.link} to={navigationPath}>
          <div className={c.backNavigation}>
            <ArrowBackIosIcon className={c.backNavigationIcon} />
            {navigationTitle}
          </div>
        </Link>
      )}
      <div className={cx(c.header, headerClass)}>
        <h1 className={cx(c.headerTitle, headerTitleClass)}>{title}</h1>
        {children}
      </div>
    </>
  );
};
