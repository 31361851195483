import DateFnsUtils from '@date-io/date-fns';
import { createStyles } from '@material-ui/core/styles';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { PButton } from '@porsche-design-system/components-react';
import { Modal, Select, SelectValue } from '@porsche/ui-kit-react';
import cx from 'clsx';
import { Field, FieldProps, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { CREATED_END_STR, CREATED_START_STR, LABEL_STR } from '../../constants';
import { getModalStyles } from './ListFilterModal';
import { RecordingLabelType } from '../../models';

const useStyles = makeStyles(
  createStyles({
    ...getModalStyles(),
    fixedWidthInput: {
      ...getModalStyles().fixedWidthInput,
      width: 204,
      marginRight: 20,
    },
    fixedWidthSelect: {
      ...getModalStyles().fixedWidthInput,
      width: 256,
    },
  }),
  { name: 'RecordingFilterModal' }
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  filter: RecordingFilterType;
  applyFilter: (filter: RecordingFilterType) => void;
};
if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}
export type RecordingFilterType = {
  [CREATED_START_STR]: Date;
  [CREATED_END_STR]: Date;
  [LABEL_STR]: RecordingLabelType;
};
export const initialFilterInterval: RecordingFilterType = {
  [CREATED_START_STR]: undefined,
  [CREATED_END_STR]: undefined,
  [LABEL_STR]: undefined,
};
export const RecordingFilterModal: React.FC<Props> = ({ isOpen, onClose, applyFilter, filter }) => {
  const c = useStyles({});

  const [initialValues, setInitialValues] = useState<RecordingFilterType>(initialFilterInterval);

  useEffect(() => {
    setInitialValues({ createdStart: filter?.createdStart, createdEnd: filter?.createdEnd, label: filter?.label });
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={c.content}
      containerClassName={c.content}
      contentLabel="Filter auswählen"
      size="dynamic"
    >
      <Modal.Title className={c.title}>Filter auswählen</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          applyFilter(values);
          setSubmitting(false);
          onClose();
        }}
      >
        {({ submitForm, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, values, resetForm }) => (
          <form onSubmit={handleSubmit} className={c.form} noValidate>
            <div className={c.inputContainerRow}>
              <Field name={CREATED_START_STR}>
                {({ field }: FieldProps) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      autoOk
                      clearable
                      className={cx(c.firstDateTimeInput, c.dateTimeInput, c.fixedWidthInput)}
                      label="Von"
                      inputVariant="outlined"
                      variant="dialog"
                      ampm={false}
                      clearLabel={<span>Löschen</span>}
                      cancelLabel={<span>Abbrechen</span>}
                      format="dd.MM.yy - HH:mm"
                      value={field.value ?? null}
                      onChange={(date) => setFieldValue(field.name, date)}
                      maxDate={values[CREATED_END_STR]}
                      maxDateMessage="Startdatum muss vor Enddatum liegen."
                      DialogProps={{ style: { zIndex: 13000 } }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </Field>
              <Field name={CREATED_END_STR}>
                {({ field }: FieldProps) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      autoOk
                      clearable
                      className={cx(c.dateTimeInput, c.fixedWidthInput)}
                      label="Bis"
                      inputVariant="outlined"
                      variant="dialog"
                      ampm={false}
                      clearLabel={<span>Löschen</span>}
                      cancelLabel={<span>Abbrechen</span>}
                      format="dd.MM.yy - HH:mm"
                      value={field.value ?? null}
                      minDate={values[CREATED_START_STR]}
                      minDateMessage="Enddatum muss nach Startdatum liegen."
                      onChange={(date) => setFieldValue(field.name, date)}
                      DialogProps={{ style: { zIndex: 13000 } }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </Field>
              <Field name={LABEL_STR}>
                {({ field }: FieldProps) => (
                  <Select
                    clearable
                    className={cx(c.input, c.select, c.fixedWidthSelect, c.porscheStyleFix)}
                    placeholder={'Beschriftung'}
                    value={field.value}
                    options={[
                      { value: 'IO', label: 'i.O.' },
                      { value: 'NIO', label: 'n.i.O.' },
                      { value: 'NONE', label: 'noch nicht beschriftet' },
                    ]}
                    onChange={(val: SelectValue) => {
                      setFieldValue(field.name, val);
                    }}
                  />
                )}
              </Field>
            </div>
            <div className={c.actions}>
              <PButton
                variant="tertiary"
                icon="reset"
                className={c.cancelButton}
                onClick={() => {
                  resetForm({ values: initialFilterInterval });
                  submitForm();
                }}
              >
                Zurücksetzen
              </PButton>
              <PButton type="submit" disabled={isSubmitting}>
                Bestätigen
              </PButton>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
