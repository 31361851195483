import { Environment, EnvironmentFeatures } from './environment.model';
import { environment as envLocal } from './environment.local';
import { environment as envProd } from './environment.prod';
import { environment as envProdApi } from './environment.prodApi'
import { environment as envDev } from './environment.dev';
import { environment as envDevApi } from './environment.devApi'

const getEnvironment = () => {
  switch (process.env.REACT_APP_STAGING) {
    case 'prodStage':
      return envProd;
    case 'prodApi':
      return envProdApi;
    case 'devStage':
      return envDev;
    case 'devApi':
      return envDevApi;
    case 'local':
      return envLocal;
    default:
      return envLocal;
  }
};

export const environment: Environment = getEnvironment();

export const isFeatureEnabled = <K extends keyof EnvironmentFeatures>(key: K) => environment.features[key];
