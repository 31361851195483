import { createStyles, makeStyles } from '@material-ui/core';
import { PButton } from '@porsche-design-system/components-react';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match, RouteComponentProps } from 'react-router';
import {
  CustomSnackbar,
  Header,
  LoadingMask,
  RecordingFilterInformation,
  TrainingRecordingDetailCard,
  TrainingRecordingList,
} from '../components';
import {
  initialFilterInterval, RecordingFilterModal,
  RecordingFilterType,
} from '../components/Form/RecordingFilterModal';
import { RecordingLabelType, SnackbarStatus } from '../models';
import { sitemap } from '../routes';
import {
  fetchTrainingDetails,
  updateTrainingRecordingsOffset,
  getCurrentTraining,
  getCurrentTrainingRecordingLimit,
  getFetchTrainingDetailStatus,
  getTrainingDescriptionUpdateStatus,
  getTrainingDetailUpdateStatus,
  labelRecordings,
  resetSnackbar, getCurrentTrainingRecordingOffset,
} from '../store/trainings';
import { getFilterButtonStyles } from './TestRunList';


export const getSubtitleStyles = () =>
  createStyles({
    subtitleWrapper: {
      display: 'inline-block',
    },
    subtitle: {
      display: 'inline-block',
      marginRight: 34,
      marginBottom: 0,
    },
    subtitleValue: {
      fontWeight: 'normal',
    },
    listWrapper: {
      display: 'inline-block',
    },
    descriptionWrapper: {
      marginTop: '10px',
      marginBottom: 0,
    },
  });

const useStyles = makeStyles(
  createStyles({
    ...getFilterButtonStyles(),
    ...getSubtitleStyles(),
  }),
  { name: 'TrainingDetail' },
);

type CombinedProps = RouteComponentProps & {
  match: match<{ id: string; recordingClientId: string }>;
};

export const initialSnackbarState = {
  isOpen: false,
  error: undefined,
};

export const TrainingDetail: React.FC<CombinedProps> = ({ match }) => {
  const c = useStyles({});
  const dispatch = useDispatch();
  const training = useSelector(getCurrentTraining);
  const isLoading = useSelector(getFetchTrainingDetailStatus).isPending;
  const trainingDetailUpdateStatus = useSelector(getTrainingDetailUpdateStatus);
  const trainingDescriptionUpdateStatus = useSelector(getTrainingDescriptionUpdateStatus);
  const limit = useSelector(getCurrentTrainingRecordingLimit)
  const offset = useSelector(getCurrentTrainingRecordingOffset)

  useEffect(() => () => { dispatch(resetSnackbar()) }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [checkedIds, setCheckedIds] = useState([]);
  const [filteredRecordings, setFilteredRecordings] = useState([]);
  const [isFirstFetch, setIsFirstFetch] = useState(true);

  useEffect(() => {
    const id = match.params.id;
    if (id !== training?.id) {
      setIsFirstFetch(true);
      dispatch(fetchTrainingDetails.request({ id, limit: limit, offset: offset }));
    }
  }, [match.params]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const id = match.params.id;
    if (trainingDescriptionUpdateStatus.isSuccess) {
      setIsFirstFetch(true);
      dispatch(fetchTrainingDetails.request({ id, limit: limit, offset: offset }));
    }
  }, [trainingDescriptionUpdateStatus]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (match.params.id === training?.id && (isFirstFetch || trainingDetailUpdateStatus.isSuccess || trainingDescriptionUpdateStatus.isSuccess)) {
      setIsFirstFetch(false);
      applyFilter(filter);
    }
  }, [training]); // eslint-disable-line react-hooks/exhaustive-deps

  const [snackbarState, setSnackbarState] = useState<SnackbarStatus>(initialSnackbarState);
  const closeSnackbar = () => {
    setSnackbarState((prevState) => ({ ...prevState, isOpen: false }));
    dispatch(resetSnackbar());
  };

  useEffect(() => {
    setSnackbarState({
      isOpen: trainingDetailUpdateStatus.isSuccess || !!trainingDetailUpdateStatus.error,
      error: trainingDetailUpdateStatus.error,
    });
  }, [trainingDetailUpdateStatus.isSuccess, trainingDetailUpdateStatus.error]);

  const [selectedRecordingId, setSelectedRecordingId] = useState(training?.recordings[0]?.id);
  const labelRecording = (label: RecordingLabelType) => {
    dispatch(
      labelRecordings.request({
        trainingId: match.params.id,
        labels: checkedIds.map((id) => ({
          cycleId: id,
          label: label,
        })),
      }),
    );
  };
  const [filter, setFilter] = useState<RecordingFilterType>(initialFilterInterval);

  const applyFilter = (filter: RecordingFilterType) => {
    setFilter(filter);
    if (!filter?.createdStart && !filter?.createdEnd && !filter?.label) {
      setFilteredRecordings(training?.recordings ?? []);
    } else {
      let filteredRecordings = training.recordings;

      if (filter.label) {
        filteredRecordings = filteredRecordings.filter((recording) => recording.label === filter.label);
      }
      if (filter.createdStart || filter.createdEnd) {
        filteredRecordings = filteredRecordings.filter((recording) => {
          if (filter.createdStart && filter.createdEnd) {
            return recording.created >= filter.createdStart && recording.created <= filter.createdEnd;
          } else if (filter.createdStart) {
            return recording.created >= filter.createdStart;
          } else if (filter.createdEnd) {
            return recording.created <= filter.createdEnd;
          } else {
            return false;
          }
        });
      }
      setCheckedIds((prev) => prev.filter((checkedId) => filteredRecordings.find(({ id }) => id === checkedId)));
      setSelectedRecordingId((prev) => filteredRecordings.find(({ id }) => id === prev)?.id);
      setFilteredRecordings(filteredRecordings);
    }
  };

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const openModal = () => {
    setIsFilterModalOpen(true);
  };

  const loadMore = () => {
    setIsFirstFetch(true);
    dispatch(updateTrainingRecordingsOffset())
    dispatch(fetchTrainingDetails.cancel());
    dispatch(fetchTrainingDetails.request({ id: match.params.id, limit: limit, offset: offset + limit }));
  };

  const closeModal = () => {
    setIsFilterModalOpen(false);
  };
  return (
    <>
      <Header
        navigationTitle={'Zurück zu Trainingsdaten'}
        navigationPath={sitemap.training.record.path}
        title={`Datenbeschriftung - Aufnahme ${training?.startDate ? moment(training.startDate).format('DD.MM.YYYY') : ''
          }`}
      >
        <PButton
          icon='filter'
          onClick={openModal}
          disabled={isLoading}
          variant={!!filter?.createdStart || !!filter?.createdEnd || !!filter?.label ? 'primary' : 'secondary'}
          className={c.filterButton}
          loading={isLoading}
        >
          Filter
        </PButton>
        {training && !isLoading && (
          <PButton
            onClick={loadMore}
            disabled={isLoading}
            className={c.filterButton}
            loading={isLoading}
          >
            Nächste 100 laden
          </PButton>
        )}

        <RecordingFilterModal
          filter={filter}
          isOpen={isFilterModalOpen}
          onClose={closeModal}
          applyFilter={applyFilter}
        />

        {training && !isLoading && (
          <div>
            <div>
              <div className={c.subtitleWrapper}>
                <h4 className={c.subtitle}>
                  Modell<span className={c.subtitleValue}>: {training.carModel}</span>
                </h4>
              </div>
              <div className={c.subtitleWrapper}>
                <h4 className={c.subtitle}>
                  Komponente<span className={c.subtitleValue}>: {training.componentName}</span>
                </h4>
              </div>
            </div>
            <div>
            </div>
          </div>
        )}
      </Header>
      <LoadingMask isLoading={isLoading} />
      <CustomSnackbar
        isSnackbarOpen={snackbarState.isOpen}
        closeSnackbar={closeSnackbar}
        type={snackbarState.error ? 'error' : 'success'}
      >
        <>
          {snackbarState.error ? (
            <span
              id='message-id'>{`Aufnahme konnte nicht beschriftet werden: ${snackbarState.error.message ?? 'Unbekanter Fehler.'
                }`}</span>
          ) : (
            <span id='message-id'>{`Aufnahme wurde erfolgreich beschriftet.`}</span>
          )}
        </>
      </CustomSnackbar>
      {training && !isLoading && (
        <>
          <TrainingRecordingDetailCard
            recording={
              selectedRecordingId === null ? null : filteredRecordings.find(({ id }) => id === selectedRecordingId)
            }
            recordings={filteredRecordings.filter(({ id }) => checkedIds.includes(id))}
            labelRecording={labelRecording}
            selectedRecordingsCount={checkedIds.length}
            trainingDetailUpdateStatus={trainingDetailUpdateStatus}

          />
          <div className={c.listWrapper}>
            <RecordingFilterInformation clearFilter={() => applyFilter(null)} filter={filter} />
            <TrainingRecordingList
              allRecordingsCount={training.actualCycleCount}
              recordings={filteredRecordings}
              setSelectedRecordingId={setSelectedRecordingId}
              selectedRecordingId={selectedRecordingId}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
            />
          </div>
        </>
      )}
    </>
  );
};
