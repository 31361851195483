import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LastPageIcon from '@material-ui/icons/LastPage';
import PauseIcon from '@material-ui/icons/Pause';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableHeadRow } from '.';
import { END_TEST_STR, PAUSE_TEST_STR, START_TEST_STR } from '../constants';
import { findValueInColumns } from '../utils/helpers'
import {
  ActionStatus,
  DetailsWithRecordingClientQuery,
  NamedActionStatus,
  PrefillMapping,
  SelectRecordingClientModalProps,
  TestRun,
} from '../models';
import { createUrlWithParams, sitemap } from '../routes';
import { SelectRecordingClientModal } from './SelectRecordingClientModal';
import { RowType, TableType } from './Table';
import InfoIcon from '@material-ui/icons/Info';
import { createStyles, makeStyles, Modal } from '@material-ui/core';
import { PButton } from '@porsche-design-system/components-react';
import { testingStationColumnNames } from '../store/test-runs/selectors';

type Props = {
  testRuns: TestRun[];
  shouldHideActions?: boolean;
  pauseTestRun?: (testRunRecording: DetailsWithRecordingClientQuery) => void;
  startTestRun?: (testRunRecording: DetailsWithRecordingClientQuery) => void;
  stopTestRun?: (testRunRecording: DetailsWithRecordingClientQuery) => void;
  testRunActionStatus?: NamedActionStatus;
  startTestRunStatus?: ActionStatus;
  testRunControlPrefilling?: PrefillMapping;
  resetStartTestRunStatus?: () => void;
  startDate?: string,
  endDate?: string
};

const useStyles = makeStyles(
  createStyles({
    modalStyles: {
      '& *': {
        backgroundColor: '#fff',
      },
      zIndex: 10,
      margin: 'auto !important',
      width: 600,
      height: 425,

    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    flexHeading: {
      padding: '1em 0 0 2em',
    },
    modalTextLeft: {},
    modalTextRight: {},
    closeBtn: {
      padding: '3em 0',
    },
  }),
);

export const TestRunTable: React.FC<Props> = ({
  testRuns,
  shouldHideActions,
  pauseTestRun,
  startTestRun,
  stopTestRun,
  testRunActionStatus,
  startTestRunStatus,
  testRunControlPrefilling,
  resetStartTestRunStatus,
  startDate,
  endDate
}) => {
  const c = useStyles({});
  const [run, setRun] = useState(null);
  const [detailModalId, setDetailModalId] = useState<string>(undefined);
  const closeDetailModal = () => setDetailModalId(undefined);
  const openDetailModal = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, id: string, testrun: TestRun) => {
    e?.stopPropagation();
    e?.preventDefault();
    setDetailModalId(id);
    setRun(testrun);
  };

  const tenantColumnNames = useSelector(testingStationColumnNames);

  const TESTRUN_LIST_TABLE_HEADERS: TableType[] = [
    { title: 'Prüfstation', key: 'carModel' },
    { title: 'Komponente', key: 'componentName' },
    { title: 'Geprüftes Merkmal', key: 'checkedMethod' },
    { title: 'Referenz Nr.', key: 'humanReadableId' },
    { title: 'Status', key: 'status' },
    { title: findValueInColumns("cycleCountColumn", tenantColumnNames) ?? 'Gelaufene Zyklen', key: 'actualCycleCount' },
    { title: findValueInColumns("ioColumn", tenantColumnNames) ?? 'iO', key: 'io' },
    { title: findValueInColumns("nioColumn", tenantColumnNames) ?? 'niO', key: 'nio' },
    { title: 'Detail', key: 'detail' }, //Todo check for correctness
  ];


  // const user = useSelector(getUser);
  const [modalStatus, setModalStatus] = React.useState<SelectRecordingClientModalProps>(undefined);
  const openSelectRecordingClientIdModal = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent> | React.MouseEvent<HTMLLIElement, MouseEvent>,
    testRunId: string,
    recordingClientId?: string,
  ) => {
    e?.stopPropagation();
    e?.preventDefault();
    setModalStatus({ id: testRunId, recordingClientId: recordingClientId });
  };

  const closeSelectRecordingClientIdModal = () => setModalStatus(undefined);

  const createDetailViewPath = (data: RowType) => {
    const urlWithoutFilter = createUrlWithParams(sitemap.testRuns.detail.path, {
      id: data.entityId,
    });
    let filter = "";
    if (startDate && endDate) {
      filter = `?filter=[{"name":"created","operator":"gt","values":"${startDate}"},{"name":"created","operator":"lt","values":"${endDate}"}]`;
    }
    return urlWithoutFilter + filter
  }


  useEffect(() => {
    if (startTestRunStatus?.isSuccess) {
      closeSelectRecordingClientIdModal();
    }
  }, [startTestRunStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderModal = () => {
    return (
      <Modal
        open={!!detailModalId}
        onClose={closeDetailModal}
        className={c.modalStyles}
        BackdropProps={{ style: { backgroundColor: 'rgba(0,0, 0, 0.8)' } }}
      >
        <div>
          <div className={c.flexHeading}>
            {run && run.id ? <h3> {run.componentName} - Detail</h3> : 'Not loaded yet'}
          </div>
          <div className={c.flexContainer}>
            <div className={c.modalTextLeft}>
              <div>
                {run?.carModel ? <p> <strong>Prüfstation:</strong> {run.carModel} </p> : "Not loaded yet"}
                {run?.componentName ? <p> <strong>Komponente:</strong> {run.componentName} </p> : "Not loaded yet"}
                {run?.startDate ? <p> <strong>Geprüftes Merkmal:</strong> {run?.metaInfo?.checkedFeature ?? "-"} </p> : "Not loaded yet"}
                {run?.startDate ? <p> <strong>Startdatum:</strong> {new Date(Date.parse(run.startDate)).toLocaleString("de-DE")} Uhr </p> : "Not loaded yet"}
                <PButton className={c.closeBtn} onClick={closeDetailModal}>Ok</PButton>
              </div>
            </div>
            <div className={c.modalTextRight}>
              {run?.carModel ? <p> <strong>I-Nummer:</strong> {run?.metaInfo?.iNumber ?? "-"} </p> : "Not loaded yet"}
              {run?.carModel ? <p> <strong>Abteilung:</strong> {run?.metaInfo?.department ?? "-"} </p> : "Not loaded yet"}
              {run?.carModel ? <p> <strong>Zählpunkt:</strong> {run?.metaInfo?.countPoint ?? "-"} </p> : "Not loaded yet"}
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Table>
      {!!modalStatus?.id && (
        <SelectRecordingClientModal
          isOpen={!!modalStatus?.id}
          onClose={closeSelectRecordingClientIdModal}
          id={modalStatus.id}
          recordingClientId={modalStatus.recordingClientId}
          startCallback={startTestRun}
          startActionStatus={startTestRunStatus}
          formPrefilling={testRunControlPrefilling}
          resetActionStatus={resetStartTestRunStatus}
        />
      )}
      <TableHeadRow types={TESTRUN_LIST_TABLE_HEADERS} />
      <TableBody
        data={testRuns?.map<RowType>((testRun) => ({
          entityId: testRun.id,
          recordingClientId: testRun.recordingClientId,
          cols: [
            { text: testRun.carModel },
            { text: testRun.componentName },
            { text: testRun.metaInfo?.checkedFeature ?? "Vibration" },
            { text: testRun.humanReadableId },
            { text: testRun?.status, isStatus: true },
            { text: testRun?.actualCycleCount?.toString() ?? '-' },
            { text: (testRun?.actualCycleCount - testRun?.anomalyCount).toString() },
            { text: testRun?.anomalyCount?.toString() ?? '-' },
            { text: <InfoIcon onClick={(e) => openDetailModal(e, testRun.id, testRun)} />, isDetail: true },
          ],
          isHighlighted: testRun.wasCreated,
          shouldHideActions: shouldHideActions,
          rowActions: !shouldHideActions
            ? [
              {
                name: START_TEST_STR,
                icon: <CheckCircleIcon />,
                callback: (e, handleClose) => {
                  handleClose();
                  openSelectRecordingClientIdModal(e, testRun.id, testRun.recordingClientId);
                },
              },
              {
                name: PAUSE_TEST_STR,
                icon: <PauseIcon />,
                callback: () => pauseTestRun({ id: testRun.id, recordingClientId: testRun.recordingClientId }),
              },
              {
                name: END_TEST_STR,
                icon: <LastPageIcon />,
                callback: () => stopTestRun({ id: testRun.id, recordingClientId: testRun.recordingClientId }),
              },
              // ...(isFeatureEnabled('showMockImplementations') && user?.canDeleteTestRuns
              //   ? [
              //     {
              //       name: 'Löschen',
              //       icon: <DeleteIcon />,
              //       callback: () => console.log('Gelöscht', testRun.id),
              //     },
              //   ]
              //   : []),
            ]
            : [],
        }))}
        type={TESTRUN_LIST_TABLE_HEADERS}
        routeTo={createDetailViewPath}
        actionStatus={testRunActionStatus}
      />
      {renderModal()}
    </Table>
  );
};

