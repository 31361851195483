import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { Cycle, CycleRequest, CycleUpdateRequest, CycleZipRequest, FilteredCycles, HttpError } from '../../models';

import { FilterRequestType } from '../../models/requestFilter.model';

export const fetchCycleFailureList = createAsyncAction(
  '@cycles/FETCH_FAILURE_LIST',
  '@cycles/FETCH_FAILURE_LIST_SUCCESS',
  '@cycles/FETCH_FAILURE_LIST_FAILURE',
  '@cycles/FETCH_FAILURE_LIST_CANCEL'
)<FilterRequestType, FilteredCycles, HttpError, void>();

export const fetchCycleFailureDetail = createAsyncAction(
  '@cycles/FETCH_CYCLE_FAILURE_DETAIL',
  '@cycles/FETCH_CYCLE_FAILURE_DETAIL_SUCCESS',
  '@cycles/FETCH_CYCLE_FAILURE_DETAIL_FAILURE',
  '@cycles/FETCH_CYCLE_FAILURE_DETAIL_CANCEL'
)<CycleRequest, Cycle, HttpError, void>();

export const updateCycleFailureDetail = createAsyncAction(
  '@cycles/UPDATE_CYCLE_FAILURE_DETAIL',
  '@cycles/UPDATE_CYCLE_FAILURE_DETAIL_SUCCESS',
  '@cycles/UPDATE_CYCLE_FAILURE_DETAIL_FAILURE'
)<CycleUpdateRequest, Cycle, HttpError, void>();

export const fetchDownloadZipKey = createAsyncAction(
  '@cycles/FETCH_DOWNLOAD_ZIP_KEY',
  '@cycles/FETCH_DOWNLOAD_ZIP_KEY_SUCCESS',
  '@cycles/FETCH_DOWNLOAD_ZIP_KEY_FAILURE',
  '@cycles/FETCH_DOWNLOAD_ZIP_KEY_CANCEL'
)<CycleZipRequest, string, HttpError, void>();

export const resetZipDownloadUrl = createAction('@cycles/RESET_CYCLE_FAILURE_ZIP_DOWNLOAD_URL')<void>();

export const resetSnackbar = createAction('@cycles/RESET_CYCLE_FAILURE_DETAIL_SNACKBAR')<void>();

export type CycleFailureAction =
  | ActionType<typeof fetchDownloadZipKey>
  | ActionType<typeof fetchCycleFailureList>
  | ActionType<typeof fetchCycleFailureDetail>
  | ActionType<typeof updateCycleFailureDetail>
  | ActionType<typeof resetZipDownloadUrl>
  | ActionType<typeof resetSnackbar>;
