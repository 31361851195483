import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CycleZipRequest } from '../models';
import { DataExportControllerApi, DownloadTicket } from '../proxy';
import { AuthInterceptor } from './auth.interceptor';

export class DataExportService {
  private readonly api = new DataExportControllerApi(AuthInterceptor.Instance);

  downloadZip(request: CycleZipRequest): Observable<string> {
    return this.api
      .generateDownloadKeyUsingGET({ cycleId: request.cycleId, testRunId: request.testRunId })
      .pipe(map((data: DownloadTicket) => data.path));
  }
}
