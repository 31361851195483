import { createStyles, makeStyles } from '@material-ui/styles';
import { Select, SelectValue } from '@porsche/ui-kit-react';
import cx from 'clsx';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { FilterConfiguration } from '../../models';
import { getModalStyles } from './ListFilterModal';

const useStyles = makeStyles(
  createStyles({
    ...getModalStyles(),
    fixedWidthInput: {
      ...getModalStyles().fixedWidthInput,
      width: 295,
      marginRight: 20,
    },
  }),
  { name: 'CycleFailureFilter' }
);

type Props = {
  setFieldValue: (field: string, value: SelectValue) => void;
  formConfiguration: FilterConfiguration;
};

export const TestRunFilter: React.FC<Props> = ({ setFieldValue, formConfiguration }) => {
  const c = useStyles({});

  const carModelStr = 'carModel';
  const componentNameStr = 'componentName';
  const idStr = 'humanReadableId';
  const stateStr = 'state';

  return (
    <>
      <div className={c.inputContainerRow}>
        <Field name={carModelStr}>
          {({ field }: FieldProps) => (
            <Select
              clearable
              multi
              className={cx(c.input, c.select, c.fixedWidthInput, c.porscheStyleFix)}
              placeholder={'Modell'}
              value={field.value}
              options={formConfiguration.elementConfigurations
                .find((filterConfiguration) => filterConfiguration.name === field.name)
                .options.map((option) => ({ value: option, label: option }))}
              onChange={(val) => {
                const typedVal = val as string[];
                setFieldValue(field.name, typedVal.length > 0 ? typedVal : undefined);
              }}
            />
          )}
        </Field>
        <Field name={componentNameStr}>
          {({ field }: FieldProps) => (
            <Select
              clearable
              multi
              className={cx(c.input, c.select, c.fixedWidthInput, c.porscheStyleFix)}
              placeholder={'Komponente'}
              value={field.value}
              options={formConfiguration.elementConfigurations
                .find((filterConfiguration) => filterConfiguration.name === field.name)
                .options.map((option) => ({ value: option, label: option }))}
              onChange={(val) => {
                const typedVal = val as string[];
                setFieldValue(field.name, typedVal.length > 0 ? val : undefined);
              }}
            />
          )}
        </Field>
        <Field name={idStr}>
          {({ field }: FieldProps) => (
            <Select
              clearable
              multi
              className={cx(c.input, c.select, c.fixedWidthInput, c.porscheStyleFix)}
              placeholder={"Referenz Nr."}
              value={field.value}
              options={formConfiguration.elementConfigurations
                .find((filterConfiguration) => filterConfiguration.name === field.name)
                .options.map((option) => ({ value: option, label: option }))}
              onChange={(val) => {
                const typedVal = val as string[];
                setFieldValue(field.name, typedVal.length > 0 ? val : null);
              }}
            />
          )}
        </Field>
      </div>
      <div className={c.inputContainerRow}>
        <Field name={stateStr}>
          {({ field }: FieldProps) => (
            <Select
              clearable
              multi
              className={cx(c.input, c.select, c.fixedWidthInput, c.porscheStyleFix)}
              placeholder={'Status'}
              value={field.value}
              options={formConfiguration.elementConfigurations
                .find((filterConfiguration) => filterConfiguration.name === field.name)
                .options.map((option) => ({ value: option, label: option }))}
              onChange={(val) => {
                const typedVal = val as string[];
                setFieldValue(field.name, typedVal.length > 0 ? val : undefined);
              }}
            />
          )}
        </Field>
      </div>
    </>
  );
};
