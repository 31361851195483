import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PButton } from '@porsche-design-system/components-react';
import { Modal, Select, SelectOption, SelectProps } from '@porsche/ui-kit-react';
import cx from 'clsx';
import React, { useEffect, useState } from 'react';
import { ActionStatus, DetailsWithRecordingClientQuery, PrefillMapping } from '../models';
import { getModalStyles } from './Form/ListFilterModal';

const useStyles = makeStyles(
  createStyles({
    ...getModalStyles(),
  }),
  { name: 'SelectRecordingClientModal' }
);

const humanReadableNamesStr = 'humanReadableNames';
const recordingClientIdsStr = 'recordingClientIds';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  formPrefilling: PrefillMapping;
  id: string;
  startCallback?: (recording: DetailsWithRecordingClientQuery) => void;
  startActionStatus: ActionStatus;
  resetActionStatus?: () => void;
  recordingClientId?: string;
};

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

export const SelectRecordingClientModal: React.FC<Props> = ({
  isOpen,
  onClose,
  formPrefilling,
  id,
  startCallback,
  startActionStatus,
  resetActionStatus,
  recordingClientId,
}) => {
  const c = useStyles({});

  const getHumanReadableIdByRecordingClientId = (recordingClientId: string): string => {
    const index = formPrefilling?.fieldValues
      .find((prefilledField) => prefilledField.fieldName === recordingClientIdsStr)
      ?.values.findIndex((value) => value === recordingClientId);
    return getHumanReadableId(index);
  };

  const getHumanReadableId = (index: number) => {
    return formPrefilling?.fieldValues.find((prefilledField) => prefilledField.fieldName === humanReadableNamesStr)
      ?.values[index];
  };

  const [selectedRecordingClient, setSelectedRecordingClient] = useState({
    id: recordingClientId ?? undefined,
    label: getHumanReadableIdByRecordingClientId(recordingClientId) ?? undefined,
  });

  useEffect(() => {
    if (startActionStatus.isSuccess) {
      resetActionStatus();
      onClose();
    }
  }, [startActionStatus.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={c.content}
      containerClassName={c.content}
      size="dynamic"
      contentLabel="Recording-Client auswählen"
    >
      <Modal.Title className={c.title}>{'Recording-Client auswählen'}</Modal.Title>
      <Modal.Text>
        {'Wählen Sie bitte den Recording-Client aus, mit welchem Sie die Daten aufnehmen möchten.'}
      </Modal.Text>
      <Select
        clearable={false}
        className={cx(c.input, c.select, c.porscheStyleFix)}
        placeholder={'Recording Client *'}
        options={formPrefilling?.fieldValues
          .find((prefilledField) => prefilledField.fieldName === recordingClientIdsStr)
          ?.values.map((option, index) => ({ value: option, label: getHumanReadableId(index) }))}
        value={selectedRecordingClient?.id}
        onChange={(val, props: SelectProps) => {
          const options = props.options as SelectOption[];
          setSelectedRecordingClient({
            id: val.toString(),
            label: options.find((option) => option.value === val)?.label,
          });
        }}
      />
      <div className={c.actions}>
        <PButton variant="tertiary" icon="close" onClick={onClose} className={c.cancelButton}>
          Abbrechen
        </PButton>
        <PButton
          disabled={startActionStatus.isPending || !selectedRecordingClient.id}
          loading={startActionStatus.isPending}
          onClick={() => {
            selectedRecordingClient.id &&
              startCallback({
                id: id,
                recordingClientId: selectedRecordingClient.id,
                humanReadableRecordingClientId: selectedRecordingClient.label,
              });
          }}
        >
          Weiter
        </PButton>
      </div>
    </Modal>
  );
};
