import * as React from 'react';

import { Divider, Flyout, Icon, Spacing } from '@porsche/ui-kit-react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { colors } from '../colors';
import cx from 'clsx';
import { getUser } from '../store/app/selectors';
import logo from '../assets/sounce_logo_white.svg';
import { makeStyles } from '@material-ui/styles';
import { sitemap } from '../routes';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { showAITab, showRecordingsView, showTestrunsView, showClusteringView } from '../store/config/selectors';


const useStyles = makeStyles(
  {
    header: {
      height: 116,
      width: '100%',
      backgroundColor: colors.porscheDark,
      display: 'flex',
      marginBottom: 60,
    },
    logo: {
      margin: '41px 0 42px 130px',
      height: 33,
      width: 267,
      minHeight: 33,
      minWidth: 267,
      fontSize: 18,
    },
    nav: {
      marginLeft: 155,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: 'inherit',
    },
    navItem: {
      fontSize: 18,
      lineHeight: '26px',
      cursor: 'pointer',
      position: 'relative',
    },
    navItemActive: {
      fontWeight: 'bold',
      borderBottom: `5px solid ${colors.porscheRed}`,
      marginBottom: -5,
      '& div': {
        color: colors.white,
      },
    },
    navItemWrapper: {
      textDecoration: 'none',
      color: colors.neutralGrey4,
      backgroundColor: colors.porscheDark,
      display: 'flex',
      alignItems: 'center',
      height: 105,
    },
    porscheNavItemWrapper: {},
    notificationIcon: {
      color: colors.surfaceLight,
      fontSize: 24,
    },
    expandIcon: {
      fontSize: 18,
      verticalAlign: 'middle',
    },
    modelServicePopOutHeader: {
      fontWeight: 'bold',
      padding: '14px 0 10px',
      borderBottom: `1px solid ${colors.greyDarkest}`,
    },
    modelServicePopOut: {
      backgroundColor: colors.neutralGrey7,
      color: colors.white,
    },
    modelServicePopOutItem: {
      color: `${colors.porscheBlack} !important`,
      fontWeight: 'normal',
      fontSize: 16,
      padding: '18px 0 18px 0',
      cursor: 'default',
    },
    flyOut: {
      padding: '0 25px 0 25px',
      minWidth: 247,
      top: 'calc(100% + 40px)',
      left: -42,
      '&:before, &:after': {
        left: 160,
      },
    },
    flyOutIcon: {
      marginRight: 5,
    },
    flyoutNavLink: {
      textDecoration: 'none',
      color: colors.porscheBlack,
    },
    flyoutItemActive: {
      color: colors.porscheRed,
    },
  },
  { name: 'NavigationHeader' }
);

type NavigationItemType = {
  name: string;
  destination: string;
  exact?: boolean;
};

type Props = {
  logout: () => void;
};

export const NavigationHeader: React.FC<Props> = ({ logout }) => {
  const c = useStyles({});
  const isRecordMatch = useRouteMatch(sitemap.training.record.path);
  const isEvaluateMatch = useRouteMatch(sitemap.training.evaluate.path);

  const user = useSelector(getUser);

  const [isModelServiceOpen, setIsModelServiceOpen] = useState(false);

  const showAI: boolean = useSelector(showAITab);

  const showClustering: boolean = useSelector(showClusteringView);

  const showRecordings: boolean = useSelector(showRecordingsView);

  const showTestruns: boolean = useSelector(showTestrunsView);

  const navItemsMain: NavigationItemType[] = [
    { destination: sitemap.home.path, name: sitemap.home.name },
  ];
  return (
    <header className={c.header}>
      <img className={c.logo} src={logo} alt="Sounce Logo" />
      <nav className={c.nav}>
        {navItemsMain.map((item, index) => (
          <div key={index} className={c.navItemWrapper} onClick={() => setIsModelServiceOpen(false)}>
            <NavLink className={c.navItemWrapper} activeClassName={c.navItemActive} to={item.destination}>
              <div className={c.navItem}>{item.name}</div>
            </NavLink>
          </div>
        ))}

        {showTestruns && (
          <>
            <div className={c.modelServicePopOutItem}>
              <NavLink
                className={c.navItemWrapper}
                activeClassName={c.navItemActive}
                to={sitemap.testRuns.home.path}
              >
                <div className={c.navItem}>Prüfstationen</div>
              </NavLink>
            </div>
          </>
        )}

        {showRecordings && (
          <>
            <div className={c.modelServicePopOutItem}>
              <NavLink
                className={c.navItemWrapper}
                activeClassName={c.navItemActive}
                to={sitemap.recordings.home.path}
              >
                <div className={c.navItem}>Aufnahmen</div>
              </NavLink>
            </div>
          </>
        )}

        {showClustering && (
          <>
            <div className={c.modelServicePopOutItem}>
              <NavLink
                className={c.navItemWrapper}
                activeClassName={c.navItemActive}
                to={sitemap.clustering.path}
              >
                <div className={c.navItem}>Clustering</div>
              </NavLink>
            </div>
          </>
        )}

        {user?.canViewTraining && (
          <div
            className={cx(c.navItemWrapper, isRecordMatch || isEvaluateMatch ? c.navItemActive : null)}
            onMouseEnter={() => setIsModelServiceOpen(true)}
            onMouseLeave={() => setIsModelServiceOpen(false)}
          >
            <div className={cx(c.navItem)}>
              KI-Funktionen
              {isModelServiceOpen ? (
                <>
                  <ExpandMoreIcon className={c.expandIcon} />
                  <Flyout className={c.flyOut} position={'left'}>
                    <Spacing marginTop={12} />
                    <div className={c.modelServicePopOutItem}>
                      <NavLink
                        className={c.flyoutNavLink}
                        activeClassName={c.flyoutItemActive}
                        to={sitemap.training.record.path}
                      >
                        <Icon className={c.flyOutIcon} size={'small'} name={'arrow_right_hair'} /> Trainingsdaten
                        erfassen
                      </NavLink>
                    </div>


                    {showAI && (
                      <>
                        <Divider />
                        <div className={c.modelServicePopOutItem}>
                          <NavLink
                            className={c.flyoutNavLink}
                            activeClassName={c.flyoutItemActive}
                            to={sitemap.training.evaluate.path}
                          >
                            <Icon className={c.flyOutIcon} size={'small'} name={'arrow_right_hair'} /> Performance
                            K.I.-Modell evaluieren
                          </NavLink>
                        </div>
                      </>
                    )}
                  </Flyout>
                </>

              ) : (
                <ExpandLessIcon className={c.expandIcon} />
              )}
            </div>
          </div>
        )}
        {/* TODO: correctly call logout function here and clear cookies c */}
        <div className={c.navItemWrapper} onClick={logout}>
          <div className={c.navItem}>Logout</div>
        </div>
      </nav>
    </header>
  );
};
