import { getType } from 'typesafe-actions';
import { INITIAL_ACTION_STATUS } from '../../../constants';
import { ActionStatus, Training } from '../../../models';
import { FilterRequestType } from '../../../models/requestFilter.model';
import * as fromActions from '../actions';

type TrainingState = {
  readonly isLastResult: boolean;
  readonly currentTraining: Training;
  readonly currentTrainingRecordingLimit: number;
  readonly currentTrainingRecordingOffset: number;
  readonly requestFilter: FilterRequestType;
  readonly previousRequestFilter: FilterRequestType;
  readonly isSameFilter: boolean;
  readonly fetchTrainingDetailStatus: ActionStatus;
  readonly trainingDetailUpdateStatus: ActionStatus;
};

const initialState: TrainingState = {
  currentTraining: undefined,
  currentTrainingRecordingLimit: 100,
  currentTrainingRecordingOffset: 0,
  isLastResult: false,
  previousRequestFilter: undefined,
  requestFilter: undefined,
  isSameFilter: false,
  fetchTrainingDetailStatus: { ...INITIAL_ACTION_STATUS },
  trainingDetailUpdateStatus: { ...INITIAL_ACTION_STATUS },
};

export const trainingDetailReducer = (state = initialState, action: fromActions.TrainingAction): TrainingState => {
  switch (action.type) {
    case getType(fromActions.updateTrainingRecordingsOffset):
      return {
        ...state,
        currentTrainingRecordingOffset: state.currentTrainingRecordingOffset + state.currentTrainingRecordingLimit
      };

    // /*
    //  *------------------------------- Fetch
    //  */
    case getType(fromActions.fetchTrainingDetails.request):
      return {
        ...state,
        fetchTrainingDetailStatus: {
          ...INITIAL_ACTION_STATUS,
          isPending: true,
        },
        currentTraining: null,
      };
    case getType(fromActions.fetchTrainingDetails.success):
      return {
        ...state,
        fetchTrainingDetailStatus: {
          ...INITIAL_ACTION_STATUS,
          isSuccess: true,
        },
        currentTraining: action.payload,
      };
    case getType(fromActions.fetchTrainingDetails.failure):
      return {
        ...state,
        fetchTrainingDetailStatus: {
          ...INITIAL_ACTION_STATUS,
          error: action.payload,
        },
      };
    /*
     *------------------------------- Update Recording Labels Details
     */
    case getType(fromActions.labelRecordings.request):
      return {
        ...state,
        trainingDetailUpdateStatus: {
          ...INITIAL_ACTION_STATUS,
          isPending: true,
        },
      };
    case getType(fromActions.labelRecordings.success):
      return {
        ...state,
        currentTraining: action.payload,
        trainingDetailUpdateStatus: {
          ...INITIAL_ACTION_STATUS,
          isSuccess: true,
        },
      };
    case getType(fromActions.labelRecordings.failure):
      return {
        ...state,
        trainingDetailUpdateStatus: {
          ...INITIAL_ACTION_STATUS,
          error: action.payload,
        },
      };

    /*
     *------------------------------- Reset Snackbar
     */
    case getType(fromActions.resetSnackbar):
      return {
        ...state,
        trainingDetailUpdateStatus: {
          ...INITIAL_ACTION_STATUS,
        },
      };

    default:
      return state;

    /*
   *------------------------------- Update Training Details after description has been changed
   */
    case getType(fromActions.updateTrainingDetails.success):
      {

        if (state.currentTraining) {
          const updatedTraining = { ...state.currentTraining, description: action.payload.description }
          return {
            ...state,
            currentTraining: updatedTraining,
          }

        } else {
          return state;
        }

      }
  }
}

