/* eslint-disable @typescript-eslint/no-explicit-any */
import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import {
  AuthService,
  ConfigService,
  CycleService,
  DataExportService,
  Services,
  TestRunService,
  TrainingService,
} from '../services';
import rootEpic from './rootEpic';
import rootReducer, { RootAction, RootState } from './rootReducer';

const configureStore = ({ initialState, history }: any = {}) => {
  const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
    dependencies: {
      authService: new AuthService(),
      configService: new ConfigService(),
      cycleFailureService: new CycleService(),
      dataExportService: new DataExportService(),
      testRunService: new TestRunService(),
      trainingService: new TrainingService(),
    },
  });

  const middlewares = [epicMiddleware];

  let composeEnhancers = compose;
  if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }

  const newStore = createStore(rootReducer(history), initialState, composeEnhancers(applyMiddleware(...middlewares)));

  epicMiddleware.run(rootEpic);

  if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
    (module as any).hot.accept('./rootReducer', () => newStore.replaceReducer(require('./rootReducer').default));
  }

  return newStore;
};

export const store: any =
  window.store ||
  configureStore({
    initialState: window.__PRELOADED_STATE__,
  });
