import { getType } from 'typesafe-actions';
import { Config, HttpError } from '../../models';
import * as fromActions from './actions';
type State = {
  readonly config: Config;
  readonly isLoading: boolean;
  readonly error: HttpError;
};


const initialState: State = {
  config: {
    filterConfigurations: undefined,
    formPrefillings: undefined,
    tenantSettings: undefined,
  },
  isLoading: false,
  error: undefined,
};
export const configReducer = (state = initialState, action: fromActions.ConfigAction): State => {
  switch (action.type) {
    case getType(fromActions.fetchInitialConfig.request):
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case getType(fromActions.fetchInitialConfig.success):
      return {
        ...state,
        isLoading: false,
        config: { ...action.payload }
      };
    case getType(fromActions.fetchInitialConfig.failure):
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
