import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PButton } from '@porsche-design-system/components-react';
import { Modal } from '@porsche/ui-kit-react';
import React, { useEffect } from 'react';
import { ActionStatus, DetailsWithRecordingClientQuery, SelectRecordingClientModalProps } from '../models';
import { getModalStyles } from './Form/ListFilterModal';

const useStyles = makeStyles(
  createStyles({
    ...getModalStyles(),
    boldRecording: {
      fontWeight: 'bold',
    },
  }),
  { name: 'StartRecordingConfirmationModal' }
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (recording: DetailsWithRecordingClientQuery) => void;
  startActionStatus: ActionStatus;
  resetActionStatus?: () => void;
  confirmationModalStatus?: SelectRecordingClientModalProps;
};

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

export const StartRecordingConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  startActionStatus,
  resetActionStatus,
  confirmationModalStatus,
}) => {
  const c = useStyles({});
  useEffect(() => {
    if (startActionStatus?.isSuccess) {
      resetActionStatus();
      onClose();
    }
  }, [startActionStatus.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={c.content}
      containerClassName={c.content}
      size="dynamic"
      contentLabel="Trainingsdaten erfassen"
    >
      <Modal.Title className={c.title}>{'Trainingsdaten erfassen'}</Modal.Title>
      <Modal.Text>
        {'Sind Sie sicher, dass Sie die Datenerfassung mit '}
        <span
          className={c.boldRecording}
        >{`Recording-Client ${confirmationModalStatus?.humanReadableRecordingClientId}`}</span>
        {' starten möchten?'}
      </Modal.Text>
      <div className={c.actions}>
        <PButton variant="tertiary" icon="close" onClick={onClose} className={c.cancelButton}>
          Abbrechen
        </PButton>
        <PButton
          disabled={startActionStatus.isPending}
          loading={startActionStatus.isPending}
          onClick={() => {
            onConfirm({ id: confirmationModalStatus.id, recordingClientId: confirmationModalStatus.recordingClientId });
          }}
        >
          Datenerfassung starten
        </PButton>
      </div>
    </Modal>
  );
};
