import { MetaInfo } from "../proxy";

export type TestRun = {
  actualCycleCount: number;
  anomalyCount: number;
  aiModelId: string;
  carModel: string;
  changeDate: Date;
  componentName: string;
  endDate: Date;
  id: string;
  plannedCycleCount: number;
  recordingClientId: string;
  startDate: Date;
  status: RecordingStatus;
  description?: string;
  engineerName?: string;
  humanReadableId?: string;
  testRunDataHistory?: TestRunDataHistory;
  wasCreated?: boolean;
  metaInfo?: MetaInfo
};

export type TestRunDataHistoryBin = {
  startDate: string;
  endDate: string;
  nio: number;
  io: number;
  total: number;
};

export type TestRunDataHistory = {
  start: string;
  end: string;
  intervals: Array<TestRunDataHistoryBin>
};

export type CycleInfo = {
  humidity: number;
  isAnomaly: boolean;
  temperature: number;
}

export type RecordingStatus = 'angelegt' | 'in_betrieb' | 'standby' | 'pausiert' | 'offline' | 'abgeschlossen' | '-';

export enum TestRunStatusEnum {
  CREATED = 'CREATED',
  RUNNING = 'RUNNING',
  STANDBY = 'STANDBY',
  PAUSED = 'PAUSED',
  OFFLINE = 'OFFLINE',
  FINISHED = 'FINISHED',
}

export type NewTestRun = {
  aiModelId: string;
  carModel: string;
  componentName: string;
  description?: string;
  endDate: Date;
  plannedCycleCount: number;
  startDate: Date;
};

export type DetailsQuery = {
  id: string;
  limit?: number;
  offset?: number;
  fromDate?: string
  toDate?: string
};

export type DetailsWithRecordingClientQuery = DetailsQuery & {
  recordingClientId: string;
  humanReadableRecordingClientId?: string;
};

export type FilteredTestRuns = {
  testRuns: TestRun[];
  total: number;
};

export type TestRunOverview = {
  summary: Summary;
  latest: TestRunInfo[];
};

export type Summary = {
  sortedByState: { [key: string]: number };
  total: number;
};

export type TestRunInfo = {
  testRunId: string;
  actualCycleCount: number;
  carModel: string;
  name: string;
  componentName: string;
  humanReadableId: string;
  ioPerCent: number;
  nioPerCent: number;
  state: RecordingStatus;
};