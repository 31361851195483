// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface NewTestRunDTO
 */
export interface NewTestRunDTO {
    /**
     * @type {string}
     * @memberof NewTestRunDTO
     */
    componentName: string;
    /**
     * @type {string}
     * @memberof NewTestRunDTO
     */
    description?: string;
    /**
     * @type {number}
     * @memberof NewTestRunDTO
     */
    plannedCycleCount?: number;
    /**
     * @type {string}
     * @memberof NewTestRunDTO
     */
    carModel: string;
    /**
     * @type {string}
     * @memberof NewTestRunDTO
     */
    aiModelId: string;
    /**
     * @type {Date}
     * @memberof NewTestRunDTO
     */
    startDate: Date;
    /**
     * @type {Date}
     * @memberof NewTestRunDTO
     */
    endDate: Date;
    /**
     * @type {string}
     * @memberof NewTestRunDTO
     */
    state?: NewTestRunDTOStateEnum;
    /**
     * @type {string}
     * @memberof NewTestRunDTO
     */
    humanReadableId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum NewTestRunDTOStateEnum {
    CREATED = 'CREATED',
    RUNNING = 'RUNNING',
    STANDBY = 'STANDBY',
    PAUSED = 'PAUSED',
    FINISHED = 'FINISHED',
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE'
}

