import { makeStyles } from '@material-ui/styles';
import cx from 'clsx';
import React from 'react';
import { colors } from '../colors';
import { HEADER_HEIGHT } from '../constants';

const useStyles = makeStyles(
  {
    root: {
      minHeight: '100vh',
      width: '100%',
      maxWidth: 1440,
      margin: '0 auto',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: colors.white,
    },
    content: {
      display: 'flex',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    main: {
      position: 'relative',
      flex: 1,
    },
    page: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 'fit-content',
      margin: '0 auto',
    },
    pageHasHeader: {
      top: HEADER_HEIGHT,
    },
  },
  { name: 'Main' }
);

type Props = {
  header: React.ReactNode;
  children: React.ReactNode;
};

export const Main: React.FC<Props> = ({ children, header }) => {
  const c = useStyles({});

  return (
    <div className={c.root}>
      <div className={c.content}>
        <main className={c.main}>
          {header}
          <section className={cx(c.page, c.pageHasHeader)}>{children}</section>
        </main>
      </div>
    </div>
  );
};
