import * as React from 'react';

import { Cycle, NamedActionStatus, TestRun, Training } from '../models';
import { useEffect, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Loader } from '@porsche/ui-kit-react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { StatusChip } from '.';
import { colors } from '../colors';
import cx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  {
    table: {
      width: '-webkit-fill-available',
      fallbacks: {
        width: '-moz-available',
      },
      '&.is-scrollable': {
        tbody: {
          overflowY: 'scroll',
          width: 'auto',
          position: 'absolute',
        },
      },
      borderCollapse: 'collapse',
    },
    iconContainer: {
      marginLeft: '1vh',
    },
    select: {
      marginLeft: '2vh',
    },
    dropdownItem: {
      color: colors.neutralGrey5,
      display: 'block',
      fontSize: '0.875rem',
      lineHeight: '1.5',
      padding: '0.375rem 1rem',
      position: 'relative',
    },
    'span.dropdown-item:hover,  button.dropdown-item:hover': {
      backgroundColor: colors.neutralGrey5,
      color: colors.black,
    },
    td: {
      cursor: 'pointer',
    },
    dot: {
      width: 12,
      height: 12,
      backgroundColor: colors.snackbarSuccess,
      position: 'absolute',
      borderRadius: 15,
      margin: '7px 0 0 -24px',
    },
    tableHead: {
      textAlign: 'left',
      fontSize: 14,
      fontWeight: 'normal',
      color: colors.neutralGrey6,
      paddingBottom: 5,
    },
    tableRow: {
      cursor: 'pointer',
    },
    tableRowBordered: {
      borderBottom: `1px solid ${colors.tableBorder}`,
    },
    tableData: {
      fontSize: 16,
      padding: 0,
    },
    tableAnchor: {
      display: 'inline-block',
      minHeight: '100%',
      width: '100%',
      padding: '20px 0 20px',
      cursor: 'pointer',
      textDecoration: 'none',
      color: colors.porscheBlack,
    },
    actionItem: {
      paddingRight: 52,
    },
    actionText: {
      fontFamily: `'Porsche Next', 'Arial Narrow', Arial, sans-serif`,
      fontSize: '16px',
    },
    actionMenu: {
      padding: '23px 0 23px',
    },
    actionIcon: {
      width: 35,
      height: 35,
      border: `1px solid ${colors.porscheBlack}`,
      borderRadius: '100%',
      margin: '0 18px 0 8px',
      '&>svg': {
        padding: 6,
        fontSize: '23px',
      },
    },
    loaderActionIcon: {
      width: 35,
      height: 35,
    },
  },
  { name: 'Table' }
);

export type TableType = {
  key: any |'detail' | keyof TestRun | keyof Cycle | keyof Training;
  title: string;
};
type TableProps = {
  children: JSX.Element | JSX.Element[];
};
export const Table: React.FC<TableProps> = ({ children }) => {
  const c = useStyles({});
  return <table className={cx(c.table, 'is-hoverable')}>{children}</table>;
};

type TableHeadRowProps = {
  types: TableType[];
};

export const TableHeadRow: React.FC<TableHeadRowProps> = ({ types }) => (
  <thead>
    <tr>
      {types.map((head, index) => (
        <TableHead key={index} head={head} />
      ))}
    </tr>
  </thead>
);
type TableHeadProps = {
  head: TableType;
};
export const TableHead: React.FC<TableHeadProps> = ({ head }) => {
  const c = useStyles({});
  return <th className={c.tableHead}>{head.title}</th>;
};

export type RowAction = {
  name: string;
  icon?: JSX.Element;
  callback: (
    e?: React.MouseEvent<HTMLLIElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>,
    handleClose?: () => void
  ) => void;
};

export type RowType = {
  entityId: string;
  recordingClientId?: string;
  cols?: ColType[];
  referenceSpectrogram?: string;
  isHighlighted?: boolean;
  rowActions?: RowAction[];
  shouldHideActions?: boolean;
};
export type ColType = {
  text: string | JSX.Element;
  isStatus?: boolean;
  isDetail?: boolean;
};

type TableBodyProps = {
  data: RowType[];
  type: TableType[];
  actionStatus?: NamedActionStatus;
  routeTo?: (data: RowType) => string | { pathname: string; state: { from: string } };
};

export const TableBody: React.FC<TableBodyProps> = ({ data, type, actionStatus, routeTo }) => {
  const c = useStyles({});

  return (
    <tbody>
      {data?.map((entry, index) => (
        <React.Fragment key={`${entry.entityId}_${index}`}>
          <tr className={data.length > 1 ? c.tableRowBordered : c.tableRow}>
            {entry.cols.map((col, index) => (
              <td className={c.tableData} key={`${col.text}_${index}`}>
                <Link className={c.tableAnchor} to={routeTo(entry)}>
                  {entry.isHighlighted && index === 0 && <span className={c.dot}></span>}
                  {col.isStatus ? <StatusChip text={col.text as string} /> : col.text}
                </Link>
              </td>
            ))}
            {entry.rowActions && !entry.shouldHideActions && (
              <td>
                <MenuButton entry={entry} actionStatus={actionStatus} />
              </td>
            )}
          </tr>
        </React.Fragment>
      ))}
    </tbody>
  );
};
type MenuProps = {
  entry: RowType;
  actionStatus?: NamedActionStatus;
};
export const MenuButton: React.FC<MenuProps> = ({ entry, actionStatus }) => {
  const c = useStyles({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (actionStatus.isFinished) {
      handleMenuClose();
    }
  }, [actionStatus]);

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    e?.preventDefault();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} classes={{ list: c.actionMenu }}>
        {entry.rowActions.map((action, index) => (
          <MenuItem
            className={c.actionItem}
            key={action.name}
            disabled={actionStatus.isPending}
            onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
              if (!actionStatus.isPending) {
                e.stopPropagation();
                e.preventDefault();
                action.callback(e, handleMenuClose);
              }
            }}
          >
            {actionStatus.isPending && action.name === actionStatus.actionName ? (
              <div className={c.actionIcon}>
                <Loader className={c.loaderActionIcon} />
              </div>
            ) : (
              <div className={c.actionIcon}>{action.icon}</div>
            )}
            <div className={c.actionText}>{action.name}</div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
