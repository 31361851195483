import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import {
  DetailsWithRecordingClientQuery,
  FilteredTrainings,
  HttpError,
  NewTraining,
  Training,
  DetailsQuery,
  LabelRecordingRequest,
} from '../../models';
import { FilterRequestType } from '../../models/requestFilter.model';

export const fetchTrainings = createAsyncAction(
  '@trainings/FETCH_TRAININGS',
  '@trainings/FETCH_TRAININGS_SUCCESS',
  '@trainings/FETCH_TRAININGS_FAILURE',
  '@trainings/FETCH_TRAININGS_CANCEL'
)<FilterRequestType, FilteredTrainings, HttpError, void>();

export const resetTrainings = createAction('@trainings/RESET_TRAININGS')<void>();

export const createTraining = createAsyncAction(
  '@trainings/CREATE_TRAINING',
  '@trainings/CREATE_TRAINING_SUCCESS',
  '@trainings/CREATE_TRAINING_FAILURE'
)<NewTraining, Training, HttpError>();

export const pauseTraining = createAsyncAction(
  '@trainings/PAUSE_TRAINING',
  '@trainings/PAUSE_TRAINING_SUCCESS',
  '@trainings/PAUSE_TRAINING_FAILURE'
)<DetailsWithRecordingClientQuery, Training, HttpError>();

export const startTraining = createAsyncAction(
  '@trainings/START_TRAINING',
  '@trainings/START_TRAINING_SUCCESS',
  '@trainings/START_TRAINING_FAILURE'
)<DetailsWithRecordingClientQuery, Training, HttpError>();

export const stopTraining = createAsyncAction(
  '@trainings/STOP_TRAINING',
  '@trainings/STOP_TRAINING_SUCCESS',
  '@trainings/STOP_TRAINING_FAILURE'
)<DetailsWithRecordingClientQuery, Training, HttpError>();

export const labelRecordings = createAsyncAction(
  '@trainings/LABEL_RECORDING',
  '@trainings/LABEL_RECORDING_SUCCESS',
  '@trainings/LABEL_RECORDING_FAILURE',
  '@trainings/LABEL_RECORDING_CANCEL'
)<LabelRecordingRequest, Training, HttpError, void>();

//fetch filtered test run cycles

export const fetchTrainingDetails = createAsyncAction(
  '@trainings/FETCH_TRAINING_DETAILS',
  '@trainings/FETCH_TRAINING_DETAILS_SUCCESS',
  '@trainings/FETCH_TRAINING_DETAILS_FAILURE',
  '@trainings/FETCH_TRAINING_DETAILS_CANCEL'
)<DetailsQuery, Training, HttpError, void>();

export const updateTrainingDetails = createAsyncAction(
  '@trainings/UPDATE_TRAINING_DETAILS',
  '@trainings/UPDATE_TRAINING_DETAILS_SUCCESS',
  '@trainings/UPDATE_TRAINING_DETAILS_FAILURE',
  '@trainings/UPDATE_TRAINING_DETAILS_CANCEL'
)<Training, Training, HttpError, void>();

export const updateTrainingRecordingsOffset = createAction('@trainings/UPDATE_TRAINING_RECORDINGS_OFFSET')<void>();
export const updateTrainingInTrainings = createAction('@trainings/UPDATE_TRAINING_DETAILS_IN_TRAININGs')<Training>();
export const resetCreateTrainingStatus = createAction('@trainings/RESET_CREATE_TRAINING_STATUS')<void>();
export const resetStartTrainingStatus = createAction('@trainings/RESET_START_TRAINING_STATUS')<void>();

export const resetSnackbar = createAction('@trainings/RESET_TRAINING_DETAILS_SNACKBAR')<void>();

export type TrainingAction =
  | ActionType<typeof fetchTrainings>
  | ActionType<typeof createTraining>
  | ActionType<typeof pauseTraining>
  | ActionType<typeof startTraining>
  | ActionType<typeof stopTraining>
  | ActionType<typeof fetchTrainingDetails>
  | ActionType<typeof updateTrainingDetails>
  | ActionType<typeof labelRecordings>
  | ActionType<typeof resetTrainings>
  | ActionType<typeof resetStartTrainingStatus>
  | ActionType<typeof resetCreateTrainingStatus>
  | ActionType<typeof updateTrainingRecordingsOffset>
  | ActionType<typeof resetSnackbar>;
