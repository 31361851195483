import { RootAction, RootState } from '../rootReducer';
import { catchErrorAndHandleWithAction, takeUntilAction } from '../../utils';
import {
  fetchCycleFailureDetail,
  fetchCycleFailureList,
  fetchDownloadZipKey,
  updateCycleFailureDetail,
} from './actions';
import { filter, map, switchMap } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { Services } from '../../services';
import { isActionOf } from 'typesafe-actions';

const fetchCycleFailureListEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { cycleFailureService }
) =>
  action$.pipe(
    filter(isActionOf(fetchCycleFailureList.request)),
    switchMap(({ payload }) =>
      cycleFailureService
        .getAll(payload)
        .pipe(
          map(fetchCycleFailureList.success),
          catchErrorAndHandleWithAction(fetchCycleFailureList.failure),
          takeUntilAction(action$, fetchCycleFailureList.cancel)
        )
    )
  );

const fetchTestFailureEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { cycleFailureService }
) =>
  action$.pipe(
    filter(isActionOf(fetchCycleFailureDetail.request)),
    switchMap(({ payload }) =>
      cycleFailureService
        .getById(payload.entityId, payload.recordingClientId, payload.provideIo)
        .pipe(
          map(fetchCycleFailureDetail.success),
          catchErrorAndHandleWithAction(fetchCycleFailureDetail.failure),
          takeUntilAction(action$, fetchCycleFailureDetail.cancel)
        )
    )
  );
const updateFailureDetailEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { cycleFailureService }
) =>
  action$.pipe(
    filter(isActionOf(updateCycleFailureDetail.request)),
    switchMap(({ payload }) =>
      cycleFailureService
        .updateOne(payload)
        .pipe(map(updateCycleFailureDetail.success), catchErrorAndHandleWithAction(updateCycleFailureDetail.failure))
    )
  );

const downloadZipEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { dataExportService }) =>
  action$.pipe(
    filter(isActionOf(fetchDownloadZipKey.request)),
    switchMap(({ payload }) =>
      dataExportService
        .downloadZip(payload)
        .pipe(map(fetchDownloadZipKey.success), catchErrorAndHandleWithAction(fetchDownloadZipKey.failure))
    )
  );

export default [fetchCycleFailureListEpic, fetchTestFailureEpic, updateFailureDetailEpic, downloadZipEpic];
