import * as CSS from 'csstype';

import { ThemeOptions, createTheme } from '@material-ui/core';

import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { colors } from './colors';

/**
 * helper typings copied from Material UI
 */

interface CSSProperties extends CSS.Properties<number | string> {
  // Allow pseudo selectors and media queries
  [k: string]: CSS.Properties<number | string>[keyof CSS.Properties] | CSSProperties;
}

type StyleRules<ClassKey extends string = string> = Record<ClassKey, CSSProperties>;

export function createStyles<C extends string>(styles: StyleRules<C>): StyleRules<C> {
  return styles;
}

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey { } //eslint-disable-line
}

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: colors.porscheRed,
    },
    secondary: {
      main: colors.porscheBlack,
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.porscheBlack,
      },
    },

    MuiPickersBasePicker: {
      pickerView: {
        margin: '0 auto',
      },
    },

    MuiTypography: {
      h4: {
        fontSize: 34,
      },
    },
    PrivateTabIndicator: {
      root: {
        width: '50% !important',
      },
      colorSecondary: {
        backgroundColor: colors.porscheRed,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
      transitionContainer: {
        color: colors.black,
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: colors.black,
      },
    },
    MuiPickersDay: {
      day: {
        color: colors.black,
      },
      daySelected: {
        backgroundColor: colors.porscheRed,
        '&:hover': {
          backgroundColor: colors.porscheRed,
        },
      },
      // dayDisabled: {
      //   color: lightBlue['100'],
      // },

      current: {
        color: colors.greyDarker,
      },
    },
    MuiInputBase: {
      root: {
        '&$error': {
          color: colors.statusRed,
        },
      },
      input: {
        // fixes border radius on iOS
        borderRadius: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        background: colors.white,
        borderRadius: 4,
        '&$focused $notchedOutline': {
          borderColor: colors.tableBorder,
          borderWidth: 1,
        },
        '&:hover $notchedOutline': {
          borderColor: colors.tableBorder,
          borderWidth: 1,
        },
        '&:hover $input': {
          color: colors.porscheRed,
        },
      },
      input: {
        padding: '13.5px 14px', // to reach 46px height
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.inputLabel,
        '&$error': {
          color: colors.statusRed,
        },
        '&$focused': {
          color: colors.inputLabel,
        },
      },
      outlined: {
        color: colors.porscheBlack,
        transform: 'translate(14px, 15px) scale(1)', // for 46px height
      },
      shrink: {
        color: `${colors.inputLabel} !important`,
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 10001, // take that, porsche-ui-kit
      },
      anchorOriginTopCenter: {
        '@media (min-width: 600px)': {
          top: 100,
        },
      },
    },
    PrivateNotchedOutline: {
      root: {
        borderRadius: 0,
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: `'Porsche Next', 'Arial Narrow', Arial, sans-serif`,
        fontSize: 18,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: 37,
      },
      label: {
        fontFamily: `'Porsche Next', 'Arial Narrow', Arial, sans-serif`,
        fontSize: 16,
      },
    },
  },
} as ThemeOptions);
