import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';
import { ALL_FAILURES_MULTI_FILTER, TEST_RUN_FAILURES_MULTI_FILTER, TEST_RUN_MULTI_FILTER } from '../../constants';

// Cycle-Failure-List
const cycleFailureListState = (state: RootState) => state.cycleFailureListReducer;
const initialConfigState = (state: RootState) => state.configReducer;

export const cycleFailureListSelector = createSelector(cycleFailureListState, (state) => state.cycles);
export const getTotalCycleFailures = createSelector(cycleFailureListState, (state) => state.totalCycleFailures);
export const getIsListLoading = createSelector(cycleFailureListState, (state) => state.isLoading);

export const getAllFailureListFilterConfigurations = createSelector(initialConfigState, (state) =>
  state.config.filterConfigurations?.find((op) => op.name === ALL_FAILURES_MULTI_FILTER)
);

export const getTestRunFailureListFilterConfigurations = createSelector(initialConfigState, (state) =>
  state.config.filterConfigurations?.find((op) => op.name === TEST_RUN_FAILURES_MULTI_FILTER)
);

export const getTestRunListFilterConfigurations = createSelector(initialConfigState, (state) =>
  state.config.filterConfigurations?.find((op) => op.name === TEST_RUN_MULTI_FILTER)
);

export const getLastRequestFilter = createSelector(cycleFailureListState, (state) => state.requestFilter);

// Cycle-Failure-Details
const cycleFailureDetailState = (state: RootState) => state.cycleFailureDetailReducer;

export const getCurrentCycle = createSelector(cycleFailureDetailState, (state) => state.currentCycle);

export const getIsDetailLoading = createSelector(cycleFailureDetailState, (state) => state.isLoading);

export const getCycleFailureDetailUpdateStatus = createSelector(
  cycleFailureDetailState,
  (state) => state.cycleFailureDetailUpdateStatus
);
export const getZipDownloadStatus = createSelector(cycleFailureDetailState, (state) => state.zipDownloadActionStatus);

export const getZipDownloadUrl = createSelector(cycleFailureDetailState, (state) => state.zipDownloadUrl);
