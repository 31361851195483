import * as React from 'react';

import { CycleFailureTable, Header, LoadingMask } from '../components';
import { FormControlLabel, createStyles, makeStyles } from '@material-ui/core';
import { INITIAL_REQUEST_FILTER_CYCLE_FAILURES, KEYDOWN_EVENT, SHOW_IO } from '../constants';
import {
  createFilterQueryParam,
  decodeURIEncodedFilterJSONStringAndEnhanceWithIOFilter,
  getLocalStorageValue,
  hasFilter,
  onArrowKeyPagination,
  setFilterQueryParamsInURL,
  setLocalStorageValue,
} from '../utils';
import {
  cycleFailureListSelector,
  fetchCycleFailureList,
  getAllFailureListFilterConfigurations,
  getIsListLoading,
  getLastRequestFilter,
  getTotalCycleFailures,
} from '../store/cycle-failures';
import { getFilterButtonStyles, getPaginationStyles } from './TestRunList';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ListFilterModal } from '../components/Form/ListFilterModal';
import { PButton } from '@porsche-design-system/components-react';
import { Pagination } from '@porsche/ui-kit-react';
import { RouteComponentProps } from 'react-router';
import { SliderToggle } from '../components/SliderToggle';

const useStyles = makeStyles(
  createStyles({
    ...getFilterButtonStyles(),
    ...getPaginationStyles(),
  }),
  { name: 'CycleFailureList' }
);

export const CycleFailureList: React.FC<RouteComponentProps> = ({ history, location }) => {
  const c = useStyles({});
  const dispatch = useDispatch();
  const cycles = useSelector(cycleFailureListSelector);
  const totalCycleFailures = useSelector(getTotalCycleFailures);
  const filterConfigurations = useSelector(getAllFailureListFilterConfigurations);
  const isLoading = useSelector(getIsListLoading);
  const lastRequestFilter = useSelector(getLastRequestFilter);

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [showIORecordings, setShowIORecordings] = useState(getLocalStorageValue(SHOW_IO) === 'true');

  const onKeyDown = (e) => onArrowKeyPagination(e, lastRequestFilter, totalCycleFailures, loadCycleFailuresPage);
  const addOnKeyDown = () => document.addEventListener(KEYDOWN_EVENT, onKeyDown, false);
  const removeOnKeyDown = () => document.removeEventListener(KEYDOWN_EVENT, onKeyDown, false);

  useEffect(() => {
    if (!isFilterOpen) {
      addOnKeyDown();
    }
    return () => removeOnKeyDown();
  });

  useEffect(() => {
    dispatch(
      fetchCycleFailureList.request(
        decodeURIEncodedFilterJSONStringAndEnhanceWithIOFilter(
          INITIAL_REQUEST_FILTER_CYCLE_FAILURES,
          location.search,
          showIORecordings
        )
      )
    );

    return () => { dispatch(fetchCycleFailureList.cancel()) };
  }, [showIORecordings]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCycleFailuresPage = (event: React.MouseEvent<HTMLElement, MouseEvent> | KeyboardEvent, page: number) => {
    const newFilter = { ...lastRequestFilter };
    newFilter.page = page;
    dispatch(fetchCycleFailureList.request(newFilter));
  };

  const openModal = () => {
    removeOnKeyDown();
    setIsFilterOpen(true);
  };

  const closeModal = () => {
    addOnKeyDown();
    setIsFilterOpen(false);
  };

  const handleIOToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalStorageValue(SHOW_IO, event.target.checked.toString());
    setShowIORecordings(event.target.checked);
  };

  return (
    <>
      <Header title={'Alle Aufnahmen'} headerTitleClass={c.headerTitle} headerClass={c.header}>
        <FormControlLabel
          control={<SliderToggle checked={showIORecordings} onChange={handleIOToggleChange} />}
          label="i.O. Aufnahmen einblenden"
        />
        <PButton
          icon="filter"
          onClick={openModal}
          disabled={isLoading}
          variant={hasFilter(lastRequestFilter) ? 'primary' : 'secondary'}
          className={c.filterButton}
          loading={isLoading}
        >
          Filter
        </PButton>
      </Header>

      {isFilterOpen && (
        <ListFilterModal
          filterType={'cycleFailures'}
          onClose={closeModal}
          isOpen={isFilterOpen}
          lastRequestFilter={lastRequestFilter}
          formConfiguration={filterConfigurations}
          onFormSubmit={(args) => {
            setFilterQueryParamsInURL(args, history);
            dispatch(
              fetchCycleFailureList.request(
                decodeURIEncodedFilterJSONStringAndEnhanceWithIOFilter(
                  INITIAL_REQUEST_FILTER_CYCLE_FAILURES,
                  createFilterQueryParam(args),
                  showIORecordings
                )
              )
            );
          }}
        />
      )}
      <CycleFailureTable cycles={cycles} totalCycles={totalCycleFailures} isFromAllCyclesPage />
      <LoadingMask isLoading={isLoading} />
      {lastRequestFilter && (
        <Pagination
          totalItemsCount={totalCycleFailures ?? 0}
          itemsPerPage={INITIAL_REQUEST_FILTER_CYCLE_FAILURES.size}
          activePage={lastRequestFilter?.page ?? 1}
          onClick={loadCycleFailuresPage}
          className={c.paginationFix}
        />
      )}
    </>
  );
};
