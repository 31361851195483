import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { CognitoRole, HttpError } from '../../models';

export const logout = createAsyncAction(
  '@app/LOGOUT',
  '@app/LOGOUT_SUCCESS',
  '@app/LOGOUT_FAILURE',
  '@app/LOGOUT_CANCEL'
)<void, void, HttpError, void>();

export const setUserRole = createAction('@app/SET_USER_ROLE')<CognitoRole[]>();

export type AppAction = ActionType<typeof logout> | ActionType<typeof setUserRole>;
