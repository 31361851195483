import { Epic } from 'redux-observable';
import { concatMap, filter, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';
import { resetTestRunList } from '../test-runs';
import { logout } from './actions';

const logoutEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(isActionOf(logout.request)),
    switchMap(() =>
      authService.logout().pipe(
        catchErrorAndHandleWithAction(logout.failure),
        concatMap(() => [logout.success(), resetTestRunList()])
      )
    )
  );

export default [logoutEpic];
