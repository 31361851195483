import { createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { PButton } from '@porsche-design-system/components-react';
import { Modal } from '@porsche/ui-kit-react';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { CycleFailureFilter, LoadingMask, TestRunFilter } from '..';
import {
  INITIAL_REQUEST_FILTER_CYCLE_FAILURES,
  INITIAL_REQUEST_FILTER_TEST_RUNS,
  CREATED_START_STR,
  CREATED_END_STR,
  CREATED_STR,
  LOWER_THAN_OPERATOR,
  GREATER_THAN_OPERATOR,
  START_DATE_STR,
} from '../../constants';
import { FilterConfiguration } from '../../models';
import { CombinedModel } from '../../models/combinedModels';
import { FilterCondition, FilterRequestType } from '../../models/requestFilter.model';

export const getModalStyles = () =>
  createStyles({
    content: {
      zIndex: 20,
      maxWidth: 'max-content',
    },
    title: {
      margin: 0,
      fontSize: 28,
      paddingBottom: 24,
    },
    form: {
      minWidth: 669,
    },
    cancelButton: {
      marginRight: 32,
    },
    input: {
      flexBasis: 327,
      marginRight: 65,
      '&>div': {
        height: 50,
      },
    },
    dateTimeInput: {
      flexBasis: 200,
    },
    firstDateTimeInput: {
      marginRight: 62,
    },
    inputContainerRow: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: 12,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    fixedWidthInput: {
      width: 198,
      flexBasis: 'unset',
    },
    actions: {
      marginTop: 50,
    },
    select: {
      '&>.pui-Select': {
        '&>.pui-Select-menu-outer': {
          position: 'absolute',
          maxHeight: 'unset',
          '&>.pui-Select-menu': {
            maxHeight: 'unset',
            '&>.pui-Select-option': {},
          },
        },
      },
    },

    porscheStyleFix: {
      '&>.pui-Select': {
        '&>.pui-Select-control': {
          '&>.pui-Select-value': {
            marginLeft: '0.75rem',
          },
          '&>.pui-Select-label': {
            marginLeft: '0.75rem',
          },
          '&>.pui-Select-input': {
            marginLeft: '0.75rem',
          },
          '&>.pui-Select-placeholder': {
            marginLeft: '0.75rem',
          },
        },
      },
    },
  });

const useStyles = makeStyles(createStyles({ ...getModalStyles() }), { name: 'ListFilterModal' });

type Props = {
  isOpen: boolean;
  onClose: () => void;
  formConfiguration: FilterConfiguration;
  onFormSubmit: (filter: FilterRequestType) => void;
  lastRequestFilter?: FilterRequestType;
  filterType: 'cycleFailures' | 'testRuns';
};
if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}
export const ListFilterModal: React.FC<Props> = ({
  formConfiguration,
  onFormSubmit,
  lastRequestFilter,
  isOpen,
  onClose,
  filterType,
}) => {
  const c = useStyles({});

  const [initialValues, setInitialValues] = useState<CombinedModel>({});

  const mapToFilterRequest = (values: CombinedModel): FilterRequestType => {
    const filterConditions = [];

    for (const name in values) {
      if (name === CREATED_START_STR || name === CREATED_END_STR) {
        if (values[name]) {
          const obj: FilterCondition = {
            name: CREATED_STR,
            operator: name === CREATED_START_STR ? GREATER_THAN_OPERATOR : LOWER_THAN_OPERATOR,
            values: values[name],
          };
          filterConditions.push(obj);
        }
      } else {
        const filterOption = formConfiguration.elementConfigurations.find((option) => option.name === name);

        if (filterOption && values[name]) {
          const obj: FilterCondition = {
            name: name,
            operator: filterOption.operator,
            values: values[name],
          };
          filterConditions.push(obj);
        }
      }
    }
    let initialRequestFilter;
    switch (filterType) {
      case 'testRuns':
        initialRequestFilter = { ...INITIAL_REQUEST_FILTER_TEST_RUNS };
        break;
      case 'cycleFailures':
        initialRequestFilter = { ...INITIAL_REQUEST_FILTER_CYCLE_FAILURES };
        break;
    }

    return {
      ...initialRequestFilter,
      filterConditions: filterConditions,
    };
  };

  useEffect(() => {
    if (lastRequestFilter?.filterConditions?.length > 0) {
      const lastFilterValues: any = {};

      const filterConditions = lastRequestFilter.filterConditions;
      if (filterConditions) {
        for (const i in filterConditions) {
          const filterCondition = filterConditions[i];
          if (filterCondition.name === CREATED_STR) {
            lastFilterValues[filterCondition.operator === GREATER_THAN_OPERATOR ? CREATED_START_STR : CREATED_END_STR] =
              filterCondition.values;
          } else if (filterCondition.name === START_DATE_STR) {
            lastFilterValues[filterCondition.name] = [
              new Date(filterCondition.values[0]),
              new Date(filterCondition.values[1]),
            ];
          } else {
            lastFilterValues[filterCondition.name] = filterCondition.values;
          }
        }
      }
      setInitialValues(lastFilterValues);
    }
  }, [lastRequestFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className={c.content}
        containerClassName={c.content}
        contentLabel="Filter auswählen"
        size="dynamic"
      >
        <Modal.Title className={c.title}>Filter auswählen</Modal.Title>
        {!formConfiguration ? (
          <LoadingMask isLoading={!formConfiguration} />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values: CombinedModel, { setSubmitting }) => {
              const filter: FilterRequestType = mapToFilterRequest(values);
              onFormSubmit(filter);
              setSubmitting(false);
              onClose();
            }}
          >
            {({ submitForm, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
              <form onSubmit={handleSubmit} className={c.form} noValidate>
                {filterType === 'cycleFailures' && (
                  <CycleFailureFilter setFieldValue={setFieldValue} formConfiguration={formConfiguration} />
                )}
                {filterType === 'testRuns' && (
                  <TestRunFilter setFieldValue={setFieldValue} formConfiguration={formConfiguration} />
                )}
                <div className={c.actions}>
                  <PButton
                    variant="tertiary"
                    icon="reset"
                    className={c.cancelButton}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={() => {
                      //alles auf null bis auf startDate
                      resetForm({ values: {} });
                      submitForm();
                    }}
                  >
                    Zurücksetzen
                  </PButton>
                  <PButton type="submit" disabled={isSubmitting}>
                    Bestätigen
                  </PButton>
                </div>
              </form>
            )}
          </Formik>
        )}
      </Modal>
    </div>
  );
};
