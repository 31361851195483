export const sitemap = {
  home: { path: '/overview', name: 'Übersicht' },
  testRuns: {
    home: { path: '/testruns', name: 'Prüfstationen' },
    detail: { path: '/testruns/:id', name: 'Testlauf' },
  },
  recordings: {
    home: { path: '/recordings', name: 'Aufnahmen' },
    detail: { path: '/recordings/:recordingClientId/:id', name: 'Aufnahme' },
  },
  clustering: {
    path: '/clustering',
    name: 'Clustering'
  },
  training: {
    record: { path: '/training/record', name: 'Trainingsdaten erfassen' },
    label: { path: '/training/record/:id', name: 'Datenbeschriftung' },
    evaluate: { path: '/training/evaluate', name: 'Performance K.I.-Modell evaluieren' },
  },
  login: {
    path: '/login/callback',
    name: 'Login',
  },
};

export const createUrlWithParams = (url: string, params = {}) => {
  Object.keys(params).forEach((key) => (url = url.replace(new RegExp(`:${key}`), params[key])));
  return url;
};
