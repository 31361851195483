// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FailureHistory } from './FailureDTO';


export interface MetaInfo {
    /**
    * @type {string}
    * @memberof MetaInfoDTO
    */
    checkedFeature?: string;
    /**
     * @type {string}
     * @memberof MetaInfoDTO
     */
    iNumber?: string;
    /**
     * @type {string}
     * @memberof MetaInfoDTO
     */
    stationNumber?: string;
    /**
    * @type {string}
    * @memberof MetaInfoDTO
    */
    department?: string;
    /**
     * @type {string}
     * @memberof MetaInfoDTO
     */
    countPoint?: string;
}

/**
 * @export
 * @interface TestRunDTO
 */
export interface TestRunDTO {
    /**
     * @type {number}
     * @memberof TestRunDTO
     */
    actualCycleCount: number;
    /**
     * @type {string}
     * @memberof TestRunDTO
     */
    aiModelId: string;
    /**
     * @type {number}
     * @memberof TestRunDTO
     */
    anomalyCount: number;
    /**
     * @type {string}
     * @memberof TestRunDTO
     */
    carModel: string;
    /**
     * @type {Date}
     * @memberof TestRunDTO
     */
    changeDate: Date;
    /**
     * @type {string}
     * @memberof TestRunDTO
     */
    componentName: string;
    /**
     * @type {string}
     * @memberof TestRunDTO
     */
    description?: string;
    /**
     * @type {Date}
     * @memberof TestRunDTO
     */
    endDate: Date;
    /**
     * @type {Array<CycleInfoDTO>}
     * @memberof TestRunDTO
     */
    failureHistory?: FailureHistory;
    /**
     * @type {string}
     * @memberof TestRunDTO
     */
    humanReadableId: string;
    /**
     * @type {string}
     * @memberof TestRunDTO
     */
    id: string;
    /**
     * @type {boolean}
     * @memberof TestRunDTO
     */
    legalHold?: boolean;
    /**
     * @type {number}
     * @memberof TestRunDTO
     */
    plannedCycleCount: number;
    /**
     * @type {string}
     * @memberof TestRunDTO
     */
    recordingClientId?: string;
    /**
     * @type {Date}
     * @memberof TestRunDTO
     */
    startDate: Date;
    /**
     * @type {string}
     * @memberof TestRunDTO
     */
    state: TestRunDTOStateEnum;
    /**
     * @type {MetaInfo}
     * @memberof TestRunDTO
     */
    metaInfo?: MetaInfo;
}

/**
 * @export
 * @enum {string}
 */
export enum TestRunDTOStateEnum {
    CREATED = 'CREATED',
    RUNNING = 'RUNNING',
    STANDBY = 'STANDBY',
    PAUSED = 'PAUSED',
    FINISHED = 'FINISHED',
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE'
}