export enum UserRole {
  USER = 'User',
  ADMIN = 'Admin',
}
export enum CognitoRole {
  ROLE_ADMIN = 'role_admin',
  ROLE_USER = 'role_user',
}
export type User = {
  userRole: UserRole;
  canViewTraining: boolean;
  canDeleteTestRuns: boolean;
  canAddRecordingClients: boolean;
};
