import * as React from 'react';

import {
  CYCLE_FAILURE_LIST_IN_TESTRUN_DETAIL_TABLE_HEADERS,
  CYCLE_FAILURE_LIST_TABLE_HEADERS,
  INITIAL_REQUEST_FILTER_CYCLE_FAILURES,
  KEYDOWN_EVENT,
  SHOW_IO,
} from '../constants';
import { CustomSnackbar, FailureDiagram, Header, LoadingMask, TestRunTable } from '../components';
import { FilterRequestType, FilteredTestRunCyclesRequestType } from '../models/requestFilter.model';
import { Loader, Pagination, TextArea } from '@porsche/ui-kit-react';
import { RouteComponentProps, match } from 'react-router';
import { RowType, Table, TableBody, TableHeadRow } from '../components/Table';
import { SnackbarStatus, TestRun, TestRunDataHistory } from '../models';
import {
  createFilterQueryParam,
  decodeURIEncodedFilterJSONStringAndEnhanceWithIOFilter,
  getLocalStorageValue,
  hasFilter,
  onArrowKeyPagination,
  setFilterQueryParamsInURL,
  setLocalStorageValue,
} from '../utils';
import { createStyles, makeStyles } from '@material-ui/styles';
import { createUrlWithParams, sitemap } from '../routes';
import {
  cycleFailuresForTestRun,
  fetchFilteredTestRunCycles,
  fetchTestRunDetails,
  getCurrentTestRun,
  getIsLoadingDetails,
  getIsLoadingTestRunCycles,
  getLastRequestFilterForTestRun,
  getTestRunDetailUpdateState,
  getTotalTestRunCycles,
  resetSnackbar,
  testingStationColumnNames,
  updateTestRunDetails,
  updateTestRunInTestRunList,
} from '../store/test-runs';
import { getFilterButtonStyles, getPaginationStyles } from './TestRunList';
import { getFormStyles, initialSnackbarStatus } from './CycleFailureDetail';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { CycleFailureDetailModal } from '../components/CycleFailureDetailModal';
import { FormControlLabel } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { ListFilterModal } from '../components/Form/ListFilterModal';
import { PButton } from '@porsche-design-system/components-react';
import { SliderToggle } from '../components/SliderToggle';
import { getRecordingTableStyles } from '../components/CycleFailureTable';
import { getTestRunFailureListFilterConfigurations } from '../store/cycle-failures';
import moment from 'moment';
import { findValueInColumns } from '../utils/helpers'


const useStyles = makeStyles(
  createStyles({
    ...getFormStyles(),
    ...getPaginationStyles(),
    ...getRecordingTableStyles(),
    ...getFilterButtonStyles(),
    textArea: {
      minWidth: 696,
      margin: '0 64px 34px 0',

      display: 'inline-block',
    },
    failureDiagram: {
      margin: 'auto',
    },
  }),
  { name: 'TestRunDetail' }
);

type CombinedProps = RouteComponentProps & {
  match: match<{ id: string; recordingClientId: string }>;
};

export const TestRunDetail: React.FC<CombinedProps> = ({ match, location, history }) => {
  const c = useStyles({});
  const dispatch = useDispatch();

  const testRun = useSelector(getCurrentTestRun);
  const cycles = useSelector(cycleFailuresForTestRun);
  const totalCycles = useSelector(getTotalTestRunCycles);
  const filterConfigurations = useSelector(getTestRunFailureListFilterConfigurations);
  const isLoading = useSelector(getIsLoadingDetails);
  const isLoadingTestRunCycles = useSelector(getIsLoadingTestRunCycles);
  const lastRequestFilter = useSelector(getLastRequestFilterForTestRun);
  const testRunDetailUpdateState = useSelector(getTestRunDetailUpdateState);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [showIORecordings, setShowIORecordings] = useState(getLocalStorageValue(SHOW_IO) === 'true');
  const [testRunDescription, setTestRunDescription] = useState<string>('');

  const openFilterModal = () => {
    removeOnKeyDown();
    setIsFilterModalOpen(true);
  };
  const closeFilterModal = () => {
    addOnKeyDown();
    setIsFilterModalOpen(false);
  };

  const [detailModalId, setDetailModalId] = useState<string>(undefined);
  const openDetailModal = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, id: string) => {
    e?.stopPropagation();
    e?.preventDefault();
    setDetailModalId(id);
  };
  const closeDetailModal = () => setDetailModalId(undefined);

  const [snackbarState, setSnackbarState] = useState<SnackbarStatus>(initialSnackbarStatus);
  const closeSnackbar = () => setSnackbarState((prevState) => ({ ...prevState, isOpen: false }));

  const onKeyDown = (e) => onArrowKeyPagination(e, lastRequestFilter, totalCycles, loadCycleFailuresForTestRunPage);
  const addOnKeyDown = () => document.addEventListener(KEYDOWN_EVENT, onKeyDown, false);
  const removeOnKeyDown = () => document.removeEventListener(KEYDOWN_EVENT, onKeyDown, false);

  useEffect(() => {
    if (!isFilterModalOpen) {
      addOnKeyDown();
    }
    return () => removeOnKeyDown();
  });
  useEffect(() => () => {
    dispatch(resetSnackbar())
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (testRun?.status === 'abgeschlossen' && cycles?.length > 0) {
      //stop fetching if cycles were loaded and testRunStatus is complete
      dispatch(fetchTestRunDetails.cancel());
      dispatch(fetchFilteredTestRunCycles.cancel());
    }
  }, [testRun?.status, cycles]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSnackbarState({
      isOpen: testRunDetailUpdateState.isSuccess || !!testRunDetailUpdateState.error,
      error: testRunDetailUpdateState.error,
    });
  }, [testRunDetailUpdateState.isSuccess, testRunDetailUpdateState.error]);

  const onUpdateDescription = () => {
    if (!testRunDetailUpdateState.isPending) {
      const updatedTestRun: TestRun = { ...testRun };
      updatedTestRun.description = testRunDescription ? testRunDescription : ' ';
      dispatch(updateTestRunDetails.request(updatedTestRun));
    }
    fetchTestRunDetailsRequest();
    fetchFilteredTestRunCyclesRequest();
  };

  useEffect(() => {
    if (testRun || testRunDetailUpdateState.error) {
      setTestRunDescription(testRun.description);
    }
  }, [testRun, testRunDetailUpdateState]);
  useEffect(() => {
    if (testRunDetailUpdateState.isSuccess) {
      dispatch(updateTestRunInTestRunList(testRun));
    }
  }, [testRunDetailUpdateState.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * condition that has to be included in every request made from this component
   */
  useEffect(() => {
    fetchTestRunDetailsRequest();
    return () => {
      dispatch(fetchTestRunDetails.cancel());
      dispatch(fetchFilteredTestRunCycles.cancel());
    };
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchFilteredTestRunCyclesRequest();

    return () => { dispatch(fetchFilteredTestRunCycles.cancel()) };
  }, [showIORecordings]); // eslint-disable-line react-hooks/exhaustive-deps

  const [clusteredAnomalies, setClusteredAnomalies] = useState<TestRunDataHistory>();
  const [numberOfCalculatedBins, setNumberOfCalculatedBins] = useState(0);

  useEffect(() => {
    if (testRun?.id === match.params.id) {
      //calculate amount of required bins
      //const tempNumberOfCalculatedBins = Math.ceil(Math.sqrt(testRun?.testRunDataHistory?.length));
      const tempNumberOfCalculatedBins = testRun?.testRunDataHistory?.intervals?.length;
      setNumberOfCalculatedBins(tempNumberOfCalculatedBins);

      setClusteredAnomalies(testRun?.testRunDataHistory ?? { start: null, end: null, intervals: [] });
    } else {
      //reset diagram
      setClusteredAnomalies(null);
      setNumberOfCalculatedBins(null);
    }
  }, [testRun]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchTestRunDetailsRequest = () => {
    const filter = decodeURIEncodedFilterJSONStringAndEnhanceWithIOFilter(INITIAL_REQUEST_FILTER_CYCLE_FAILURES, location.search);
    const filterConditions = filter.filterConditions;

    let fromDate = undefined;
    let toDate = undefined;

    for (let i = 0; i < filterConditions.length; i++) {
      const f = filterConditions[i];
      if (f.name === "created") {
        if (f.operator === "gt") {
          fromDate = f.values;
        }
        if (f.operator === "lt") {
          toDate = f.values;
        }
      }
    }

    dispatch(
      fetchTestRunDetails.request({
        id: match.params.id,
        fromDate: fromDate,
        toDate: toDate,
      })
    );
  };

  const fetchFilteredTestRunCyclesRequest = () => {
    const requestBody: FilteredTestRunCyclesRequestType = {
      filter: decodeURIEncodedFilterJSONStringAndEnhanceWithIOFilter(
        INITIAL_REQUEST_FILTER_CYCLE_FAILURES,
        location.search,
        showIORecordings
      ),
      id: match.params.id,
    };
    dispatch(fetchFilteredTestRunCycles.request(requestBody));
  };

  const createDetailViewPath = (data: RowType) =>
    createUrlWithParams(sitemap.recordings.detail.path, {
      id: data.entityId,
      recordingClientId: data.recordingClientId,
    });

  const loadCycleFailuresForTestRunPage = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | KeyboardEvent,
    page: number
  ) => {
    const newFilter = { ...lastRequestFilter };
    newFilter.page = page;
    const requestBody: FilteredTestRunCyclesRequestType = {
      filter: newFilter,
      id: match.params.id,
    };
    dispatch(fetchFilteredTestRunCycles.request(requestBody));
  };
  const handleIOToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalStorageValue(SHOW_IO, event.target.checked.toString());
    setShowIORecordings(event.target.checked);
  };

  const tenantColumnNames = useSelector(testingStationColumnNames);

  return (
    <>
      <Header
        navigationTitle={'Zurück zu Prüfstationen'}
        navigationPath={`${sitemap.testRuns.home.path}${location.search}`}
        title={`${testRun?.carModel ?? 'Test'} - ${testRun?.componentName ?? ''}`}
      ></Header>
      {cycles?.length > 0 && !isLoadingTestRunCycles && !!detailModalId && (
        <CycleFailureDetailModal
          isOpen={!!detailModalId}
          onClose={closeDetailModal}
          cycle={cycles.find((cycle) => cycle.id === detailModalId)}
        />
      )}
      {testRun && !isLoading && <TestRunTable testRuns={[testRun]} shouldHideActions={true} />}
      <CustomSnackbar
        isSnackbarOpen={snackbarState.isOpen}
        closeSnackbar={closeSnackbar}
        type={snackbarState.error ? 'error' : 'success'}
      >
        <>
          {snackbarState.error ? (
            <span id="message-id">{`Testlauf konnte nicht bearbeitet werden: ${snackbarState.error.message ?? 'Unbekanter Fehler.'
              }`}</span>
          ) : (
            <span id="message-id">{`Testlauf wurde erfolgreich bearbeitet.`}</span>
          )}
        </>
      </CustomSnackbar>
      {!clusteredAnomalies ? (
        <Loader className={c.failureDiagram} size="large" />
      ) : (
        <FailureDiagram
          id="failure-diagram"
          clusteredAnomalies={clusteredAnomalies}
          binCount={numberOfCalculatedBins}
        />
      )}
      {testRun && !isLoading && (
        <div className={c.contentRow}>
          <div onFocus={removeOnKeyDown} onBlur={addOnKeyDown}>
            <TextArea
              className={c.textArea}
              name={'description'}
              value={testRunDescription ?? ''}
              placeholder={`${testRun.description ? 'Beschreibung' : 'Beschreibung hinzufügen (optional)'}`}
              onChange={(val) => setTestRunDescription(val)}
              textareaProps={{
                onFocus: () => {
                  dispatch(fetchTestRunDetails.cancel());
                  dispatch(fetchFilteredTestRunCycles.cancel());
                },
              }}
            />
            <PButton
              icon="edit"
              onClick={onUpdateDescription}
              loading={testRunDetailUpdateState.isPending}
              disabled={testRunDetailUpdateState.isPending}
            >
              Speichern
            </PButton>
          </div>
        </div>
      )}
      <>
        <Header title={"Aufnahmen"} headerTitleClass={c.headerTitle} headerClass={c.header}>
          <FormControlLabel
            control={<SliderToggle checked={showIORecordings} onChange={handleIOToggleChange} />}
            label={`${findValueInColumns("ioColumn", tenantColumnNames) ?? 'iO'} Aufnahmen einblenden`}
          />
          <PButton
            icon="filter"
            onClick={openFilterModal}
            disabled={isLoadingTestRunCycles}
            variant={hasFilter(lastRequestFilter) ? 'primary' : 'secondary'}
            className={c.filterButton}
          >
            Filter
          </PButton>
        </Header>
        {isFilterModalOpen && (
          <ListFilterModal
            filterType={'cycleFailures'}
            onClose={closeFilterModal}
            isOpen={isFilterModalOpen}
            lastRequestFilter={lastRequestFilter}
            formConfiguration={filterConfigurations}
            onFormSubmit={(filter: FilterRequestType) => {
              setFilterQueryParamsInURL(filter, history);
              dispatch(
                fetchFilteredTestRunCycles.request({
                  filter: decodeURIEncodedFilterJSONStringAndEnhanceWithIOFilter(
                    INITIAL_REQUEST_FILTER_CYCLE_FAILURES,
                    createFilterQueryParam(filter),
                    showIORecordings
                  ),
                  id: match.params.id,
                })
              );
            }}
          />
        )}
        <Table>
          <TableHeadRow types={CYCLE_FAILURE_LIST_IN_TESTRUN_DETAIL_TABLE_HEADERS} />
          {(cycles?.length > 0 || !isLoadingTestRunCycles) && (
            <TableBody
              data={cycles?.map<RowType>((cycle) => ({
                recordingClientId: cycle.testRunId,
                entityId: cycle.id,
                cols: [
                  { text: cycle.currentCycle?.toString() ?? '-' },
                  { text: cycle.orderNum ?? '-' },
                  { text: cycle.created ? moment(cycle.created).format('DD.MM.YY') : '-' },
                  { text: cycle.created ? moment(cycle.created).format('HH:mm:ss') : '-' },
                  { text: cycle.modelFinding ? 'n.i.O.' : <span className={c.io}>i.O.</span> },
                  { text: <InfoIcon onClick={(e) => openDetailModal(e, cycle.id)} />, isDetail: true },
                ],
              }))}
              type={CYCLE_FAILURE_LIST_TABLE_HEADERS}
              routeTo={createDetailViewPath}
            />
          )}
        </Table>
      </>
      <LoadingMask isLoading={isLoading || isLoadingTestRunCycles} />
      {lastRequestFilter && (
        <Pagination
          totalItemsCount={totalCycles ?? 0}
          itemsPerPage={INITIAL_REQUEST_FILTER_CYCLE_FAILURES.size}
          activePage={lastRequestFilter?.page ?? 1}
          onClick={loadCycleFailuresForTestRunPage}
          className={c.paginationFix}
        />
      )}
    </>
  );
};
