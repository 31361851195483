import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PButton } from '@porsche-design-system/components-react';
import { Modal } from '@porsche/ui-kit-react';
import cx from 'clsx';
import React, { useEffect } from 'react';
import { colors } from '../colors';
import { ActionStatus, RecordingLabelType } from '../models';
import { getModalStyles } from './Form/ListFilterModal';

const useStyles = makeStyles(
  createStyles({
    ...getModalStyles(),
    IO: {
      backgroundColor: colors.snackbarSuccess,
    },
    NIO: { backgroundColor: colors.snackbarError },
    NONE: {},
    label: {
      color: colors.white,
      padding: '0 4px',
    },
  }),
  { name: 'LabelConfirmationModal' }
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  trainingDetailUpdateStatus: ActionStatus;
  selectedCount: number;
  label: RecordingLabelType;
  onConfirm: () => void;
};

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

export const LabelConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  trainingDetailUpdateStatus,
  selectedCount,
  label,
  onConfirm,
}) => {
  const c = useStyles({});
  useEffect(() => {
    if (trainingDetailUpdateStatus?.isSuccess) {
      onClose();
    }
  }, [trainingDetailUpdateStatus.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={c.content}
      containerClassName={c.content}
      size="dynamic"
      contentLabel="Auswahl bestätigen"
    >
      <Modal.Title className={c.title}>{'Auswahl bestätigen'}</Modal.Title>
      <Modal.Text>
        {`Sie haben ${selectedCount} Aufnahmen ausgewählt. `}
        {label === 'NONE' ? (
          `Möchten Sie die Beschriftungen der ${selectedCount} Aufnahmen zurücksetzen?`
        ) : (
          <>
            {`Möchten Sie diese ${selectedCount} Aufnahmen als `}
            <span className={cx(c.label, c[label])}>{label === 'IO' ? 'i.O.' : label === 'NIO' ? 'n.i.O.' : null}</span>
            {' beschriften?'}
          </>
        )}
      </Modal.Text>
      <div className={c.actions}>
        <PButton variant="tertiary" icon="close" onClick={onClose} className={c.cancelButton}>
          Abbrechen
        </PButton>
        <PButton
          disabled={trainingDetailUpdateStatus.isPending}
          loading={trainingDetailUpdateStatus.isPending}
          onClick={() => {
            if (!trainingDetailUpdateStatus.isPending) {
              onConfirm();
            }
          }}
        >
          {label === 'NONE' ? 'Beschriftungen zurücksetzen' : 'Aufnahmen beschriften'}
        </PButton>
      </div>
    </Modal>
  );
};
