import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Doughnut, StatusChip } from '.';
import { colors } from '../colors';
import { TestRunInfo } from '../models';
import { sitemap, createUrlWithParams } from '../routes';
import { testingStationColumnNames } from '../store/test-runs/selectors';
import { getBorderedCardStyles } from './AllTestRunsOverview';
import { findValueInColumns, generateDayStartEnd } from '../utils/helpers'


const useStyles = makeStyles(
  createStyles({
    ...getBorderedCardStyles(),
    borderedCard: {
      ...getBorderedCardStyles().borderedCard,
      width: 315,
      display: 'inline-block',
      marginRight: 24.5,
    },
    cardHeading: {
      ...getBorderedCardStyles().cardHeading,
      marginBottom: 0,
      minHeight: "3em",
    },
    text: {
      fontSize: 16,
    },
    dot: {
      width: 7,
      height: 7,
      backgroundColor: colors.porscheRed,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: 5,
    },
    dotBlack: {
      backgroundColor: colors.greyDarkest,
    },
    chipWrapper: {
      marginTop: 18,
    },
    legend: {
      marginTop: 34,
      verticalAlign: 'top',
      display: 'inline-block',
    },
    legendText: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    legendWhiteSpace: {
      paddingLeft: "0.2em",
    },
    doughnutHeader: {
      ...getBorderedCardStyles().doughnutHeader,
      marginTop: 10,
      fontWeight: 'bold',
      textTransform: null,
    },
    doughnutWrapper: {
      ...getBorderedCardStyles().doughnutWrapper,
      marginBottom: 12,
    },
    loadingBarWrapper: {
      backgroundColor: colors.greyLightest,
    },
    loadingBar: {
      height: 13,
      backgroundColor: colors.greyDarkest,
    },
    carImage: {},
  }),
  { name: 'TestRunAnomalyDetailCard' }
);
export const TestRunAnomalyDetailCard: React.FC<TestRunInfo> = ({
  actualCycleCount,
  name,
  componentName,
  testRunId,
  humanReadableId,
  ioPerCent,
  nioPerCent,
  state,
}) => {

  const tenantColumnNames = useSelector(testingStationColumnNames);


  const createDetailViewPath = (id: string) => {
    const urlWithoutFilter = createUrlWithParams(sitemap.testRuns.detail.path, {
      id: id,
    });
    const [startDate, endDate] = generateDayStartEnd();
    const filter = `?filter=[{"name":"created","operator":"gt","values":"${startDate}"},{"name":"created","operator":"lt","values":"${endDate}"}]`;

    return urlWithoutFilter + filter
  }

  const c = useStyles({});
  return (
    <Link
      className={c.clickableCard}
      to={createDetailViewPath(testRunId)}
    >
      <div className={c.borderedCard}>
        <div className={c.cardContent}>
          <div>
            <h4 className={c.cardHeading}>{`${name} ${componentName}`}</h4>
            <div className={c.text}>{humanReadableId}</div>
          </div>
          <div>
            <div className={c.chipWrapper}>
              <StatusChip text={state} />
            </div>

            <div className={c.legend}>
              <div>
                <span className={c.dot} />
                <span className={c.legendText}>{`${nioPerCent}%`}</span>
                <span className={cx(c.legendText, c.legendWhiteSpace)}> {findValueInColumns("nioColumn", tenantColumnNames) ?? 'niO'}</span>
              </div>
              <div>
                <span className={cx(c.dot, c.dotBlack)} />
                <span className={c.legendText}>{`${ioPerCent}%`}</span>
                <span className={cx(c.legendText, c.legendWhiteSpace)}>{findValueInColumns("ioColumn", tenantColumnNames) ?? 'iO'}</span>
              </div>
            </div>
            <Doughnut ioPerCent={ioPerCent} nioPerCent={nioPerCent} testrunName={name} componentName={componentName} />
          </div>

          <div className={c.doughnutWrapper}>
            <h5 className={c.doughnutHeader}>Heute bereits geprüft:</h5>
            <div className={c.text}>{`${actualCycleCount ?? '-'}`}</div>
          </div>
          <div className={c.loadingBarWrapper}>
            <div
              className={c.loadingBar}
              style={{
                width: "100%"
              }}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};
