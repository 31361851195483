import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { AppAction, appReducer } from './app';
import { ConfigAction, configReducer } from './config';
import { CycleFailureAction, cycleFailureDetailReducer, cycleFailureListReducer } from './cycle-failures';
import { TestRunAction, testRunDetailReducer, testRunListReducer } from './test-runs';
import { TrainingAction, trainingsReducer, trainingDetailReducer } from './trainings';

export type RootAction = ConfigAction | CycleFailureAction | TestRunAction | AppAction | TrainingAction;
const rootReducer = (history: History) => {
  return combineReducers({
    appReducer,
    configReducer,
    cycleFailureListReducer,
    cycleFailureDetailReducer,
    testRunListReducer,
    testRunDetailReducer,
    trainingsReducer,
    trainingDetailReducer,
  });
};
export type RootState = StateType<ReturnType<typeof rootReducer>>;
export default rootReducer;
