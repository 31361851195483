import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PButton } from '@porsche-design-system/components-react';
import { Modal, Select, TextArea } from '@porsche/ui-kit-react';
import cx from 'clsx';
import { Field, FieldProps, Formik } from 'formik';
import React, { useState } from 'react';
import { NewTraining, PrefillMapping } from '../../models';
import { isEmptyObject } from '../../utils';
import { LoadingMask } from '../LoadingMask';
import { getModalStyles } from './ListFilterModal';

const carModelStr = 'carModel';
const componentNameStr = 'componentName';
const descriptionStr = 'description';

const useStyles = makeStyles(
  createStyles({
    ...getModalStyles(),
    ...{
      form: {
        minWidth: 790,
      },
      textArea: {
        flexBasis: 720,
        marginTop: 50,
      },
    },
  }),
  { name: 'NewTrainingRecordingFormModal' }
);

type NewTrainingRecordingFormModal = {
  isOpen: boolean;
  onClose: () => void;
  formPrefilling: PrefillMapping;
  onFormSubmit: (filter: NewTraining) => void;
};

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

export const NewTrainingRecordingFormModal: React.FC<NewTrainingRecordingFormModal> = ({
  isOpen,
  onClose,
  formPrefilling,
  onFormSubmit,
}) => {
  const c = useStyles({});

  const initialState: NewTraining = {
    carModel: formPrefilling.fieldValues.find((f) => f.fieldName === carModelStr)?.values[0],
    componentName: formPrefilling.fieldValues.find((f) => f.fieldName === componentNameStr)?.values[0],
    description: undefined,
  };

  const [initialValues, setInitialValues] = useState<NewTraining>(initialState);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      containerClassName={c.content}
      contentLabel="Neue Trainingsdaten erfassen"
      size="dynamic"
    >
      <Modal.Title className={c.title}>Neue Trainingsdaten erfassen</Modal.Title>
      {!formPrefilling ? (
        <LoadingMask isLoading={!formPrefilling} />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values: NewTraining, { setSubmitting }) => {
            onFormSubmit(values);
            setInitialValues(values);
            setSubmitting(false);
            onClose();
          }}
        >
          {({ handleSubmit, setFieldValue, values, errors }) => (
            <form onSubmit={handleSubmit} className={c.form} noValidate>
              <div>
                <div>
                  <div className={c.inputContainerRow}>
                    <Field name={carModelStr}>
                      {({ field }: FieldProps) => (
                        <Select
                          clearable={false}
                          className={cx(c.input, c.select, c.porscheStyleFix)}
                          placeholder={'Modell *'}
                          value={field.value}
                          options={formPrefilling.fieldValues
                            .find((prefilledField) => prefilledField.fieldName === carModelStr)
                            ?.values.map((option) => ({ value: option, label: option }))}
                          onChange={(val) => setFieldValue(field.name, val)}
                        />
                      )}
                    </Field>

                    <Field name={componentNameStr}>
                      {({ field }: FieldProps) => (
                        <Select
                          clearable={false}
                          className={cx(c.input, c.select, c.porscheStyleFix)}
                          placeholder={'Komponente *'}
                          value={field.value}
                          options={formPrefilling.fieldValues
                            .find((prefilledField) => prefilledField.fieldName === componentNameStr)
                            .values.map((option) => ({ value: option, label: option }))}
                          onChange={(val) => setFieldValue(field.name, val)}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <div className={c.inputContainerRow}>
                <Field name={descriptionStr}>
                  {({ field, form: { errors, touched } }: FieldProps) => (
                    <>
                      <TextArea
                        className={c.textArea}
                        name={field.name}
                        value={field.value ?? ''}
                        placeholder={`${field.value ? 'Beschreibung' : 'Beschreibung hinzufügen (optional)'}`}
                        onChange={(val) => setFieldValue(field.name, val)}
                      />
                      {errors?.description && touched?.description && <div>{errors.description}</div>}
                    </>
                  )}
                </Field>
              </div>
              <div className={c.actions}>
                <PButton variant="tertiary" icon="close" onClick={onClose} className={c.cancelButton}>
                  Abbrechen
                </PButton>
                <PButton type="submit" disabled={!isEmptyObject(errors)}>
                  Anlegen
                </PButton>
              </div>
            </form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
