// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    CycleDTO,
    CycleListDTO,
    CycleUpdateDTO,
    FilterDTO,
} from '../models';

export interface GetAllFailuresByTestRunIdUsingPOSTRequest {
    testRunId: string;
    filterDTO: FilterDTO;
}

export interface GetAllFailuresUsingPOSTRequest {
    filterDTO: FilterDTO;
}

export interface GetFailureByIDUsingGETRequest {
    cycleId: string;
    testRunId: string;
    provideIo?: boolean;
}

export interface UpdateFailureUsingPUTRequest {
    cycleId: string;
    testRunId: string;
    cycleData: CycleUpdateDTO;
    provideIo?: boolean;
}

/**
 * no description
 */
export class AnomalyCycleControllerApi extends BaseAPI {

    /**
     * getAllFailuresByTestRunId
     */
    getAllFailuresByTestRunIdUsingPOST({ testRunId, filterDTO }: GetAllFailuresByTestRunIdUsingPOSTRequest): Observable<CycleListDTO>
    getAllFailuresByTestRunIdUsingPOST({ testRunId, filterDTO }: GetAllFailuresByTestRunIdUsingPOSTRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<CycleListDTO>
    getAllFailuresByTestRunIdUsingPOST({ testRunId, filterDTO }: GetAllFailuresByTestRunIdUsingPOSTRequest, opts?: OperationOpts): Observable<RawAjaxResponse<CycleListDTO>>
    getAllFailuresByTestRunIdUsingPOST({ testRunId, filterDTO }: GetAllFailuresByTestRunIdUsingPOSTRequest, opts?: OperationOpts): Observable<CycleListDTO | RawAjaxResponse<CycleListDTO>> {
        throwIfNullOrUndefined(testRunId, 'getAllFailuresByTestRunIdUsingPOST');
        throwIfNullOrUndefined(filterDTO, 'getAllFailuresByTestRunIdUsingPOST');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<CycleListDTO>({
            url: '/failures/{testRunId}'.replace('{testRunId}', encodeURI(testRunId)),
            method: 'POST',
            headers,
            body: filterDTO,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * getAllFailures
     */
    getAllFailuresUsingPOST({ filterDTO }: GetAllFailuresUsingPOSTRequest): Observable<CycleListDTO>
    getAllFailuresUsingPOST({ filterDTO }: GetAllFailuresUsingPOSTRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<CycleListDTO>
    getAllFailuresUsingPOST({ filterDTO }: GetAllFailuresUsingPOSTRequest, opts?: OperationOpts): Observable<RawAjaxResponse<CycleListDTO>>
    getAllFailuresUsingPOST({ filterDTO }: GetAllFailuresUsingPOSTRequest, opts?: OperationOpts): Observable<CycleListDTO | RawAjaxResponse<CycleListDTO>> {
        throwIfNullOrUndefined(filterDTO, 'getAllFailuresUsingPOST');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<CycleListDTO>({
            url: '/failures',
            method: 'POST',
            headers,
            body: filterDTO,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * getFailureByID
     */
    getFailureByIDUsingGET({ cycleId, testRunId, provideIo }: GetFailureByIDUsingGETRequest): Observable<CycleDTO>
    getFailureByIDUsingGET({ cycleId, testRunId, provideIo }: GetFailureByIDUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<CycleDTO>
    getFailureByIDUsingGET({ cycleId, testRunId, provideIo }: GetFailureByIDUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<CycleDTO>>
    getFailureByIDUsingGET({ cycleId, testRunId, provideIo }: GetFailureByIDUsingGETRequest, opts?: OperationOpts): Observable<CycleDTO | RawAjaxResponse<CycleDTO>> {
        throwIfNullOrUndefined(cycleId, 'getFailureByIDUsingGET');
        throwIfNullOrUndefined(testRunId, 'getFailureByIDUsingGET');

        const query: HttpQuery = {};

        if (provideIo != null) { query['provideIo'] = provideIo; }

        return this.request<CycleDTO>({
            url: '/failures/{testRunId}/{cycleId}'.replace('{cycleId}', encodeURI(cycleId)).replace('{testRunId}', encodeURI(testRunId)),
            method: 'GET',
            query,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * updateFailure
     */
    updateFailureUsingPUT({ cycleId, testRunId, cycleData, provideIo }: UpdateFailureUsingPUTRequest): Observable<CycleDTO>
    updateFailureUsingPUT({ cycleId, testRunId, cycleData, provideIo }: UpdateFailureUsingPUTRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<CycleDTO>
    updateFailureUsingPUT({ cycleId, testRunId, cycleData, provideIo }: UpdateFailureUsingPUTRequest, opts?: OperationOpts): Observable<RawAjaxResponse<CycleDTO>>
    updateFailureUsingPUT({ cycleId, testRunId, cycleData, provideIo }: UpdateFailureUsingPUTRequest, opts?: OperationOpts): Observable<CycleDTO | RawAjaxResponse<CycleDTO>> {
        throwIfNullOrUndefined(cycleId, 'updateFailureUsingPUT');
        throwIfNullOrUndefined(testRunId, 'updateFailureUsingPUT');
        throwIfNullOrUndefined(cycleData, 'updateFailureUsingPUT');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = {};

        if (provideIo != null) { query['provideIo'] = provideIo; }

        return this.request<CycleDTO>({
            url: '/failures/{testRunId}/{cycleId}'.replace('{cycleId}', encodeURI(cycleId)).replace('{testRunId}', encodeURI(testRunId)),
            method: 'PUT',
            headers,
            query,
            body: cycleData,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

}
