import moment from 'moment';
import { Observable, of } from 'rxjs';
import { COGNITO_GROUPS_KEY } from '../constants';
import { CognitoRole } from '../models';

export class AuthService {
  logout(): Observable<void> {
    return of();
  }

  private static decodeToken(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  }

  static isTokenValid(token: string) {
    if (!token) {
      return false;
    }
    const decodedToken = AuthService.decodeToken(token);
    const expDate = new Date(decodedToken.exp * 1000); // seconds to milliseconds
    return moment.duration(moment(expDate).diff(moment(moment.now()))).asSeconds() > 0;
  }
  static getRoles(token: string): CognitoRole[] {
    if (!token) {
      return undefined;
    }
    const decodedToken = AuthService.decodeToken(token);
    return decodedToken[COGNITO_GROUPS_KEY];
  }
}
