import { createSelector } from 'reselect';
import { NEW_TRAINING_RUN_FORM, TEST_RUN_CONTROL } from '../../constants';
import { RootState } from '../rootReducer';

const initialConfigState = (state: RootState) => state.configReducer;

const trainingState = (state: RootState) => state.trainingsReducer;

export const getTrainings = createSelector(trainingState, (state) => state.trainings);
export const getTotalTrainings = createSelector(trainingState, (state) => state.totalTrainings);

export const newTrainingConfigurations = createSelector(initialConfigState, (state) =>
  state.config.formPrefillings?.find((op) => op.formName === NEW_TRAINING_RUN_FORM)
);

export const getTrainingControlPrefilling = createSelector(initialConfigState, (state) =>
  state.config.formPrefillings?.find((op) => op.formName === TEST_RUN_CONTROL)
);

export const getIsLoading = createSelector(trainingState, (state) => state.isLoading);

export const getCreateTrainingStatus = createSelector(trainingState, (state) => state.createTrainingStatus);
export const getStartTrainingStatus = createSelector(trainingState, (state) => state.startTrainingStatus);
export const getPauseTrainingStatus = createSelector(trainingState, (state) => state.pauseTrainingStatus);
export const getStopTrainingStatus = createSelector(trainingState, (state) => state.stopTrainingStatus);

export const getLastRequestFilter = createSelector(trainingState, (state) => state.requestFilter);

const TrainingDetailState = (state: RootState) => state.trainingDetailReducer;

// export const cycleFailuresForTraining = createSelector(TrainingDetailState, (state) => state.cycles);

// export const getLastRequestFilterForTraining = createSelector(TrainingDetailState, (state) => state.requestFilter);

export const getCurrentTraining = createSelector(TrainingDetailState, (state) => state.currentTraining);
export const getCurrentTrainingRecordingLimit = createSelector(TrainingDetailState, (state) => state.currentTrainingRecordingLimit);
export const getCurrentTrainingRecordingOffset = createSelector(TrainingDetailState, (state) => state.currentTrainingRecordingOffset);
export const getFetchTrainingDetailStatus = createSelector(
  TrainingDetailState,
  (state) => state.fetchTrainingDetailStatus
);
export const getTrainingDetailUpdateStatus = createSelector(
  TrainingDetailState,
  (state) => state.trainingDetailUpdateStatus
);

export const getTrainingDescriptionUpdateStatus = createSelector(trainingState, (state) => state.trainingDescriptionUpdateStatus);

// export const getIsLoadingDetails = createSelector(TrainingDetailState, (state) => state.isLoading);

// export const getIsLoadingTrainingCycles = createSelector(TrainingDetailState, (state) => state.isLoadingTrainingCycles);
// export const getTotalTrainingCycles = createSelector(TrainingDetailState, (state) => state.totalTrainingCycles);

// export const getTrainingDetailUpdateState = createSelector(
//   TrainingDetailState,
//   (state) => state.TrainingDetailUpdateStatus
// );
