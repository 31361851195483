import * as packageJson from '../../package.json';
import { Environment } from './environment.model';

export const environment: Environment = {
  version: packageJson.version,
  build: 'local',
  release: packageJson.version + '-local',
  environment: 'local',
  cognitoUrl:
    'https://aaa-dev.auth.eu-central-1.amazoncognito.com/login?client_id=fnj6vmonrbm3ntb4kli4b5b9v&response_type=token&scope=email+openid+profile&redirect_uri=http://localhost:3000/login/callback',
  features: {
    showMockImplementations: true,
  },
  apiEndpointUrl: 'http://localhost:8080',
};
