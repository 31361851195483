import { createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { PButton } from '@porsche-design-system/components-react';
import { Modal, TextArea } from '@porsche/ui-kit-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Training } from '../models';
import { fetchTrainingDetails, getTrainingDescriptionUpdateStatus, updateTrainingDetails } from '../store/trainings';
import { getModalStyles } from './Form/ListFilterModal';
import { CustomSnackbar } from '../components';
import { SnackbarStatus } from '../models';

import {
  resetSnackbar,
} from '../store/trainings';

export const initialSnackbarStatus: SnackbarStatus = {
  isOpen: false,
  error: undefined,
};

export const getDetailTableStyles = () =>
  createStyles({
    table: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 669,
    },
    row: {
      display: 'flex',
      marginBottom: 8,
    },
    cell: {
      flex: 2,
      marginRight: 20,
    },
    key: {
      fontWeight: 'bold',
      marginRight: '.5em',
    },

    descriptionArea: {
      marginTop: 8
    },
    textArea: {
      minWidth: 400,
      marginTop: 0,
      marginRight: 20,
      display: 'inline-block',
    },
  });

const useStyles = makeStyles(
  createStyles({
    ...getModalStyles(),
    ...getDetailTableStyles(),
  }),
  { name: 'TrainingTableDetailModal' }
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  training: Training;
};

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

export const TrainingTableDetailModal: React.FC<Props> = ({ isOpen, onClose, training }) => {
  const c = useStyles({});


  const createDetails = (training: Training) => [
    [
      {
        key: 'Prüfstation:',
        value: training.carModel ?? '-',
      },
      {
        key: 'Aufnahmen (Anz.):',
        value: training.actualCycleCount,
      },
    ],

    [
      {
        key: 'Komponente:',
        value: training.componentName,
      },
      {
        key: 'i.O. Beschriftung:',
        value: training.labeledNotAnomalyCount ?? 0,
      },


    ],

    [
      {
        key: 'Geprüftes Merkmal:',
        value: training.metaInfo?.checkedFeature ?? "Vibration"
      },
      {
        key: 'n.i.O. Beschriftung:',
        value: training.labeledAnomalyCount ?? 0,
      },

    ],
    [
     {
     key: 'Start-Datum:',
        value: moment(training.startDate).format('DD.MM.YYYY'),
     }
    ]
  ];

  const [details, setDetails] = useState(createDetails(training));
  const dispatch = useDispatch();
  const [trainingDescription, setTrainingDescription] = useState<string>('');

  const trainingDescriptionUpdateState = useSelector(getTrainingDescriptionUpdateStatus);

  const [snackbarState, setSnackbarState] = useState<SnackbarStatus>(initialSnackbarStatus);
  const closeSnackbar = () => setSnackbarState((prevState) => ({ ...prevState, isOpen: false }));

  useEffect(() => () => {
    dispatch(resetSnackbar())
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSnackbarState({
      isOpen: trainingDescriptionUpdateState.isSuccess || !!trainingDescriptionUpdateState.error,
      error: trainingDescriptionUpdateState.error,
    });
  }, [trainingDescriptionUpdateState.isSuccess, trainingDescriptionUpdateState.error]);


  const onUpdateDescription = () => {
    if (!trainingDescriptionUpdateState.isPending) {
      const updatedTraining: Training = { ...training };
      updatedTraining.description = trainingDescription ? trainingDescription : ' ';
      dispatch(updateTrainingDetails.request(updatedTraining));
    }
  };

  const onCloseButton = () => {
    closeSnackbar();
    onClose();
  }


  useEffect(() => {
    setDetails(createDetails(training));
    setTrainingDescription(training.description);
  }, [training]); // eslint-disable-line react-hooks/exhaustive-deps

  return (

    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={c.content}
      containerClassName={c.content}
      size="dynamic"
      contentLabel="Aufnahme Details"
    >
      <CustomSnackbar
        isSnackbarOpen={snackbarState.isOpen}
        closeSnackbar={closeSnackbar}
        type={snackbarState.error ? 'error' : 'success'}
      >
        <>
          {snackbarState.error ? (
            <span id="message-id">{`Training konnte nicht bearbeitet werden: ${snackbarState.error.message ?? 'Unbekanter Fehler.'
              }`}</span>
          ) : (
            <span id="message-id">{`Training wurde erfolgreich bearbeitet.`}</span>
          )}
        </>
      </CustomSnackbar>

      <Modal.Title className={c.title}>{`Trainingsset ID ${training.trainingNumber} - Detail`}</Modal.Title>
      <div className={c.table}>
        {details.map((detailRow, index) => (
          <div className={c.row} key={index}>
            {detailRow.map((cell) => (
              <div className={c.cell} key={cell.key}>
                <span className={c.key}>{cell.key}</span>
                <span>{cell.value ?? '-'}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div >
        <div className={c.descriptionArea}>
          <TextArea
            className={c.textArea}
            name={'description'}
            value={trainingDescription ?? ''}
            placeholder={`${training.description ? 'Beschreibung' : 'Beschreibung hinzufügen (optional)'}`}
            onChange={(val) => setTrainingDescription(val)}
            textareaProps={{
              onFocus: () => {
                dispatch(fetchTrainingDetails.cancel());
              },
            }}
          />
          <PButton
            icon="edit"
            onClick={onUpdateDescription}
          >
            Speichern
            </PButton>
        </div>
      </div>

      <div className={c.actions}>
        <PButton onClick={onCloseButton}>Ok</PButton>
      </div>
    </Modal>

  );
};
