import React, { useEffect, useState } from 'react';

import { Cycle } from '../models';
import { Modal } from '@porsche/ui-kit-react';
import { PButton } from '@porsche-design-system/components-react';
import { createStyles } from '@material-ui/core/styles';
import { getModalStyles } from './Form/ListFilterModal';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

export const getDetailTableStyles = () =>
  createStyles({
    table: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 669,
    },
    row: {
      display: 'flex',
      marginBottom: 8,
    },
    cell: {
      flex: 2,
      marginRight: 20,
    },
    key: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '18px',
    },
  });

const useStyles = makeStyles(
  createStyles({
    ...getModalStyles(),
    ...getDetailTableStyles(),
  }),
  { name: 'CycleFailureDetailModal' }
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  cycle: Cycle;
};

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

export const CycleFailureDetailModal: React.FC<Props> = ({ isOpen, onClose, cycle }) => {
  const c = useStyles({});

  const createDetails = (cycle: Cycle) => [
    [
      {
        key: 'Aufnahme ID: ',
        value: `${cycle.currentCycle}`,
      },
      {
        key: 'Prüfstation: ',
        value: cycle.carModel ?? '-',
      },
    ],
    [
      {
        key: 'Teile-Nr: ',
        value: cycle.orderNum ?? '-',
      },
      {
        key: 'Komponente: ',
        value: cycle.componentName,
      }
    ],
    [
      {
        key: 'Datum: ',
        value: moment(cycle.created).format('DD.MM.YYYY'),
      },
      {
        key: 'Temperatur (°C): ',
        value: cycle.temperature,
      },
    ],
    [
      {
        key: 'Uhrzeit: ',
        value: `${moment(cycle.created).format('HH:mm:ss')} Uhr`,
      },
      {
        key: 'Kundenrelevant: ',
        value: cycle.customerRelevant === true ? 'Ja' : cycle.customerRelevant === false ? 'Nein' : '-',
      },
    ],
    [
      {
        key: 'Bewertung: ',
        value: `${cycle.modelFinding ? 'n.i.O.' : 'i.O.'}`
      },
      {
        key: '',
        value: '',
      },
    ],
  ];

  const [details, setDetails] = useState(createDetails(cycle));

  useEffect(() => {
    setDetails(createDetails(cycle));
  }, [cycle]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={c.content}
      containerClassName={c.content}
      size="dynamic"
      contentLabel="Aufnahme Details"
    >
      <Modal.Title className={c.title}>{`Aufnahme ${cycle.currentCycle} - Detail`}</Modal.Title>
      <div className={c.table}>
        {details.map((detailRow, index) => (
          <div className={c.row} key={index}>
            {detailRow.map((cell) => (
              <div className={c.cell} key={cell.key}>
                <span className={c.key}>{cell.key}</span>
                <span>{cell.value ?? '-'}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={c.actions}>
        <PButton onClick={onClose}>Ok</PButton>
      </div>
    </Modal>
  );
};
