import { makeStyles } from '@material-ui/styles';
import cx from 'clsx';
import * as React from 'react';
import { colors } from '../colors';

type Props = {
  text: string;
  className?: string;
};
const useStyles = makeStyles(
  {
    chip: {
      backgroundColor: colors.neutralGrey4,
      color: colors.white,
      fontWeight: 'bold',
      borderRadius: 5,
      padding: '2px 8px',
      fontSize: 16,
    },
    angelegt: {
      color: colors.neutralGrey8,
      backgroundColor: colors.cardBorder,
    },
    aufnahme: {
      backgroundColor: colors.snackbarSuccess,
    },
    standby: {
      backgroundColor: colors.standby,
    },
    pausiert: {
      backgroundColor: colors.porscheDarkRed,
    },
    offline: {
      backgroundColor: colors.neutralGrey4,
    },
    abgeschlossen: {
      backgroundColor: colors.neutralGrey8,
    },
  },
  { name: 'StatusChips' }
);
export const StatusChip: React.FC<Props> = ({ text, className }) => {
  const c = useStyles({});
  return <span className={cx(c.chip, c[text as string], className)}>{text}</span>;
};
