import { makeStyles } from '@material-ui/core';
import { PIcon } from '@porsche-design-system/components-react';
import moment from 'moment';
import React from 'react';
import { colors } from '../colors';
import { RecordingFilterType } from './Form/RecordingFilterModal';

const useStyles = makeStyles(
  {
    infoWrapper: {
      width: 389,
      height: 44,
      lineHeight: '44px',
      padding: '0 16xp 0 16px',
      marginTop: -65,
      marginBottom: 21,
      backgroundColor: colors.neutralGrey8,
      color: colors.white,
    },
    resetFilter: {
      float: 'right',
      marginRight: 16,
      cursor: 'pointer',
      marginTop: 8,
    },
    firstDate: {
      marginLeft: 16,
    },
  },
  { name: 'RecordingFilterInformation' }
);
type Props = {
  clearFilter: () => void;
  filter?: RecordingFilterType;
};

export const RecordingFilterInformation: React.FC<Props> = ({ clearFilter, filter }) => {
  const c = useStyles({});

  return !!filter?.createdStart || !!filter?.createdEnd ? (
    <div className={c.infoWrapper}>
      {filter.createdStart ? (
        <b className={c.firstDate}>{moment(filter.createdStart).format('DD.MM.YYYY - HH:mm')}</b>
      ) : (
        <b className={c.firstDate}>alle</b>
      )}
      <span> bis </span>
      {filter.createdEnd ? <b>{moment(filter.createdEnd).format('DD.MM.YYYY - HH:mm')}</b> : <b>jetzt</b>}
      <PIcon
        onClick={clearFilter}
        theme={'dark'}
        className={c.resetFilter}
        name={'close'}
        title={'Filter zurücksetzen'}
      />
    </div>
  ) : null;
};
