export const colors = {
  // extracted colors from scss color mixins

  porscheRed: '#d5001c',
  porscheDarkRed: '#960014',
  porscheLight: '#fff',
  white: '#fff',
  porscheDark: '#0e1418',
  porscheBlack: '#000',
  black: '#000000',

  // surface colors
  surfaceLight: '#f2f2f2',
  surfaceDark: '#1a2023',

  // neutral grey colors
  neutralGrey1: '#e3e4e5',
  neutralGrey2: '#c9cacb',
  neutralGrey3: '#b0b1b2',
  neutralGrey4: '#96989a',
  neutralGrey5: '#7c7f81',
  neutralGrey6: '#626669',
  neutralGrey7: '#4a4e51',
  neutralGrey8: '#323639',

  // color grey mappings used on dark backgrounds
  greyLight: '#b0b1b2',
  greyLighter: '#c9cacb',
  greyLightest: '#e3e4e5',
  greyDisabledOnDark: '#7c7f81',

  // color grey mappings used on light backgrounds
  greyDark: '#626669',
  greyDarker: '#4a4e51',
  greyDarkest: '#323639',
  greyDisabledOnLight: '#96989a',

  // status colors
  statusRed: '#e00000',
  statusGreen: '#13d246',
  statusYellow: '#e2b236',

  // state colors
  stateFocus: '#00d5b9',

  // external brand colors
  externalFacebook: '#1877f2',
  externalGoogle: '#4285f4',
  externalInstagram: '#e1306c',
  externalLinkedIn: '#0077b5',
  externalTwitter: '#1da1f2',
  externalWeChat: '#1aad19',
  externalYouTube: '#ff0000',

  //Brunos colors:
  snackbarSuccess: '#2DC782',
  snackbarSuccessDarker: '#219863',
  snackbarError: '#C72D47',
  snackbarErrorDarker: '##982337',
  tableBorder: '#C8CACB',
  canceled: '#D55300',
  cardBorder: '#E7E7E7',
  temperature: '#e67e22',
  humidity: '#0091FF',
  standby: '#F1C40F',
  selectedRecordingInList: '#EFF0F1',

  //porsche ui kit v0 colors
  inputLabel: '#959899',
};
