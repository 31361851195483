import { ActionStatus, NamedActionStatus } from './models';
import { FilterCondition, FilterRequestType } from './models/requestFilter.model';

import { TableType } from './components/Table';

export const HEADER_HEIGHT = 116;
export const D3_TOOLTIP_ID = 'd3ToolTip';
export const AUTH_HEADER_KEY = 'Authorization';

export const COGNITO_GROUPS_KEY = 'cognito:groups';

export const CYCLE_FAILURE_LIST_IN_TESTRUN_DETAIL_TABLE_HEADERS: TableType[] = [
  { title: 'Aufnahme-ID', key: 'currentCycle' },
  { title: 'Teile-Nr.', key: 'orderNum' },
  { title: 'Datum', key: 'createdDay' },
  { title: 'Uhrzeit', key: 'createdTime' },
  { title: 'Bewertung', key: 'modelFinding' },
  { title: 'Detail', key: 'detail' },
];

export const CYCLE_FAILURE_LIST_TABLE_HEADERS: TableType[] = [
  { title: 'Aufnahme-ID', key: 'currentCycle' },
  { title: 'Teile-Nr.', key: 'orderNum' },
  { title: 'Datum', key: 'createdDay' },
  { title: 'Uhrzeit (HH:MM:SS)', key: 'createdTime' },
  { title: 'Prüfstation', key: 'carModel' },
  { title: 'Komponente', key: 'componentName' },
  { title: 'Bewertung', key: 'modelFinding' },
  { title: 'Detail', key: 'detail' },
];


export const TRAINING_LIST_TABLE_HEADERS: TableType[] = [
  { title: 'Trainingsset ID', key: 'trainingNumber' },
  { title: 'Prüfstation', key: 'carModel' },
  { title: 'Komponente', key: 'componentName' },
  { title: 'Geprüftes Merkmal', key: 'checkedMethod' },
  { title: 'Startdatum', key: 'startDate' },
  { title: 'Aufnahmen (Anz.)', key: 'actualCycleCount' },
  { title: 'i.O.', key: 'labeledNotAnomalyCount' },
  { title: 'n.i.O.', key: 'labeledAnomalyCount' },
];
export const ALL_FAILURES_MULTI_FILTER = 'allFailuresMultiFilter';
export const TEST_RUN_FAILURES_MULTI_FILTER = 'testRunFailuresMultiFilter';
export const TEST_RUN_MULTI_FILTER = 'testRunsMultiFilter';

export const NEW_TEST_RUN_FORM = 'newTestRunForm';
export const TEST_RUN_CONTROL = 'testRunControl';
export const NEW_TRAINING_RUN_FORM = 'newTrainingRunForm';

export const DEFAULT_PLANNED_CYCLE_COUNT = 3000;

export const BAR_PADDING = 0.25;

/**
 * first request filter to be sent when component is launched
 */
export const DISPLAY_N_I_O_FILTER: FilterCondition = {
  name: 'modelFinding',
  operator: 'eq',
  values: ['true'],
};

export const INITIAL_REQUEST_FILTER_CYCLE_FAILURES: FilterRequestType = {
  filterConditions: [DISPLAY_N_I_O_FILTER],
  size: 12,
  page: 1,
  sortedBy: 'created',
  sortDirection: 'desc',
};

export const INITIAL_REQUEST_FILTER_TEST_RUNS: FilterRequestType = {
  filterConditions: [],
  size: 12,
  page: 1,
  sortedBy: 'changeDate',
  sortDirection: 'desc',
};

export const CREATED_STR = 'created';
export const CREATED_START_STR = 'createdStart';
export const CREATED_END_STR = 'createdEnd';
export const LABEL_STR = 'label';
export const START_DATE_STR = 'startDate';
export const GREATER_THAN_OPERATOR = 'gt';
export const LOWER_THAN_OPERATOR = 'lt';

const THIRTY_SECONDS = 30 * 1000;
const TWENTY_SECONDS = 20 * 1000;

export const TEST_RUN_OVERVIEW_REFRESH_INTERVAL = THIRTY_SECONDS;
export const TEST_RUNS_REFRESH_INTERVAL = THIRTY_SECONDS;
export const TEST_RUN_DETAIL_REFRESH_INTERVAL = TWENTY_SECONDS;
export const TRAINING_DETAIL_REFRESH_INTERVAL = THIRTY_SECONDS;
export const SNACKBAR_AUTO_HIDE_DURATION = 4000;
export const SHOULD_LOOP_AUDIO_FILE = false;
export const APP_PREFIX = 'AAA.';
export const REDIRECT_URL = 'redirectUrl';
export const ID_TOKEN = 'id_token';
export const SHOW_IO = 'showIO';
export const FAILURE_DIAGRAM_WIDTH = 1011;
export const FAILURE_DIAGRAM_HEIGHT = 600;
export const DIAGRAM_LEGEND_Y = -20;
export const AXIS_MARGIN_INNER = 50;

export const START_RECORDING_STR = 'Datenerfassung starten';
export const PAUSE_RECORDING_STR = 'Datenerfassung pausieren';
export const END_RECORDING_STR = 'Datenerfassung beenden';

export const START_TEST_STR = 'Starten';
export const PAUSE_TEST_STR = 'Pausieren';
export const END_TEST_STR = 'Beenden';

export const PANAMERA_STR = 'panamera';
export const CAYENNE_STR = 'cayenne';
export const TAYCAN_STR = 'taycan';
export const CAYMAN_STR = 'cayman';
export const MACAN_STR = 'macan';
export const NINE_ELEVEN_STR = '911';
export const ROBOT_STR = "robot"
export const DOOR_COMPONENT_LIST = ['fenster', "tür", "tuer"];
export const BALL_BEARING_STR = "kugellager"
export const ANODE_STR = "ctr21"
export const ALL_CYCLES = 'ALL_CYCLES';

export const MAX_AMOUNT_OF_X_AXIS_TICKS = 21;

export const EMPTY_TEST_RUN_FILTER_STATE = {
  name: 'state',
  operator: 'in',
  values: [],
};

export const KEYDOWN_EVENT = 'keydown';

export const MOCK_RECORDING_CLIENT_ID = '3d786577-8de4-405c-95c4-8c5a5b22872f';

export const INITIAL_ACTION_STATUS: ActionStatus = {
  id: undefined,
  isPending: false,
  isSuccess: false,
  error: undefined,
};
export const INITIAL_NAMED_ACTION_STATUS: NamedActionStatus = {
  actionName: undefined,
  isFinished: false,
  isPending: false,
};
