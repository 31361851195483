import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Config,
  FilterConfiguration,
  FilterElementConfiguration,
  FieldValue,
  PrefillMapping,
  PreFillSet,
} from '../models';
import { ConfigurationControllerApi } from '../proxy/apis';
import {
  ConfigDTO,
  ElementConfigurationDTO,
  PrefillingDTO,
  PrefillMappingDTO,
  FilterConfigurationDTO,
  ModelPrefillingDTO,
} from '../proxy/models';
import { AuthInterceptor } from './auth.interceptor';

export class ConfigService {
  private readonly api = new ConfigurationControllerApi(AuthInterceptor.Instance);
  getConfig(): Observable<Config> {
    return this.api.getConfigurationUsingGET().pipe(map((data) => ConfigService.toConfig(data)));
  }

  static toConfig = (dto: ConfigDTO): Config => ({
    filterConfigurations: dto.filterConfigurations.map(ConfigService.toFilterConfiguration),
    formPrefillings: dto.formPrefillings.map(ConfigService.toFormPrefillings),
    tenantSettings: dto.tenantSettings, // since no real transformation is needed
  });

  private static toFilterConfiguration = (dto: FilterConfigurationDTO): FilterConfiguration => ({
    name: dto.name,
    elementConfigurations: dto.elementConfigurations.map(ConfigService.toElementConfiguration),
  });

  private static toElementConfiguration = (dto: ElementConfigurationDTO): FilterElementConfiguration => ({
    name: dto.name,
    elementType: dto.elementType,
    operator: dto.operator,
    options: dto.options,
  });

  private static toFormPrefillings = (dto: PrefillMappingDTO): PrefillMapping => {
    return {
      formName: dto.formName,
      fieldValues: dto.prefillings?.map(ConfigService.toFieldValues),
      preFillSets: dto.prefilSets?.map(ConfigService.toPreFillSets),
    };
  };

  private static toFieldValues = (dto: PrefillingDTO): FieldValue => ({
    fieldName: dto.fieldName,
    values: dto.values,
  });
  static toPreFillSets = (dto: ModelPrefillingDTO): PreFillSet => ({
    carModel: dto.carModel,
    componentName: dto.componentName,
    aiModelId: dto.aiModelId,
  });
}
