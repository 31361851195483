import { AnomalyCycleControllerApi, CycleDTO, CycleListDTO } from '../proxy';
import { Cycle, CycleUpdateRequest, FilteredCycles } from '../models';

import { AuthInterceptor } from './auth.interceptor';
import { FilterRequestType } from '../models/requestFilter.model';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { toFilterDTO } from '../utils';
import { sitemap } from '../routes';


export class CycleService {
  private readonly api = new AnomalyCycleControllerApi(AuthInterceptor.Instance);

  getAll(filter: FilterRequestType): Observable<FilteredCycles> {
    const filterDTO = toFilterDTO(filter);
    return this.api.getAllFailuresUsingPOST({ filterDTO }).pipe(map(CycleService.toFilteredCycles));
  }

  getCyclesByTestRunId(filter: FilterRequestType, testRunId: string): Observable<FilteredCycles> {
    const filterDTO = toFilterDTO(filter);
    return this.api
      .getAllFailuresByTestRunIdUsingPOST({ filterDTO, testRunId })
      .pipe(map(CycleService.toFilteredCycles));
  }

  getById(cycleId: string, testRunId: string, provideIo: boolean): Observable<Cycle> {
    return this.api
      .getFailureByIDUsingGET({
        cycleId,
        testRunId,
        provideIo
      })
      .pipe(catchError(err => { console.warn(err.response); alert(`An error (${err.response.status}) occured. Please contact sounce@porsche.digital for support.`); window.location.href = sitemap.home.path; return [] }))
      .pipe(map(CycleService.toCycle));
  }

  updateOne(failedCycle: CycleUpdateRequest): Observable<Cycle> {
    return this.api
      .updateFailureUsingPUT({ testRunId: failedCycle.testRunId, cycleId: failedCycle.id, cycleData: failedCycle, provideIo: failedCycle.provideIo })
      .pipe(map(CycleService.toCycle));
  }

  static toFilteredCycles = (data: CycleListDTO): FilteredCycles => ({
    cycles: data.cycles.map(CycleService.toCycle),
    total: data.total,
  });

  static toCycle = (dto: CycleDTO): Cycle => ({
    id: dto.id,
    testRunId: dto.testRunId,
    spectrogram: dto.spectrogram,
    audioFile: dto.audioFile,
    created: dto.created,
    currentCycle: dto.currentCycle,
    temperature: dto.temperature?.toString(),
    humidity: dto.humidity,
    carModel: dto.carModel,
    componentName: dto.componentName,
    description: dto.description,
    customerRelevant: dto.customerRelevant,
    flacFile: dto.flacFile,
    modelFinding: dto.modelFinding,
    referenceSpectrogram: dto.referenceSpectrogram,
    humanReadableId: dto.humanReadableId,
    nextAnomalyId: dto.nextAnomalyId,
    previousAnomalyId: dto.previousAnomalyId,
    orderNum: dto.orderNum,
  });
}
