import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MOCK_RECORDING_CLIENT_ID } from '../constants';
import { sitemap } from '../routes';

import {
  DetailsQuery,
  DetailsWithRecordingClientQuery,
  FilteredTrainings,
  NewTraining,
  RecordingStatus,
  Summary,
  Training,
  Recording,
  RecordingLabelType,
  LabelRecordingRequest,
  RecordingLabel,
} from '../models';
import { FilterRequestType } from '../models/';
import {
  CreateTrainingUsingPOSTRequest,
  NewTrainingDTO,
  SummaryDTO,
  TrainingControllerApi,
  TrainingDTO,
  TrainingDTOStateEnum,
  TrainingListDTO,
  CycleTrainingInfoDTO,
  CycleTrainingInfoDTOTrainingLabelEnum,
  TrainingLabelDTO,
} from '../proxy';
import { toFilterDTO } from '../utils';
import { AuthInterceptor } from './auth.interceptor';

export class TrainingService {
  private readonly api = new TrainingControllerApi(AuthInterceptor.Instance);

  getTrainings(filter: FilterRequestType): Observable<FilteredTrainings> {
    const filterDTO = toFilterDTO(filter);
    return this.api.getAllUsingPOST1({ filterDTO }).pipe(map(TrainingService.toFilteredTrainings));
  }
  getTrainingDetails(trainingDetailsQuery: DetailsQuery): Observable<Training> {
    return this.api
      .getTrainingByIdUsingGET({
        trainingId: trainingDetailsQuery.id,
        limit: trainingDetailsQuery.limit,
        offset: trainingDetailsQuery.offset
      })
      .pipe(catchError(err => { console.warn(err.response); alert(`An error (${err.response.status}) occured. Please contact sounce@porsche.digital for support.`); window.location.href = sitemap.training.record.path; return [] }))
      .pipe(map(TrainingService.toTraining));
  }

  createNewTraining(newTraining: NewTraining): Observable<Training> {
    const newTrainingDTO = TrainingService.toNewTrainingRequestBody(newTraining);
    return this.api.createTrainingUsingPOST(newTrainingDTO).pipe(map(TrainingService.toTraining));
  }
  pauseTraining(trainingWithRecordingClientQuery: DetailsWithRecordingClientQuery): Observable<Training> {
    return this.api
      .pauseTrainingUsingGET({
        trainingId: trainingWithRecordingClientQuery.id,
        recordingClientId: trainingWithRecordingClientQuery.recordingClientId ?? MOCK_RECORDING_CLIENT_ID,
      })
      .pipe(map(TrainingService.toTraining));
  }

  startTraining(trainingWithRecordingClientQuery: DetailsWithRecordingClientQuery): Observable<Training> {
    return this.api
      .startTrainingUsingGET({
        trainingId: trainingWithRecordingClientQuery.id,
        recordingClientId: trainingWithRecordingClientQuery.recordingClientId,
      })
      .pipe(map(TrainingService.toTraining));
  }
  stopTraining(trainingWithRecordingClientQuery: DetailsWithRecordingClientQuery): Observable<Training> {
    return this.api
      .stopTrainingUsingGET({
        trainingId: trainingWithRecordingClientQuery.id,
        recordingClientId: trainingWithRecordingClientQuery.recordingClientId ?? MOCK_RECORDING_CLIENT_ID,
      })
      .pipe(map(TrainingService.toTraining));
  }
  labelRecordings(labelRecordingRequest: LabelRecordingRequest): Observable<Training> {
    return this.api
      .updateLabelsUsingPOST({
        trainingId: labelRecordingRequest.trainingId,
        labels: labelRecordingRequest.labels.map(TrainingService.toTrainingLabelDTO),
      })
      .pipe(map(TrainingService.toTraining));
  }

  updateDescription(training: Training): Observable<Training> {
    return this.api
      .updateTrainingUsingPATCH({
        trainingId: training.id,
        updates: { description: training.description },
      })
      .pipe(map(TrainingService.toTraining));
  }

  static toTrainingLabelDTO = (recordingLabel: RecordingLabel): TrainingLabelDTO => {
    let labelDTO;
    switch (recordingLabel.label) {
      case 'IO':
        labelDTO = CycleTrainingInfoDTOTrainingLabelEnum.IO;
        break;
      case 'NIO':
        labelDTO = CycleTrainingInfoDTOTrainingLabelEnum.NIO;
        break;
      case 'NONE':
        labelDTO = CycleTrainingInfoDTOTrainingLabelEnum.NONE;
        break;
    }
    return { cycleId: recordingLabel.cycleId, label: labelDTO };
  };

  static toFilteredTrainings = (data: TrainingListDTO): FilteredTrainings => ({
    trainings: data.trainings.map(TrainingService.toTraining),
    total: data.total,
  });
  static toTraining = (dto: TrainingDTO): Training => ({
    id: dto.id,
    actualCycleCount: dto.actualCycleCount,
    carModel: dto.carModel,
    changeDate: new Date(dto.changeDate),
    componentName: dto.componentName,
    labeledAnomalyCount: dto.labelledAnomalyCount,
    labeledNotAnomalyCount: dto.labelledNotAnomalyCount,
    startDate: new Date(dto.startDate),
    state: TrainingService.toStatus(dto.state),
    trainingNumber: dto.trainingNumber,
    description: dto.description,
    recordingClientId: dto.recordingClientId,
    recordings: dto.recordings?.map(TrainingService.toRecording),
    metaInfo: dto.metaInfo
  });

  static toStatus = (state: TrainingDTOStateEnum): RecordingStatus => {
    switch (state) {
      case TrainingDTOStateEnum.CREATED:
        return 'angelegt';
      case TrainingDTOStateEnum.RUNNING:
        return 'in_betrieb';
      case TrainingDTOStateEnum.PAUSED:
        return 'pausiert';
      case TrainingDTOStateEnum.STANDBY:
        return 'standby';
      case TrainingDTOStateEnum.FINISHED:
        return 'offline';
      case TrainingDTOStateEnum.OFFLINE:
        return 'offline';
      default:
        return '-';
    }
  };
  static toNewTrainingDTO = (newTraining: NewTraining): NewTrainingDTO => ({
    componentName: newTraining.componentName,
    description: newTraining.description,
    carModel: newTraining.carModel,
  });

  static toNewTrainingRequestBody = (newTraining: NewTraining): CreateTrainingUsingPOSTRequest => ({
    newTraining: TrainingService.toNewTrainingDTO(newTraining),
  });

  static toSummary = (data: SummaryDTO): Summary => ({
    sortedByState: data.sortedByState,
    total: data.total,
  });
  static toRecording = (data: CycleTrainingInfoDTO): Recording => ({
    audioFile: data.audioFile,
    audioFileURL: data.audioFileURL,
    created: new Date(data.created),
    currentCycle: data.currentCycle,
    id: data.cycleId,
    spectrogramURL: data.spectrogramURL,
    humidity: data.humidity,
    temperature: data.temperature,
    label: TrainingService.toTrainingLabel(data.trainingLabel),
    orderNum: data.orderNum,
  });
  static toTrainingLabel = (data: string): RecordingLabelType => {
    switch (data) {
      case CycleTrainingInfoDTOTrainingLabelEnum.IO:
        return 'IO';
      case CycleTrainingInfoDTOTrainingLabelEnum.NIO:
        return 'NIO';
      case CycleTrainingInfoDTOTrainingLabelEnum.NONE:
        return 'NONE';
      default:
        return 'NONE';
    }
  };
}
