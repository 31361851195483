import { Epic } from 'redux-observable';
import { filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { fetchInitialConfig } from '.';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, takeUntilAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';

const fetchInitialConfigEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { configService }
) =>
  action$.pipe(
    filter(isActionOf(fetchInitialConfig.request)),
    switchMap(() =>
      configService.getConfig().pipe(
        map(fetchInitialConfig.success),
        catchErrorAndHandleWithAction(fetchInitialConfig.failure),
        takeUntilAction(action$, fetchInitialConfig.cancel)
      )
    )
  );

export default [fetchInitialConfigEpic];
