import { getType } from 'typesafe-actions';
import { Cycle, HttpError, TestRun } from '../../../models';
import { FilterRequestType } from '../../../models/requestFilter.model';
import { replaceIn, withKey } from '../../../utils';
import * as fromActions from '../actions';

type TestRunState = {
  readonly cycles: Cycle[];
  readonly isLastResult: boolean;
  readonly currentTestRun: TestRun;
  readonly isLoading: boolean;
  readonly error: HttpError;
  readonly isLoadingTestRunCycles: boolean;
  readonly totalTestRunCycles: number;
  readonly errorTestRunCycles: HttpError;
  readonly requestFilter: FilterRequestType;
  readonly previousRequestFilter: FilterRequestType;
  readonly isSameFilter: boolean;
  readonly testRunDetailUpdateStatus: TestRunDetailUpdateStatus;
};
export type TestRunDetailUpdateStatus = {
  isPending: boolean;
  isSuccess: boolean;
  error?: HttpError;
};

const initialTestRunDetailUpdateStatus: TestRunDetailUpdateStatus = {
  isPending: false,
  isSuccess: false,
  error: undefined,
};

const initialState: TestRunState = {
  currentTestRun: undefined,
  isLastResult: false,
  cycles: undefined,
  isLoading: false,
  error: undefined,
  isLoadingTestRunCycles: false,
  totalTestRunCycles: undefined,
  errorTestRunCycles: undefined,
  previousRequestFilter: undefined,
  requestFilter: undefined,
  isSameFilter: false,
  testRunDetailUpdateStatus: { ...initialTestRunDetailUpdateStatus },
};
const ID_STR = 'id';

export const testRunDetailReducer = (state = initialState, action: fromActions.TestRunAction): TestRunState => {
  switch (action.type) {
    /*
     *------------------------------- Fetch
     */

    case getType(fromActions.fetchTestRunDetails.request):
      return {
        ...state,
        isLoading: true,
        error: null,
        currentTestRun: null,
        cycles: null, //reset cycles when fetching new test run
      };
    case getType(fromActions.fetchTestRunDetails.success):
      return {
        ...state,
        isLoading: false,
        currentTestRun: action.payload,
      };
    case getType(fromActions.fetchTestRunDetails.failure):
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    /*
     *------------------------------- Fetch Filtered test run failures
     */
    case getType(fromActions.fetchFilteredTestRunCycles.request):
      return {
        ...state,
        isLoadingTestRunCycles: true,
        error: null,
        requestFilter: action.payload.filter,
        previousRequestFilter: state.previousRequestFilter ? { ...state.requestFilter } : null,
        isSameFilter: state.requestFilter?.page > state.previousRequestFilter?.page,
      };
    case getType(fromActions.fetchFilteredTestRunCycles.success):
      return {
        ...state,
        isLoadingTestRunCycles: false,
        cycles: action.payload.cycles,
        totalTestRunCycles: action.payload.total,
        previousRequestFilter: { ...state.requestFilter },
      };
    case getType(fromActions.fetchFilteredTestRunCycles.failure):
      return {
        ...state,
        isLoadingTestRunCycles: false,
        requestFilter: { ...state.previousRequestFilter },
        errorTestRunCycles: action.payload,
      };
    /*
     *------------------------------- Update Cycle Failure
     */

    case getType(fromActions.updateCycleFailure):
      return {
        ...state,
        cycles: replaceIn(state.cycles, withKey(action.payload.id, ID_STR), (item) => ({
          ...item,
          ...action.payload,
        })),
      };
    /*
     *------------------------------- Update TestRun in TestRun List
     */
    case getType(fromActions.updateTestRunInTestRunList):
      return {
        ...state,
        currentTestRun: { ...action.payload },
      };
    /*
     *------------------------------- Update TestRun Details
     */
    case getType(fromActions.updateTestRunDetails.request):
      return {
        ...state,
        testRunDetailUpdateStatus: {
          ...initialTestRunDetailUpdateStatus,
          isPending: true,
        },
      };
    case getType(fromActions.updateTestRunDetails.success):
      return {
        ...state,
        currentTestRun: action.payload,
        testRunDetailUpdateStatus: {
          ...initialTestRunDetailUpdateStatus,
          isSuccess: true,
        },
      };
    case getType(fromActions.updateTestRunDetails.failure):
      return {
        ...state,
        testRunDetailUpdateStatus: {
          ...initialTestRunDetailUpdateStatus,
          error: action.payload,
        },
      };

    /*
     *------------------------------- Reset Snackbar
     */
    case getType(fromActions.resetSnackbar):
      return {
        ...state,
        testRunDetailUpdateStatus: {
          ...initialTestRunDetailUpdateStatus,
        },
      };

    default:
      return state;
  }
};
