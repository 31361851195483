import DateFnsUtils from '@date-io/date-fns';
import { createStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { PButton } from '@porsche-design-system/components-react';
import { Input, Modal, Select, SelectOption, SelectOptionGroup, SelectValue, TextArea } from '@porsche/ui-kit-react';
import cx from 'clsx';
import { Field, FieldProps, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { NewTestRun, PrefillMapping } from '../../models';
import { isEmptyObject } from '../../utils';
import { LoadingMask } from '../LoadingMask';
import { getModalStyles } from './ListFilterModal';
import { DEFAULT_PLANNED_CYCLE_COUNT } from '../../constants';

const carModelStr = 'carModel';
const startDateStr = 'startDate';
const endDateStr = 'endDate';
const componentNameStr = 'componentName';
const plannedCycleCountStr = 'plannedCycleCount';
const aiModelIdStr = 'aiModelId';
const descriptionStr = 'description';

const useStyles = makeStyles(
  createStyles({
    ...getModalStyles(),
    ...{
      form: {
        minWidth: 790,
      },
      textArea: {
        flexBasis: 720,
        marginTop: 50,
      },
    },
  }),
  { name: 'NewTestRunFormModal' }
);

type NewTestRunFormProps = {
  isOpen: boolean;
  onClose: () => void;
  formPrefilling: PrefillMapping;
  onFormSubmit: (filter: NewTestRun) => void;
};

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

export const NewTestRunFormModal: React.FC<NewTestRunFormProps> = ({
  isOpen,
  onClose,
  formPrefilling: formPreFilling,
  onFormSubmit,
}) => {
  const c = useStyles({});

  const validationSchema = Yup.object<NewTestRun>().shape({
    plannedCycleCount: Yup.number()
      .positive('Bitte eine positive Zahl eingeben.')
      .integer('Bitte ganzzahlige Werte verwenden.')
      .required('Bitte eine positive Zahl eingeben.'),
  });

  const onCarModelChange = (val: SelectValue, setFieldValue: (field: string, value: any) => void) => {
    const filteredOptions = getFilteredOptions(val);
    setFilteredComponentNames(filteredOptions.componentNameOptions);
    setFieldValue(componentNameStr, filteredOptions?.componentNameOptions[0]?.value);
    setFieldValue(aiModelIdStr, filteredOptions.aiModelId);
  };
  const getFilteredOptions = (val: SelectValue) => {
    if (!val) {
      return {
        componentNames: [],
        aiModelId: undefined,
      };
    }
    const possibleSet = formPreFilling.preFillSets.find((preFillSet) => preFillSet.carModel === val);
    return {
      componentNameOptions: [{ value: possibleSet.componentName, label: possibleSet.componentName }],
      aiModelId: possibleSet?.aiModelId,
    };
  };

  const initialFormState: NewTestRun = {
    componentName: getFilteredOptions(formPreFilling.preFillSets[0].carModel)?.componentNameOptions[0]?.value,
    description: undefined,
    plannedCycleCount: DEFAULT_PLANNED_CYCLE_COUNT,
    carModel: formPreFilling.preFillSets[0].carModel,
    aiModelId: getFilteredOptions(formPreFilling.preFillSets[0].carModel)?.aiModelId,
    startDate: new Date(),
    endDate: new Date(),
  };

  const [filteredComponentNames, setFilteredComponentNames] = useState<SelectOption[] | SelectOptionGroup[]>(
    getFilteredOptions(formPreFilling.preFillSets[0].carModel)?.componentNameOptions
  );

  const [initialValues, setInitialValues] = useState<NewTestRun>(initialFormState);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      containerClassName={c.content}
      contentLabel="Neuen Test anlegen"
      size="dynamic"
    >
      <Modal.Title className={c.title}>Neuen Test anlegen</Modal.Title>
      {!formPreFilling ? (
        <LoadingMask isLoading={!formPreFilling} />
      ) : (
        <Formik
          validationSchema={validationSchema}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values: NewTestRun, { setSubmitting }) => {
            onFormSubmit(values);
            setInitialValues(values);
            setSubmitting(false);
            onClose();
          }}
        >
          {({ handleSubmit, setFieldValue, values, errors }) => (
            <form onSubmit={handleSubmit} className={c.form} noValidate>
              <div>
                <div>
                  <div className={c.inputContainerRow}>
                    <Field name={carModelStr}>
                      {({ field }: FieldProps) => (
                        <Select
                          clearable={false}
                          className={cx(c.input, c.select, c.porscheStyleFix)}
                          placeholder={'Modell *'}
                          value={field.value}
                          options={formPreFilling.preFillSets.map((preFillSet) => ({
                            value: preFillSet.carModel,
                            label: preFillSet.carModel,
                          }))}
                          onChange={(val) => {
                            onCarModelChange(val, setFieldValue);
                            setFieldValue(field.name, val);
                          }}
                        />
                      )}
                    </Field>

                    <Field name={componentNameStr}>
                      {({ field }: FieldProps) => (
                        <Select
                          clearable={false}
                          className={cx(c.input, c.select, c.porscheStyleFix)}
                          placeholder={'Komponente *'}
                          value={field.value}
                          options={filteredComponentNames}
                          onChange={(val) => setFieldValue(field.name, val)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={c.inputContainerRow}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={c.input}
                        label="Startdatum *"
                        disableToolbar
                        inputVariant="outlined"
                        variant="inline"
                        autoOk={true}
                        maxDate={values.endDate}
                        maxDateMessage="Startdatum muss vor Enddatum liegen."
                        format="dd.MM.yyyy"
                        value={values.startDate}
                        onChange={(date) => setFieldValue(startDateStr, date)}
                        PopoverProps={{ style: { zIndex: 13000 } }}
                        KeyboardButtonProps={{
                          name: startDateStr,
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <Field name={plannedCycleCountStr}>
                      {({ field }: FieldProps) => (
                        <Input
                          name={field.name}
                          type="number"
                          className={c.input}
                          value={field.value}
                          placeholder={'Geplante Zyklen *'}
                          onChange={(val) => setFieldValue(field.name, +val)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={c.inputContainerRow}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={c.input}
                        label="Enddatum *"
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        autoOk={true}
                        minDate={values.startDate}
                        minDateMessage="Enddatum muss nach Startdatum liegen."
                        format="dd.MM.yyyy"
                        value={values.endDate}
                        onChange={(date) => setFieldValue(endDateStr, date)}
                        PopoverProps={{ style: { zIndex: 13000 } }}
                        KeyboardButtonProps={{
                          name: endDateStr,
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>
              <div className={c.inputContainerRow}>
                <Field name={descriptionStr}>
                  {({ field, form: { errors, touched } }: FieldProps) => (
                    <>
                      <TextArea
                        className={c.textArea}
                        name={field.name}
                        value={field.value ?? ''}
                        placeholder={`${field.value ? 'Beschreibung' : 'Beschreibung hinzufügen (optional)'}`}
                        onChange={(val) => setFieldValue(field.name, val)}
                      />
                      {errors[descriptionStr] && touched[descriptionStr] && <div>{errors[descriptionStr]}</div>}
                    </>
                  )}
                </Field>
              </div>
              <div className={c.actions}>
                <PButton variant="tertiary" onClick={onClose} className={c.cancelButton}>
                  Abbrechen
                </PButton>
                <PButton type="submit" disabled={!isEmptyObject(errors)}>
                  Anlegen
                </PButton>
              </div>
            </form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
