import { getType } from 'typesafe-actions';
import { Cycle, HttpError, ActionStatus } from '../../../models';
import * as fromActions from '../actions';
import { INITIAL_ACTION_STATUS } from '../../../constants';
import { environment } from '../../../environments';

type State = {
  readonly filter: string;
  readonly currentCycle: Cycle;
  readonly isLoading: boolean;
  readonly error: HttpError;
  readonly cycleFailureDetailUpdateStatus: ActionStatus;
  readonly zipDownloadUrl: string;
  readonly zipDownloadActionStatus: ActionStatus;
};

const initialState: State = {
  filter: undefined,
  currentCycle: undefined,
  isLoading: false,
  error: undefined,
  cycleFailureDetailUpdateStatus: { ...INITIAL_ACTION_STATUS },
  zipDownloadUrl: undefined,
  zipDownloadActionStatus: { ...INITIAL_ACTION_STATUS },
};

export const cycleFailureDetailReducer = (state = initialState, action: fromActions.CycleFailureAction): State => {
  switch (action.type) {
    /*
     *------------------------------- Fetch
     */
    case getType(fromActions.fetchCycleFailureDetail.request):
      return {
        ...state,
        cycleFailureDetailUpdateStatus: {
          ...INITIAL_ACTION_STATUS,
        },
        isLoading: true,
        error: null,
      };
    case getType(fromActions.fetchCycleFailureDetail.success):
      return {
        ...state,
        isLoading: false,
        currentCycle: action.payload,
      };
    case getType(fromActions.fetchCycleFailureDetail.failure):
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    /*
     *------------------------------- Update
     */
    case getType(fromActions.updateCycleFailureDetail.request):
      return {
        ...state,
        cycleFailureDetailUpdateStatus: {
          ...INITIAL_ACTION_STATUS,
          isPending: true,
        },
      };
    case getType(fromActions.updateCycleFailureDetail.success):
      return {
        ...state,
        currentCycle: action.payload,
        cycleFailureDetailUpdateStatus: {
          ...INITIAL_ACTION_STATUS,
          isSuccess: true,
        },
      };
    case getType(fromActions.updateCycleFailureDetail.failure):
      return {
        ...state,
        cycleFailureDetailUpdateStatus: {
          ...INITIAL_ACTION_STATUS,
          error: action.payload,
        },
      };

    case getType(fromActions.resetZipDownloadUrl):
      return {
        ...state,
        zipDownloadUrl: undefined
      };

    /*
     *------------------------------- Reset Snackbar
     */
    case getType(fromActions.resetSnackbar):
      return {
        ...state,
        cycleFailureDetailUpdateStatus: {
          ...INITIAL_ACTION_STATUS,
        },
      };

    /*
     *------------------------------- Download Zip
     */

    case getType(fromActions.fetchDownloadZipKey.request):
      return {
        ...state,
        zipDownloadActionStatus: { ...INITIAL_ACTION_STATUS, isPending: true },
        zipDownloadUrl: undefined,
      };
    case getType(fromActions.fetchDownloadZipKey.success):
      return {
        ...state,
        zipDownloadActionStatus: { ...INITIAL_ACTION_STATUS, isSuccess: true },
        zipDownloadUrl: `${environment.apiEndpointUrl}${encodeURI(action.payload)}`,
      };
    case getType(fromActions.fetchDownloadZipKey.failure):
      return {
        ...state,
        zipDownloadActionStatus: { ...INITIAL_ACTION_STATUS, error: action.payload },
      };

    default:
      return state;
  }
};
