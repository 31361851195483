import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MOCK_RECORDING_CLIENT_ID } from '../constants';
import { sitemap } from '../routes';

import {
  CycleInfo,
  DetailsQuery,
  DetailsWithRecordingClientQuery,
  FilteredTestRuns,
  NewTestRun,
  RecordingStatus,
  Summary,
  TestRun,
  TestRunInfo,
  TestRunOverview,
  TestRunStatusEnum,
} from '../models';
import { FilterRequestType } from '../models';
import {
  CreateTestRunUsingPOSTRequest, CycleDTO,
  NewTestRunDTO,
  SummaryDTO,
  TestRunControllerApi,
  TestRunDTO,
  TestRunDTOStateEnum,
  TestRunInfoDTO,
  TestRunInfoDTOStateEnum,
  TestRunListDTO,
  TestRunsOverviewDTO,
} from '../proxy';
import { toFilterDTO } from '../utils';
import { AuthInterceptor } from './auth.interceptor';

export class TestRunService {
  private readonly api = new TestRunControllerApi(AuthInterceptor.Instance);

  getTestRunOverview(): Observable<TestRunOverview> {
    return this.api.overviewUsingGET().pipe(map(TestRunService.toTestRunOverview));
  }

  getTestRuns(filter: FilterRequestType): Observable<FilteredTestRuns> {
    const filterDTO = toFilterDTO(filter);
    return this.api.getAllUsingPOST({ filterDTO }).pipe(map(TestRunService.toFilteredTestRuns));
  }
  getTestRunDetails(testRunDetailsQuery: DetailsQuery): Observable<TestRun> {
    return this.api.getTestRunByIdUsingGET(
      {
        testRunId: testRunDetailsQuery.id,
        fromDate: testRunDetailsQuery.fromDate,
        toDate: testRunDetailsQuery.toDate
      }
    )
      .pipe(catchError(err => { console.warn(err.response); alert(`An error (${err.response.status}) occured. Please contact sounce@porsche.digital for support.`); window.location.href = sitemap.testRuns.home.path; return [] }))
      .pipe(map(TestRunService.toTestRun));
  }

  createTestRun(newTestRun: NewTestRun): Observable<TestRun> {
    const newTestRunDTO = TestRunService.toNewTestRunRequestBody(newTestRun);
    return this.api.createTestRunUsingPOST(newTestRunDTO).pipe(map(TestRunService.toTestRun));
  }
  pauseTestRun(testRunWithRecordingClientQuery: DetailsWithRecordingClientQuery): Observable<TestRun> {
    return this.api
      .pauseTestRunUsingGET({
        testRunId: testRunWithRecordingClientQuery.id,
        recordingClientId: testRunWithRecordingClientQuery.recordingClientId ?? MOCK_RECORDING_CLIENT_ID,
      })
      .pipe(map(TestRunService.toTestRun));
  }

  startTestRun(testRunWithRecordingClientQuery: DetailsWithRecordingClientQuery): Observable<TestRun> {
    return this.api
      .startTestRunUsingGET({
        testRunId: testRunWithRecordingClientQuery.id,
        recordingClientId: testRunWithRecordingClientQuery.recordingClientId,
      })
      .pipe(map(TestRunService.toTestRun));
  }
  stopTestRun(testRunWithRecordingClientQuery: DetailsWithRecordingClientQuery): Observable<TestRun> {
    return this.api
      .stopTestRunUsingGET({
        testRunId: testRunWithRecordingClientQuery.id,
        recordingClientId: testRunWithRecordingClientQuery.recordingClientId ?? MOCK_RECORDING_CLIENT_ID,
      })
      .pipe(map(TestRunService.toTestRun));
  }

  updateDescription(testRun: TestRun): Observable<TestRun> {
    return this.api
      .updateTestRunUsingPATCH({
        testRunId: testRun.id,
        updates: { description: testRun.description },
      })
      .pipe(map(TestRunService.toTestRun));
  }

  static toFilteredTestRuns = (data: TestRunListDTO): FilteredTestRuns => ({
    testRuns: data.testRuns.map(TestRunService.toTestRun),
    total: data.total,
  });
  static toTestRun = (dto: TestRunDTO): TestRun => ({
    id: dto.id,
    carModel: dto.carModel,
    componentName: dto.componentName,
    plannedCycleCount: dto.plannedCycleCount,
    actualCycleCount: dto.actualCycleCount,
    anomalyCount: dto.anomalyCount,
    recordingClientId: dto.recordingClientId,
    aiModelId: dto.aiModelId,
    startDate: dto.startDate,
    endDate: dto.endDate,
    humanReadableId: dto.humanReadableId,
    description: dto.description,
    status: TestRunService.toStatus(dto.state),
    testRunDataHistory: dto.failureHistory,
    changeDate: dto.changeDate,
    metaInfo: dto.metaInfo
  });

  static toCycle = (cycle: CycleDTO): CycleInfo => ({
    humidity: cycle.humidity,
    isAnomaly: cycle.error,
    temperature: cycle.temperature,
  });

  // See `displayedStates` in `AllTestRunsOverview.tsx` which ones are used
  static toStatus = (state: TestRunDTOStateEnum | TestRunInfoDTOStateEnum | TestRunStatusEnum): RecordingStatus => {
    switch (state) {
      case TestRunDTOStateEnum.CREATED:
        return 'angelegt';
      case TestRunDTOStateEnum.RUNNING:
        return 'in_betrieb';
      case TestRunDTOStateEnum.PAUSED:
        return 'pausiert';
      case TestRunDTOStateEnum.STANDBY:
        return 'standby';
      case TestRunDTOStateEnum.FINISHED:
        return 'offline';
      case TestRunDTOStateEnum.OFFLINE:
        return 'offline';
      default:
        return '-';
    }
  };
  static toNewTestRunDTO = (newTestRun: NewTestRun): NewTestRunDTO => ({
    componentName: newTestRun.componentName,
    description: newTestRun.description,
    plannedCycleCount: newTestRun.plannedCycleCount,
    carModel: newTestRun.carModel,
    aiModelId: newTestRun.aiModelId,
    startDate: newTestRun.startDate,
    endDate: newTestRun.endDate,
  });

  static toNewTestRunRequestBody = (newTestRun: NewTestRun): CreateTestRunUsingPOSTRequest => ({
    newTestRun: TestRunService.toNewTestRunDTO(newTestRun),
  });

  static toTestRunOverview = (data: TestRunsOverviewDTO): TestRunOverview => ({
    latest: data.latest.map(TestRunService.toTestRunInfo),
    summary: TestRunService.toSummary(data.summary),
  });

  static round(value: number, precision: number): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  static toTestRunInfo = (data: TestRunInfoDTO): TestRunInfo => ({
    testRunId: data.id,
    actualCycleCount: data.actualCycleCount,
    name: data.carModel,
    carModel: data.carModel,
    componentName: data.componentName,
    humanReadableId: data.humanReadableId,
    ioPerCent: TestRunService.round(data.ioPercent, 1),
    nioPerCent: TestRunService.round(data.nioPercent, 1),
    state: TestRunService.toStatus(data.state),
  });
  static toSummary = (data: SummaryDTO): Summary => ({
    sortedByState: data.sortedByState,
    total: data.total,
  });
}
