import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { ID_TOKEN, REDIRECT_URL } from '../constants';
import { sitemap } from '../routes';
import { getLocalStorageValue, setLocalStorageValue } from '../utils';
/**
 * This page extracts the token from the url and persists it in the local store. After that the user is rerouted to the redirect url that was set on 403
 * */
type Props = RouteComponentProps;
export const Login: React.FC<Props> = ({ location }) => {
  const [redirectUrl] = useState<string>(getLocalStorageValue(REDIRECT_URL));
  useEffect(() => {
    const idTokenRegEx = new RegExp(`${ID_TOKEN}=(.*?)&`);
    setLocalStorageValue(ID_TOKEN, location.hash.match(idTokenRegEx)[1]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <Redirect to={redirectUrl ? redirectUrl : sitemap.home.path}></Redirect>;
};
