// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CycleTrainingInfoDTO
 */
export interface CycleTrainingInfoDTO {
    /**
     * @type {string}
     * @memberof CycleTrainingInfoDTO
     */
    audioFile: string;
    /**
     * @type {string}
     * @memberof CycleTrainingInfoDTO
     */
    audioFileURL?: string;
    /**
     * @type {Date}
     * @memberof CycleTrainingInfoDTO
     */
    created: Date;
    /**
     * @type {number}
     * @memberof CycleTrainingInfoDTO
     */
    currentCycle: number;
    /**
     * @type {string}
     * @memberof CycleTrainingInfoDTO
     */
    cycleId: string;
    /**
     * @type {number}
     * @memberof CycleTrainingInfoDTO
     */
    humidity?: number;
    /**
     * @type {number}
     * @memberof CycleTrainingInfoDTO
     */
    spectrogramFile: string;
    /**
     * @type {string}
     * @memberof CycleTrainingInfoDTO
     */
    spectrogramURL?: string;
    /**
     * @type {string}
     * @memberof CycleTrainingInfoDTO
     */
    temperature?: number;
    /**
     * @type {string}
     * @memberof CycleTrainingInfoDTO
     */
    trainingLabel?: CycleTrainingInfoDTOTrainingLabelEnum;
    /**
     * @type {string}
     * @memberof CycleTrainingInfoDTO
     */
    orderNum?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CycleTrainingInfoDTOTrainingLabelEnum {
    IO = 'IO',
    NIO = 'NIO',
    NONE = 'NONE'
}

