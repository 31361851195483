import * as React from "react";
import {useEffect, useState} from "react";
import {getLocalStorageValue} from "../utils";
import {ID_TOKEN} from "../constants";
import {makeStyles} from "@material-ui/core";
import {createUrlWithParams, sitemap} from "../routes";
import {NavLink} from "react-router-dom";
import {colors} from "../colors";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {environment} from '../environments';

const useStyles = makeStyles(
  {
    clusteringIframe: {
      border: 0,
      minHeight: '100vh',
      width: '100vh',
    },
    linkToTestruns: {
      textDecoration: 'none',
      color: colors.porscheBlack,
    },
    linkToTestrunsButton: {
      display: 'flex',
      alignItems: 'center'
    },
    linkToTestrunsIcon: {
      fontSize: 16,
      color: colors.porscheRed,
    },
  },
  {name: 'Clustering'}
);

export interface Clustering {
  id: string,
  testrun_id: string
}

export interface Clusterings {
  clustering: [Clustering]
  total: number
}

export const Clustering = () => {
  const classes = useStyles({});
  const clusteringContentUrl = `${environment.apiEndpointUrl}/dash/?instance_id=`
  const tenantClusterings = `${environment.apiEndpointUrl}/clustering/`

  const [clusterings, setClusterings] = useState<Clusterings>()

  useEffect(() => {
    const fetchClusterings = async () => {
      const response = await fetch(tenantClusterings, {
        headers: {
          'Authorization': `Bearer ${getLocalStorageValue<string>(ID_TOKEN)}`,
        },
        credentials: 'include'
      })
      const clusterings = await response.json()
      setClusterings(clusterings as Clusterings)
    }
    fetchClusterings()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>{clusterings?.clustering.filter(cluster => cluster.testrun_id).map(cluster => {
        const testrunUrl = createUrlWithParams(sitemap.testRuns.detail.path, {
          id: cluster.testrun_id,
        });
        return <div key={cluster.id}>
          <NavLink
            className={classes.linkToTestruns}
            to={testrunUrl}
          >
            <div className={classes.linkToTestrunsButton}>
              <ArrowBackIosIcon className={classes.linkToTestrunsIcon}/>
              Zur Prüfung: {cluster.testrun_id}
            </div>

          </NavLink>
          <iframe id="clustering-iframe" className={classes.clusteringIframe} title="Clustering"
                  src={clusteringContentUrl + cluster.id}/>
        </div>
      })}</div>
    </>
  );
}