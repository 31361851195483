import { HttpError } from '.';

export type SnackbarStatus = {
  isOpen: boolean;
  error: HttpError;
};

export type NamedSnackbarStatus = {
  isOpen: boolean;
  error: HttpError;
  objectName?: string;
  actionName?: string;
};
export const initialSnackbarStatus: NamedSnackbarStatus = {
  isOpen: false,
  error: undefined,
  objectName: undefined,
  actionName: undefined,
};
