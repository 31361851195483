import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import nineElevenImg from '../assets/911_icon.svg';
import cayenneImg from '../assets/cayenne_icon.svg';
import caymanImg from '../assets/cayman_icon.svg';
import macanImg from '../assets/macan_icon.svg';
import panameraImg from '../assets/panamera_icon.svg';
import taycanImg from '../assets/taycan_icon.svg';
import doorImg from '../assets/door_icon.svg';
import roboticArmImg from '../assets/robotic_arm_icon.svg';
import ballBearing from '../assets/ballBearing.svg';
import anodeImg from '../assets/anode_icon.svg';
import { colors } from '../colors';
import { CAYENNE_STR, CAYMAN_STR, MACAN_STR, NINE_ELEVEN_STR, PANAMERA_STR, TAYCAN_STR, DOOR_COMPONENT_LIST, ROBOT_STR, BALL_BEARING_STR, ANODE_STR } from '../constants';

const useStyles = makeStyles(
  {
    figure: {
      display: 'inline-block',
      margin: '0 0 0 30px',
    },
    circleContainer: {
      display: 'grid',
      height: 160,
    },
    svg: {
      height: 160,
      width: 'auto',
    },
    carImg: {
      position: 'relative',
      top: -91,
      left: 44,
    },
    suvImg: {
      top: -95,
    },
    doorImg: {
      position: 'relative',
      height: 40,
      left: 60,
      top: -105,
    },
    roboticArmImg: {
      position: 'relative',
      height: 50,
      left: 55,
      top: -106,
    },
    ballBearingImg: {
      position: 'relative',
      height: 80,
      left: 40,
      top: -120,
    },
    anodeImg: {
      position: 'relative',
      height: 42,
      left: 50,
      top: -103,
    }
  },
  { name: 'Doughnut' }
);
type Props = {
  ioPerCent: number;
  nioPerCent: number;
  testrunName: string;
  componentName: string;
};
export const Doughnut: React.FC<Props> = ({ ioPerCent, nioPerCent, testrunName, componentName }) => {
  const c = useStyles({});
  const generateImage = () => {
    const testrunNameLowerCase = testrunName.toLowerCase();
    const componentLowerCase = componentName.toLowerCase();

    if (testrunNameLowerCase.includes(PANAMERA_STR)) {
      return <img className={c.carImg} src={panameraImg} alt="Panamera"></img>;
    } else if (testrunNameLowerCase.includes(CAYENNE_STR)) {
      return <img className={cx(c.carImg, c.suvImg)} src={cayenneImg} alt="Cayenne"></img>;
    } else if (testrunNameLowerCase.includes(TAYCAN_STR)) {
      return <img className={c.carImg} src={taycanImg} alt="Taycan"></img>;
    } else if (testrunNameLowerCase.includes(CAYMAN_STR)) {
      return <img className={c.carImg} src={caymanImg} alt="Cayman"></img>;
    } else if (testrunNameLowerCase.includes(MACAN_STR)) {
      return <img className={cx(c.carImg, c.suvImg)} src={macanImg} alt="Macan"></img>;
    } else if (testrunNameLowerCase.includes(NINE_ELEVEN_STR)) {
      return <img className={c.carImg} src={nineElevenImg} alt="911"></img>;
    } else if (DOOR_COMPONENT_LIST.some((comp) => componentLowerCase.includes(comp))) {
      return <img className={c.doorImg} src={doorImg} alt="Door and Window Icon"></img>;
    } else if (testrunNameLowerCase.includes(ROBOT_STR)) {
      return <img className={c.roboticArmImg} src={roboticArmImg} alt="Robotic Arm"></img>;
    } else if (componentLowerCase.includes(BALL_BEARING_STR)) {
      return <img className={c.ballBearingImg} src={ballBearing} alt="Kugellager"></img>;
    } else if (componentLowerCase.includes(ANODE_STR)) {
      return <img className={c.anodeImg} src={anodeImg} alt="Anode"></img>;
    }
    else {
      return null;
    }
  };
  return (
    <figure className={c.figure}>
      <div className={c.circleContainer}>
        <svg className={c.svg} viewBox="0 0 42 42" aria-labelledby="anomalies" role="img">
          <circle
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            style={{
              stroke: `${colors.porscheRed}`,
              strokeWidth: 5,
            }}
            role="presentation"
          ></circle>
          <circle
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            style={{
              stroke: `${ioPerCent ? colors.greyDarkest : colors.neutralGrey4}`,
              strokeWidth: '5',
              strokeDasharray: `${ioPerCent}, ${nioPerCent}`, // set io and nio here
              strokeDashoffset: 25,
            }}
            aria-labelledby="donut-segment-1-title donut-segment-1-desc"
          ></circle>
        </svg>
        {generateImage()}
      </div>
    </figure>
  );
};
