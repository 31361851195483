import { Modal } from '@porsche/ui-kit-react';
import { PButton } from '@porsche-design-system/components-react';
import React from 'react';
import { createStyles } from '@material-ui/core/styles';
import { getModalStyles } from './Form/ListFilterModal';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  createStyles({
    ...getModalStyles(),
  }),
  { name: 'CustomerRelevanceInfoModal' }
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

export const CustomerRelevanceInfoModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const c = useStyles({});

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Kundenrelevanz">
      <Modal.Title className={c.title}>Kundenrelevanz</Modal.Title>
      <Modal.Text>
        {
          'Durch Ihre Beurteilung, ob das Geräusch richtig als i.O. oder n.i.O. (kundenrelevant) erkannt wurde, bekommt das KI-Modell Feedback zur Genauigkeit der Vorhersage.'
        }
      </Modal.Text>
      <div className={c.actions}>
        <PButton onClick={onClose}>Ok</PButton>
      </div>
    </Modal>
  );
};
