// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CycleTrainingInfoDTO, MetaInfo,
} from './';

/**
 * @export
 * @interface TrainingDTO
 */
export interface TrainingDTO {
    /**
     * @type {number}
     * @memberof TrainingDTO
     */
    actualCycleCount: number;
    /**
     * @type {string}
     * @memberof TrainingDTO
     */
    carModel: string;
    /**
     * @type {Date}
     * @memberof TrainingDTO
     */
    changeDate: Date;
    /**
     * @type {string}
     * @memberof TrainingDTO
     */
    componentName: string;
    /**
     * @type {string}
     * @memberof TrainingDTO
     */
    description?: string;
    /**
     * @type {string}
     * @memberof TrainingDTO
     */
    id: string;
    /**
     * @type {number}
     * @memberof TrainingDTO
     */
    labelledAnomalyCount: number;
    /**
     * @type {number}
     * @memberof TrainingDTO
     */
    labelledNotAnomalyCount: number;
    /**
     * @type {boolean}
     * @memberof TrainingDTO
     */
    legalHold?: boolean;
    /**
     * @type {string}
     * @memberof TrainingDTO
     */
    recordingClientId?: string;
    /**
     * @type {string}
     * @memberof TrainingDTO
     */
    recordingType: string;
    /**
     * @type {Array<CycleTrainingInfoDTO>}
     * @memberof TrainingDTO
     */
    recordings?: Array<CycleTrainingInfoDTO>;
    /**
     * @type {Date}
     * @memberof TrainingDTO
     */
    startDate: Date;
    /**
     * @type {string}
     * @memberof TrainingDTO
     */
    state: TrainingDTOStateEnum;
    /**
     * @type {number}
     * @memberof TrainingDTO
     */
    trainingNumber: number;
    /**
     * @type {MetaInfo}
     * @memberof TrainingDTO
     */
    metaInfo?: MetaInfo;
}

/**
 * @export
 * @enum {string}
 */
export enum TrainingDTOStateEnum {
    CREATED = 'CREATED',
    RUNNING = 'RUNNING',
    STANDBY = 'STANDBY',
    PAUSED = 'PAUSED',
    FINISHED = 'FINISHED',
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE'
}

