import { createStyles, makeStyles } from '@material-ui/core';
import { PButton } from '@porsche-design-system/components-react';
import { Pagination } from '@porsche/ui-kit-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteProps } from 'react-router-dom';
import {
  CustomSnackbar,
  Header,
  LoadingMask,
  NewTrainingRecordingFormModal,
  SelectRecordingClientModal,
  StartRecordingConfirmationModal,
  TrainingTable,
} from '../components';
import {
  END_RECORDING_STR,
  INITIAL_REQUEST_FILTER_TEST_RUNS,
  KEYDOWN_EVENT,
  PAUSE_RECORDING_STR,
  START_RECORDING_STR,
} from '../constants';
import {
  DetailsWithRecordingClientQuery,
  NamedActionStatus,
  NamedSnackbarStatus,
  SelectRecordingClientModalProps,
} from '../models';
import {
  createTraining,
  fetchTrainings,
  getCreateTrainingStatus,
  getIsLoading,
  getLastRequestFilter,
  getPauseTrainingStatus,
  getStartTrainingStatus,
  getStopTrainingStatus,
  getTotalTrainings,
  getTrainingControlPrefilling,
  getTrainings,
  newTrainingConfigurations,
  pauseTraining,
  resetCreateTrainingStatus,
  resetStartTrainingStatus,
  startTraining,
  stopTraining,
} from '../store/trainings';
import { extractQueryParamAsJSON, onArrowKeyPagination } from '../utils';
import { getPaginationStyles } from './TestRunList';

const useStyles = makeStyles(
  createStyles({
    ...getPaginationStyles(),
  }),
  { name: 'RecordingTraining' }
);

const initialNamedActionStatus: NamedActionStatus = {
  actionName: undefined,
  isFinished: false,
  isPending: false,
};
const initialSnackbarStatus: NamedSnackbarStatus = {
  isOpen: false,
  error: undefined,
  objectName: undefined,
  actionName: undefined,
};

export const Training: React.FC<RouteProps> = ({ history, location }) => {
  const c = useStyles({});
  const dispatch = useDispatch();

  const trainings = useSelector(getTrainings);
  const totalTrainings = useSelector(getTotalTrainings);
  const newTrainingRecordingConfigurations = useSelector(newTrainingConfigurations);
  const trainingRecordingControlPrefilling = useSelector(getTrainingControlPrefilling);
  const isLoading = useSelector(getIsLoading);
  const createTrainingStatus = useSelector(getCreateTrainingStatus);
  const startTrainingStatus = useSelector(getStartTrainingStatus);
  const pauseTrainingStatus = useSelector(getPauseTrainingStatus);
  const stopTrainingStatus = useSelector(getStopTrainingStatus);

  const [isNewTrainingRecordingModalOpen, setIsNewTrainingRecordingModalOpen] = useState(false);
  const [snackbarStatus, setSnackbarStatus] = useState<NamedSnackbarStatus>(initialSnackbarStatus);
  const [actionStatus, setActionStatus] = useState<NamedActionStatus>(initialNamedActionStatus);
  const [selectRecordingClientModalStatus, setSelectRecordingClientModalStatus] = useState<
    SelectRecordingClientModalProps
  >(undefined);
  const [confirmationModalStatus, setConfirmationModalStatus] = useState<SelectRecordingClientModalProps>(undefined);

  const openNewTrainingRecordingModal = () => {
    removeOnKeyDown();
    setIsNewTrainingRecordingModalOpen(true);
  };
  const closeNewTrainingRecordingModal = () => {
    addOnKeyDown();
    setIsNewTrainingRecordingModalOpen(false);
  };

  const lastRequestFilter = useSelector(getLastRequestFilter);

  const closeSnackbar = () => setSnackbarStatus((prevState) => ({ ...prevState, isOpen: false }));

  const closeSelectRecordingClientIdModal = () => {
    dispatch(resetCreateTrainingStatus());
    setSelectRecordingClientModalStatus(undefined);
  };
  useEffect(() => {
    if (selectRecordingClientModalStatus?.id) {
      removeOnKeyDown();
    } else {
      addOnKeyDown();
    }
  }, [selectRecordingClientModalStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const openConfirmationModal = (recording: DetailsWithRecordingClientQuery) => setConfirmationModalStatus(recording);

  const closeConfirmationModal = () => setConfirmationModalStatus(undefined);

  const onKeyDown = (e) => onArrowKeyPagination(e, lastRequestFilter, totalTrainings, loadTrainingPage);
  const addOnKeyDown = () => document.addEventListener(KEYDOWN_EVENT, onKeyDown, false);
  const removeOnKeyDown = () => document.removeEventListener(KEYDOWN_EVENT, onKeyDown, false);

  useEffect(() => {
    if (!isNewTrainingRecordingModalOpen && !selectRecordingClientModalStatus?.id) {
      addOnKeyDown();
    }
    return () => removeOnKeyDown();
  });
  useEffect(() => {
    setSnackbarStatus({
      isOpen:
        createTrainingStatus.isSuccess ||
        startTrainingStatus.isSuccess ||
        pauseTrainingStatus.isSuccess ||
        stopTrainingStatus.isSuccess ||
        !!createTrainingStatus.error ||
        !!startTrainingStatus.error ||
        !!stopTrainingStatus.error ||
        !!pauseTrainingStatus.error,

      error:
        createTrainingStatus.error ??
        startTrainingStatus.error ??
        pauseTrainingStatus.error ??
        stopTrainingStatus.error,
      objectName: 'Datenerfassung',

      actionName:
        ((createTrainingStatus.isSuccess || !!createTrainingStatus.error) && 'angelegt') ||
        ((startTrainingStatus.isSuccess || !!startTrainingStatus.error) && 'gestartet') ||
        ((stopTrainingStatus.isSuccess || !!stopTrainingStatus.error) && 'beendet') ||
        ((pauseTrainingStatus.isSuccess || !!pauseTrainingStatus.error) && 'pausiert'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createTrainingStatus.isSuccess,
    startTrainingStatus.isSuccess,
    pauseTrainingStatus.isSuccess,
    stopTrainingStatus.isSuccess,
    createTrainingStatus.error,
    startTrainingStatus.error,
    pauseTrainingStatus.error,
    stopTrainingStatus.error,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActionStatus({
      isFinished:
        createTrainingStatus.isSuccess ||
        startTrainingStatus.isSuccess ||
        pauseTrainingStatus.isSuccess ||
        stopTrainingStatus.isSuccess ||
        !!createTrainingStatus.error ||
        !!startTrainingStatus.error ||
        !!pauseTrainingStatus.error ||
        !!stopTrainingStatus.error,
      isPending:
        createTrainingStatus.isPending ||
        startTrainingStatus.isPending ||
        pauseTrainingStatus.isPending ||
        stopTrainingStatus.isPending,

      actionName:
        (startTrainingStatus.isPending && START_RECORDING_STR) ||
        (stopTrainingStatus.isPending && END_RECORDING_STR) ||
        (pauseTrainingStatus.isPending && PAUSE_RECORDING_STR),
    });
  }, [createTrainingStatus, startTrainingStatus, pauseTrainingStatus, stopTrainingStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchTrainings.request(decodeURIEncodedFilterJSONString(location.search)));
    return () => { dispatch(fetchTrainings.cancel()) };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const decodeURIEncodedFilterJSONString = (queryParams?: string) => {
    if (!queryParams) {
      return { ...INITIAL_REQUEST_FILTER_TEST_RUNS };
    }
    return { ...INITIAL_REQUEST_FILTER_TEST_RUNS, filterConditions: extractQueryParamAsJSON(queryParams, 'filter') };
  };

  const loadTrainingPage = (event: React.MouseEvent<HTMLElement, MouseEvent> | KeyboardEvent, page: number) => {
    const newFilter = { ...lastRequestFilter };
    newFilter.page = page;
    dispatch(fetchTrainings.request(newFilter));
  };

  useEffect(() => {
    if (startTrainingStatus?.isSuccess) {
      closeSelectRecordingClientIdModal();
    }
  }, [startTrainingStatus]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (createTrainingStatus.id) {
      setSelectRecordingClientModalStatus({ id: createTrainingStatus.id });
    }
  }, [createTrainingStatus]);
  return (
    <>
      <Header title={'Trainingsdaten erfassen'}>
        <PButton icon="add" onClick={openNewTrainingRecordingModal}>
          Neue Trainingsdaten erfassen
        </PButton>
      </Header>
      {newTrainingRecordingConfigurations && isNewTrainingRecordingModalOpen && (
        <NewTrainingRecordingFormModal
          isOpen={isNewTrainingRecordingModalOpen}
          onClose={closeNewTrainingRecordingModal}
          formPrefilling={newTrainingRecordingConfigurations}
          onFormSubmit={(args) => dispatch(createTraining.request(args))}
        />
      )}
      {!!selectRecordingClientModalStatus?.id && (
        <SelectRecordingClientModal
          isOpen={!!selectRecordingClientModalStatus?.id}
          onClose={closeSelectRecordingClientIdModal}
          id={selectRecordingClientModalStatus?.id}
          recordingClientId={selectRecordingClientModalStatus?.recordingClientId}
          startCallback={openConfirmationModal}
          startActionStatus={startTrainingStatus}
          formPrefilling={trainingRecordingControlPrefilling}
          resetActionStatus={() => dispatch(resetStartTrainingStatus())}
        />
      )}
      {!!confirmationModalStatus?.id && (
        <StartRecordingConfirmationModal
          isOpen={!!confirmationModalStatus?.id}
          onClose={closeConfirmationModal}
          confirmationModalStatus={confirmationModalStatus}
          onConfirm={(args) => dispatch(startTraining.request(args))}
          startActionStatus={startTrainingStatus}
          resetActionStatus={() => dispatch(resetStartTrainingStatus())}
        ></StartRecordingConfirmationModal>
      )}
      <TrainingTable
        trainings={trainings}
        pauseTraining={(args) => dispatch(pauseTraining.request(args))}
        stopTraining={(args) => dispatch(stopTraining.request(args))}
        trainingActionStatus={actionStatus}
        setModalStatus={setSelectRecordingClientModalStatus}
      />

      <LoadingMask isLoading={isLoading} />
      <CustomSnackbar
        isSnackbarOpen={snackbarStatus.isOpen}
        closeSnackbar={closeSnackbar}
        type={snackbarStatus.error ? 'error' : 'success'}
      >
        <>
          {snackbarStatus.error ? (
            <span id="message-id">{`${snackbarStatus.objectName} konnte nicht ${snackbarStatus.actionName} werden: ${snackbarStatus.error.message ?? 'Unbekanter Fehler.'
              }`}</span>
          ) : (
            <span id="message-id">{`${snackbarStatus.objectName} wurde erfolgreich ${snackbarStatus.actionName}.`}</span>
          )}
        </>
      </CustomSnackbar>
      {/* //TODO replace Pagination with PPagination (porsche design system) as soon as their events for page change work */}
      {lastRequestFilter && (
        <Pagination
          totalItemsCount={totalTrainings ?? 0}
          itemsPerPage={INITIAL_REQUEST_FILTER_TEST_RUNS.size}
          activePage={lastRequestFilter?.page ?? 1}
          onClick={loadTrainingPage}
          className={c.paginationFix}
        />
      )}
    </>
  );
};
