import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

const initialConfigState = (state: RootState) => state.configReducer;

export const getConfig = createSelector(
  initialConfigState,
  (state) => state.config
);

export const getIsLoading = createSelector(
  initialConfigState,
  (state) => state.isLoading
);

export const showAITab = createSelector(initialConfigState, (state) => {
  return state.config.tenantSettings?.globalSettings.showAITab ?? false
});

export const showTestrunsView = createSelector(initialConfigState, (state) => {
  return state.config.tenantSettings?.globalSettings.showTestrunsTab ?? true
});

export const showRecordingsView = createSelector(initialConfigState, (state) => {
  return state.config.tenantSettings?.globalSettings.showRecordingsTab ?? true
});

export const showAudioPlayButton = createSelector(initialConfigState, (state) => {
  return state.config.tenantSettings?.globalSettings.showAudioPlayButton ?? true
});

export const showClusteringView = createSelector(initialConfigState, (state) => {
  return false // will use sounce-next-dashboard for clustering view
});
