import * as React from 'react';

import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';

import { AudioPlayer } from '.';
import { AudioPlayerStatus } from './AudioPlayer';
import { PCheckboxWrapper } from '@porsche-design-system/components-react';
import { Recording } from '../models';
import { colors } from '../colors';
import cx from 'clsx';
import { getSubtitleStyles } from '../pages/TrainingDetail';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { showAudioPlayButton } from '../store/config/selectors';


const useStyles = makeStyles(
  {
    ...getSubtitleStyles(),

    root: {
      width: 389,
      display: 'block',
      maxHeight: 373,
      overflowY: 'scroll',
    },
    listItem: {
      height: 60,
    },
    label: {
      float: 'right',
      fontSize: 16,
      lineHeight: 'initial',
      marginLeft: 32,
    },
    iO: {
      color: colors.snackbarSuccess,
    },
    niO: {
      color: colors.snackbarError,
    },
    selectedListItem: {
      backgroundColor: colors.selectedRecordingInList,
    },
    allCheckbox: {
      paddingLeft: 16,
      verticalAlign: 'top',
      display: 'inline-block',
    },
    subtitle: {
      ...getSubtitleStyles().subtitle,
      margin: 0,
      marginRight: 0,
    },
    subtitleWrapper: {
      ...getSubtitleStyles().subtitleWrapper,
      verticalAlign: 'top',
    },
    selectCount: {
      float: 'right',
      marginLeft: 32,
    },
    selectAllLabel: {
      paddingLeft: 9,
    },
    listHeader: {
      marginBottom: 25,
    },
    listFooter: {
      marginTop: 25,
    },
  },
  { name: 'TrainingRecordingList' },
);

type Props = {
  allRecordingsCount: number,
  recordings: Recording[];
  setSelectedRecordingId: (id: string) => void;
  checkedIds: string[];
  setCheckedIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedRecordingId?: string;
};
export const TrainingRecordingList: React.FC<Props> = ({
  allRecordingsCount,
  recordings,
  setSelectedRecordingId,
  selectedRecordingId,
  checkedIds,
  setCheckedIds,
}) => {
  const elementRef = useRef();

  useEffect(() => {
    // elementRef.current;
  }, [])


  const c = useStyles({});
  const handleToggle = (id: string, isSingleSelect?: boolean) => () => {
    const currentIndex = checkedIds.indexOf(id);
    if (isSingleSelect) {
      setCheckedIds([id]);
      setSelectedRecordingId(id);
      return;
    }


    const newChecked = [...checkedIds];

    if (currentIndex === -1) {
      newChecked.push(id);
      setSelectedRecordingId(id);
    } else {
      newChecked.splice(currentIndex, 1);
      setSelectedRecordingId(newChecked.length > 0 ? newChecked[0] : null);
    }
    setCheckedIds(newChecked);
  };
  const toggleCheckAll = () => {
    if (checkedIds.length === recordings.length) {
      setCheckedIds([]);
      setSelectedRecordingId(null);
    } else {
      setCheckedIds(recordings.map(({ id }) => id));
      if (selectedRecordingId === null) {
        setSelectedRecordingId(null);
      }
    }
  };
  const [audioPlayerStatus, setAudioPlayerStatus] = useState<AudioPlayerStatus>(undefined);
  const stopAllOtherPlayers = (id: string) => {
    setAudioPlayerStatus({ idToIgnore: id, shouldStop: true });
  };

  const showAudioPlay: boolean = useSelector(showAudioPlayButton);

  return (
    <>
      <div className={c.listHeader} ref={elementRef}>
        <PCheckboxWrapper hideLabel={true} onClick={toggleCheckAll} className={c.allCheckbox}>
          <input type='checkbox' checked={checkedIds.length === recordings.length} onChange={toggleCheckAll} />
        </PCheckboxWrapper>
        <div className={cx(c.subtitleWrapper, c.selectAllLabel)} onClick={toggleCheckAll}>
          <h4 className={c.subtitle}>Alle auswählen</h4>
        </div>
        <div className={cx(c.subtitleWrapper, c.selectCount)}>
          <h4 className={c.subtitle}>{`${checkedIds.length}/${allRecordingsCount} ausgewählt`}</h4>
        </div>
      </div>
      <List className={c.root}>
        {recordings.map(({ id, audioFile, audioFileURL, currentCycle, label }) => {
          const labelId = `checkbox-list-label-${audioFile}`;

          return (
            <ListItem
              className={cx(c.listItem, checkedIds.find(rec => rec === id) && c.selectedListItem)}
              key={id}
              dense
              button
              onClick={handleToggle(id)}
            >
              <ListItemIcon>
                <PCheckboxWrapper hideLabel={true}>
                  <input type='checkbox' checked={checkedIds.includes(id)} onChange={handleToggle(id)} />
                </PCheckboxWrapper>
              </ListItemIcon>
              {showAudioPlay &&
                <AudioPlayer
                  id={id}
                  audioFileURL={audioFileURL}
                  stopAllOtherPlayers={stopAllOtherPlayers}
                  audioPlayerStatus={audioPlayerStatus}
                />
              }
              <ListItemText id={labelId} primary={`Aufnahme ${currentCycle}`} />
              <span className={cx(c.label, label === 'IO' ? c.iO : label === 'NIO' ? c.niO : null)}>
                {label === 'IO' ? 'i.O.' : label === 'NIO' ? 'n.i.O.' : ''}
              </span>
            </ListItem>
          );
        })}
      </List>
      <div className={cx(c.subtitleWrapper, c.selectCount, c.listFooter)}>
        <h4 className={c.subtitle}>{`${recordings?.filter((recording) => recording.label === 'IO' || recording.label === 'NIO')?.length ?? 0
          }/${allRecordingsCount} beschriftet`}</h4>
      </div>
    </>
  );
};
