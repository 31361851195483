import { makeStyles } from '@material-ui/core';
import { PButton } from '@porsche-design-system/components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header, NewTestRunFormModal, TestRunAnomaliesOverview, LoadingMask } from '../components';
import { AllTestRunsOverview } from '../components/AllTestRunsOverview';
import { sitemap } from '../routes';
import { showNewTestrunButton } from '../store/test-runs/selectors';

import {
  createTestRun,
  getCreateTestRunStatus,
  newTestRunConfigurationsSelector,
  fetchOverview,
  getOverview,
  getIsLoading,
  resetOverview,
} from '../store/test-runs';
import { Redirect } from 'react-router';

const useStyles = makeStyles(
  {
    linkWithoutDecoration: {
      textDecoration: 'none',
    },
    createButton: {
      float: 'right',
    },
    actionBar: {
      marginBottom: 147,
    },
  },
  { name: 'Overview' }
);

export const Overview: React.FC = () => {
  const c = useStyles({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOverview.request());
    return () => {
      dispatch(resetOverview());
      dispatch(fetchOverview.cancel());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createTestRunState = useSelector(getCreateTestRunStatus);
  const newTestRunConfigurations = useSelector(newTestRunConfigurationsSelector);
  const overview = useSelector(getOverview);
  const isLoading = useSelector(getIsLoading);

  const displayTestrunButton = useSelector(showNewTestrunButton);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <Header title={'Übersicht'} />
      <LoadingMask isLoading={isLoading} />
      {overview && (
        <>
          <AllTestRunsOverview {...overview.summary} />
          <h2 id="test-station-header">Prüfstationen</h2>
          <TestRunAnomaliesOverview latestTestRuns={overview.latest} />
          <div className={c.actionBar}>
            <Link to={sitemap.testRuns.home.path} className={c.linkWithoutDecoration}>
              <PButton variant="tertiary" icon="list">
                Alle anzeigen
              </PButton>
            </Link>
            {displayTestrunButton &&
              <PButton icon="add" onClick={openModal} className={c.createButton}>
                Neuen Test anlegen
              </PButton>
            }
          </div>

          {newTestRunConfigurations && isModalOpen && (
            <NewTestRunFormModal
              isOpen={isModalOpen}
              onClose={closeModal}
              formPrefilling={newTestRunConfigurations}
              onFormSubmit={(args) => dispatch(createTestRun.request(args))}
            />
          )}
          {overview && (createTestRunState.isSuccess || !!createTestRunState.error) && (
            <Redirect to={sitemap.testRuns.home.path} />
          )}
        </>
      )}
    </>
  );
};
