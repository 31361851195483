import { Switch, SwitchProps, makeStyles } from '@material-ui/core';

import CheckIcon from '../assets/check_icon.svg';
import MinusIcon from '../assets/minus_icon.svg';
import React from 'react';
import { colors } from '../colors';
import { materialTheme } from '../theme';

const useStyles = makeStyles(
  {
    root: {
      width: 48,
      height: 22,
      padding: 0,
      margin: `5px ${materialTheme.spacing(1)}px ${materialTheme.spacing(1)}px ${materialTheme.spacing(1)}px`,
      overflow: 'unset',
    },
    switchBase: {
      padding: 0,
      border: `1px solid ${colors.tableBorder}`,
      '&$checked': {
        transform: 'translateX(24px)',
        padding: 1,
        color: colors.white,
        border: 'none',
        '& + $track': {
          backgroundColor: colors.statusGreen,
          border: `1px solid ${colors.statusGreen}`,
          opacity: 1,
          backgroundImage: `url(${CheckIcon})`,
          backgroundSize: '18px 18px',
          backgroundPosition: '3px 2px',
          backgroundRepeat: 'no-repeat',
        },
      },
      '&$focusVisible $thumb': {},
    },
    thumb: {
      width: 22,
      height: 22,
      boxShadow: 'none',
      backgroundColor: colors.white,
    },
    track: {
      borderRadius: 13.5,
      border: `1px solid ${colors.tableBorder}`,
      backgroundColor: colors.white,
      opacity: 1,
      backgroundImage: `url(${MinusIcon})`,
      backgroundSize: '18px 18px',
      backgroundPosition: '25px 2px',
      backgroundRepeat: 'no-repeat',
    },
    checked: {},
    focusVisible: {},
  },
  { name: 'SliderToggle' }
);

type Props = SwitchProps;

export const SliderToggle: React.FC<Props> = ({ ...props }: Props) => {
  const c = useStyles({});
  return (
    <Switch
      focusVisibleClassName={c.focusVisible}
      disableRipple
      classes={{
        root: c.root,
        switchBase: c.switchBase,
        thumb: c.thumb,
        track: c.track,
        checked: c.checked,
      }}
      {...props}
    />
  );
};
