import * as React from 'react';

import { useEffect, useRef, useState } from 'react';

import { PIcon } from '@porsche-design-system/components-react';
import { makeStyles } from '@material-ui/core';

export type AudioPlayerStatus = { idToIgnore: string; shouldStop: boolean };

type Props = {
  id: string;
  audioFileURL: string;
  stopAllOtherPlayers: (id: string) => void;
  audioPlayerStatus: AudioPlayerStatus;
};

const useStyles = makeStyles(
  {
    controls: {
      borderRadius: 15,
      border: '1px solid black',
      marginRight: 22,
    },
  },
  { name: 'AudioPlayer' }
);

export const AudioPlayer: React.FC<Props> = ({ id, audioFileURL, stopAllOtherPlayers, audioPlayerStatus }) => {
  const c = useStyles({});
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayerRef = useRef(null);

  const stopPlaying = () => {
    audioPlayerRef.current.pause();
    audioPlayerRef.current.currentTime = 0;
    setIsPlaying(false);
  };
  const playAudio = () => {
    audioPlayerRef.current.play();
    setIsPlaying(true);
  };

  useEffect(() => {
    if (audioPlayerStatus?.idToIgnore !== id && audioPlayerStatus?.shouldStop) {
      stopPlaying();
    }
  }, [audioPlayerStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const togglePlay = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (isPlaying) {
      stopPlaying();
    } else {
      stopAllOtherPlayers(id);
      playAudio();
    }
  };

  return (
    <>
      <div onClick={(e) => togglePlay(e)} className={c.controls}>
        {isPlaying ? <PIcon name={'pause'} role={'img'} /> : <PIcon name={'play'} role={'img'} />}
      </div>
      <audio ref={audioPlayerRef} onEnded={() => setIsPlaying(false)} src={audioFileURL} preload="none" />
    </>
  );
};
