import { ActionType, createAsyncAction } from 'typesafe-actions';
import { Config, HttpError } from '../../models';

export const fetchInitialConfig = createAsyncAction(
  '@app/FETCH_INITIAL_CONFIG',
  '@app/FETCH_INITIAL_CONFIG_SUCCESS',
  '@app/FETCH_INITIAL_CONFIG_FAILURE',
  '@app/FETCH_INITIAL_CONFIG_CANCEL'
)<void, Config, HttpError, void>();

export type ConfigAction = ActionType<typeof fetchInitialConfig>;
