import { concat, empty, Observable, of, OperatorFunction } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ActionCreator } from 'typesafe-actions/dist/is-action-of';
import { HttpError } from '../models';
import { logout } from '../store/app/actions';
import { RootAction } from '../store/rootReducer';

export const errorFromResponse = (apiResponse: AjaxResponse): Observable<HttpError> =>
  of(apiResponse).pipe(
    // eslint-disable-next-line no-console
    tap((error) => process.env.NODE_ENV !== 'production' && console.log(error)),
    map(({ status, response }) =>
      response
        ? { status, ...response }
        : {
            // our own 'INVALID_TOKEN' exception is taken care of here
            status: status >= 0 ? status : 401,
            error: status === 0 ? 'Network Error' : 'Invalid Token',
          }
    )
  );

export const catchErrorAndHandleWithAction = <T, R>(
  action: (payload: HttpError) => R,
  options?: { skipLogout: boolean },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  other$?: Observable<any>
): OperatorFunction<T, T | R> =>
  catchError((response) =>
    errorFromResponse(response).pipe(
      switchMap((error) =>
        concat(
          of(action(error)),
          empty(),
          error.status === 401 || error.status === 403 ? of(logout.request()) : empty(), // dispatch logout action on 401
          // error.status === 401 && !(options && options.skipLogout) ? of(logout.request()) : empty(), // dispatch logout action on 401
          other$ || empty()
        )
      )
    )
  );

type Action = ActionCreator<{ type: string }>;
export const filterAction = <T extends Action>(actionCreator: T | T[]) => filter(isActionOf(actionCreator));

export const takeUntilAction = <T>(
  action$: Observable<RootAction>,
  action: Action | Action[]
): OperatorFunction<T, T> => takeUntil(action$.pipe(filterAction(action)));
