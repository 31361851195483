// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    CycleListDTO,
    DownloadTicket,
    FilterDTO,
    RecordingClientListDTO,
    TestRunEntityList,
} from '../models';

export interface DownloadZipUsingGETRequest {
    downloadKey: string;
}

export interface GenerateDownloadKeyUsingGETRequest {
    cycleId: string;
    testRunId: string;
}

export interface GetCyclesForMonthUsingGETRequest {
    month: number;
    year: number;
}

export interface GetCyclesUsingPOSTRequest {
    filterDTO: FilterDTO;
}

export interface GetTrainingsAndTestrunsUsingPOSTRequest {
    filterDTO: FilterDTO;
}

/**
 * no description
 */
export class DataExportControllerApi extends BaseAPI {

    /**
     * downloadZip
     */
    downloadZipUsingGET({ downloadKey }: DownloadZipUsingGETRequest): Observable<string>
    downloadZipUsingGET({ downloadKey }: DownloadZipUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<string>
    downloadZipUsingGET({ downloadKey }: DownloadZipUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<string>>
    downloadZipUsingGET({ downloadKey }: DownloadZipUsingGETRequest, opts?: OperationOpts): Observable<string | RawAjaxResponse<string>> {
        throwIfNullOrUndefined(downloadKey, 'downloadZipUsingGET');

        return this.request<string>({
            url: '/export/download/{downloadKey}'.replace('{downloadKey}', encodeURI(downloadKey)),
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * generateDownloadKey
     */
    generateDownloadKeyUsingGET({ cycleId, testRunId }: GenerateDownloadKeyUsingGETRequest): Observable<DownloadTicket>
    generateDownloadKeyUsingGET({ cycleId, testRunId }: GenerateDownloadKeyUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<DownloadTicket>
    generateDownloadKeyUsingGET({ cycleId, testRunId }: GenerateDownloadKeyUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<DownloadTicket>>
    generateDownloadKeyUsingGET({ cycleId, testRunId }: GenerateDownloadKeyUsingGETRequest, opts?: OperationOpts): Observable<DownloadTicket | RawAjaxResponse<DownloadTicket>> {
        throwIfNullOrUndefined(cycleId, 'generateDownloadKeyUsingGET');
        throwIfNullOrUndefined(testRunId, 'generateDownloadKeyUsingGET');

        return this.request<DownloadTicket>({
            url: '/export/cycles/zip/{testRunId}/{cycleId}'.replace('{cycleId}', encodeURI(cycleId)).replace('{testRunId}', encodeURI(testRunId)),
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * getCyclesForMonth
     */
    getCyclesForMonthUsingGET({ month, year }: GetCyclesForMonthUsingGETRequest): Observable<CycleListDTO>
    getCyclesForMonthUsingGET({ month, year }: GetCyclesForMonthUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<CycleListDTO>
    getCyclesForMonthUsingGET({ month, year }: GetCyclesForMonthUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<CycleListDTO>>
    getCyclesForMonthUsingGET({ month, year }: GetCyclesForMonthUsingGETRequest, opts?: OperationOpts): Observable<CycleListDTO | RawAjaxResponse<CycleListDTO>> {
        throwIfNullOrUndefined(month, 'getCyclesForMonthUsingGET');
        throwIfNullOrUndefined(year, 'getCyclesForMonthUsingGET');

        return this.request<CycleListDTO>({
            url: '/export/cycles/{year}/{month}'.replace('{month}', encodeURI(month)).replace('{year}', encodeURI(year)),
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * getCycles
     */
    getCyclesUsingPOST({ filterDTO }: GetCyclesUsingPOSTRequest): Observable<CycleListDTO>
    getCyclesUsingPOST({ filterDTO }: GetCyclesUsingPOSTRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<CycleListDTO>
    getCyclesUsingPOST({ filterDTO }: GetCyclesUsingPOSTRequest, opts?: OperationOpts): Observable<RawAjaxResponse<CycleListDTO>>
    getCyclesUsingPOST({ filterDTO }: GetCyclesUsingPOSTRequest, opts?: OperationOpts): Observable<CycleListDTO | RawAjaxResponse<CycleListDTO>> {
        throwIfNullOrUndefined(filterDTO, 'getCyclesUsingPOST');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<CycleListDTO>({
            url: '/export/cycles',
            method: 'POST',
            headers,
            body: filterDTO,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * getRecordingClients
     */
    getRecordingClientsUsingGET(): Observable<RecordingClientListDTO>
    getRecordingClientsUsingGET(opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<RecordingClientListDTO>
    getRecordingClientsUsingGET(opts?: OperationOpts): Observable<RawAjaxResponse<RecordingClientListDTO>>
    getRecordingClientsUsingGET(opts?: OperationOpts): Observable<RecordingClientListDTO | RawAjaxResponse<RecordingClientListDTO>> {
        return this.request<RecordingClientListDTO>({
            url: '/export/recordingclients',
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * getTrainingsAndTestruns
     */
    getTrainingsAndTestrunsUsingPOST({ filterDTO }: GetTrainingsAndTestrunsUsingPOSTRequest): Observable<TestRunEntityList>
    getTrainingsAndTestrunsUsingPOST({ filterDTO }: GetTrainingsAndTestrunsUsingPOSTRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TestRunEntityList>
    getTrainingsAndTestrunsUsingPOST({ filterDTO }: GetTrainingsAndTestrunsUsingPOSTRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestRunEntityList>>
    getTrainingsAndTestrunsUsingPOST({ filterDTO }: GetTrainingsAndTestrunsUsingPOSTRequest, opts?: OperationOpts): Observable<TestRunEntityList | RawAjaxResponse<TestRunEntityList>> {
        throwIfNullOrUndefined(filterDTO, 'getTrainingsAndTestrunsUsingPOST');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<TestRunEntityList>({
            url: '/export/trainingsandtestruns',
            method: 'POST',
            headers,
            body: filterDTO,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

}
