import { TrainingTableDetailModal } from './TrainingTableDetailModal';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LastPageIcon from '@material-ui/icons/LastPage';
import PauseIcon from '@material-ui/icons/Pause';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';

import { Table, TableBody, TableHeadRow } from '.';
import { END_RECORDING_STR, PAUSE_RECORDING_STR, START_RECORDING_STR, TRAINING_LIST_TABLE_HEADERS } from '../constants';
import {
  DetailsWithRecordingClientQuery,
  SelectRecordingClientModalProps,
  Training,
  NamedActionStatus,
} from '../models';

import { createUrlWithParams, sitemap } from '../routes';
import { RowType } from './Table';

type Props = {
  trainings: Training[];
  shouldHideActions?: boolean;
  pauseTraining?: (trainingRecording: DetailsWithRecordingClientQuery) => void;
  stopTraining?: (trainingRecording: DetailsWithRecordingClientQuery) => void;
  trainingActionStatus?: NamedActionStatus;
  setModalStatus: React.Dispatch<React.SetStateAction<SelectRecordingClientModalProps>>;
};

export const TrainingTable: React.FC<Props> = ({
  trainings,
  shouldHideActions,
  pauseTraining,
  stopTraining,
  trainingActionStatus,
  setModalStatus,
}) => {
  const openSelectRecordingClientIdModal = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent> | React.MouseEvent<HTMLLIElement, MouseEvent>,
    id: string,
    recordingClientId?: string
  ) => {
    e?.stopPropagation();
    e?.preventDefault();
    setModalStatus({ id: id, recordingClientId: recordingClientId });
  };

  const [detailModalId, setDetailModalId] = useState<string>(undefined);
  const openDetailModal = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, id: string) => {
    e?.stopPropagation();
    e?.preventDefault();
    setDetailModalId(id);
  };
  const closeDetailModal = () => setDetailModalId(undefined);

  const createDetailViewPath = (data: RowType) =>
    createUrlWithParams(sitemap.training.label.path, {
      id: data.entityId,
    });

  return (
    <Table>
      {trainings?.length > 0 && !!detailModalId && (
        <TrainingTableDetailModal
          isOpen={!!detailModalId}
          onClose={closeDetailModal}
          training={trainings.find((training) => training.id === detailModalId)}
        />
      )}
      <TableHeadRow types={TRAINING_LIST_TABLE_HEADERS} />
      <TableBody
        data={trainings?.map<RowType>((training: Training) => ({
          entityId: training.id,
          recordingClientId: training.recordingClientId,
          cols: [
            { text: training.trainingNumber?.toString() },
            { text: training.carModel },
            { text: training.componentName },
            { text: training.metaInfo?.checkedFeature ?? "Vibration" },
            { text: moment(training.startDate).format('DD.MM.YYYY') },
            { text: training.actualCycleCount?.toString() },
            { text: training.labeledNotAnomalyCount?.toString() },
            { text: training.labeledAnomalyCount?.toString() },
            { text: <InfoIcon onClick={(e) => openDetailModal(e, training.id)} />, isDetail: true },
          ],
          isHighlighted: training.wasCreated,
          shouldHideActions: shouldHideActions,
          rowActions: !shouldHideActions
            ? [
              {
                name: START_RECORDING_STR,
                icon: <CheckCircleIcon />,
                callback: (e, handleClose) => {
                  handleClose();
                  openSelectRecordingClientIdModal(e, training.id, training.recordingClientId);
                },
              },
              {
                name: PAUSE_RECORDING_STR,
                icon: <PauseIcon />,
                callback: () => pauseTraining({ id: training.id, recordingClientId: training.recordingClientId }),
              },
              {
                name: END_RECORDING_STR,
                icon: <LastPageIcon />,
                callback: () => stopTraining({ id: training.id, recordingClientId: training.recordingClientId }),
              },
            ]
            : [],
        }))}
        type={TRAINING_LIST_TABLE_HEADERS}
        routeTo={createDetailViewPath}
        actionStatus={trainingActionStatus}
      />
    </Table>
  );
};
