import * as React from 'react';
import * as fromPages from './pages';

import { Redirect, Route, RouteProps, Switch, withRouter } from 'react-router';
import { logout, setUserRole } from './store/app';
import { useDispatch, } from 'react-redux';

import { AuthService } from './services';
import { ID_TOKEN } from './constants';
import { Main } from './layout';
import { NavigationHeader } from './components';
import { colors } from './colors';
import { fetchInitialConfig } from './store/config';
import { getLocalStorageValue } from './utils';
import { makeStyles } from '@material-ui/styles';
import { sitemap } from './routes';
import { useEffect } from 'react';

const useStyles = makeStyles(
  {
    '@global': {
      'html, body': {
        margin: 0,
        height: '100%',
        fontFamily: `'Porsche Next', 'Arial Narrow', Arial, sans-serif`,
        fontSize: 16,
        lineHeight: '26px',
        background: colors.surfaceLight,
      },
      h2: {
        fontSize: 28,
      },
      h3: {
        fontSize: 24,
      },
      h4: {
        fontSize: 18,
      },
      h5: {
        fontSize: 16,
      },
    },
    app: {},
  },
  { name: 'App' }
);

type RouteType = RouteProps & {
  path: string;
};

const AppComponent: React.FC = () => {
  const c = useStyles({});
  const dispatch = useDispatch();
  /**
   * fetch the applicationConfig at startup
   * TODO: -> cleanup config on logout
   */
  useEffect(() => {
    dispatch(fetchInitialConfig.request());
  }, [fetchInitialConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setUserRole(AuthService.getRoles(getLocalStorageValue<string>(ID_TOKEN))));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const routes: RouteType[] = [
    { path: sitemap.home.path, component: fromPages.Overview },
    { path: sitemap.testRuns.home.path, component: fromPages.TestRunList },
    { path: sitemap.testRuns.detail.path, component: fromPages.TestRunDetail },
    { path: sitemap.recordings.home.path, component: fromPages.CycleFailureList },
    { path: sitemap.recordings.detail.path, component: fromPages.CycleFailureDetail },
    { path: sitemap.login.path, component: fromPages.Login },
    { path: sitemap.clustering.path, component: fromPages.Clustering },
    { path: sitemap.training.record.path, component: fromPages.Training },
    { path: sitemap.training.label.path, component: fromPages.TrainingDetail },
    { path: sitemap.training.evaluate.path, component: fromPages.EvaluateTraining },
  ];
  return (
    <div className={c.app}>
      <Main header={<NavigationHeader logout={() => dispatch(logout.request())} />}>
        <div>
          <Switch>
            {routes.map((route) => (
              <Route exact key={route.path} {...route} />
            ))}
            <Redirect from="*" to={sitemap.home.path} />
          </Switch>
        </div>
      </Main>
    </div>
  );
};

export default withRouter(AppComponent);
