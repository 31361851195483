import * as React from 'react';

import { ALL_CYCLES, KEYDOWN_EVENT, SHOULD_LOOP_AUDIO_FILE, SHOW_IO } from '../constants';
import { Checkbox, TextArea } from '@porsche/ui-kit-react';
import { CustomSnackbar, CycleFailureTable, Header, LoadingMask } from '../components';
import { Cycle, SnackbarStatus } from '../models';
import { FormControlLabel, Tooltip, createStyles } from '@material-ui/core';
import { RouteComponentProps, match } from 'react-router';
import { createUrlWithParams, sitemap } from '../routes';
import {
  fetchCycleFailureDetail,
  fetchDownloadZipKey,
  getCurrentCycle,
  getCycleFailureDetailUpdateStatus,
  getIsDetailLoading,
  getZipDownloadStatus,
  getZipDownloadUrl,
  resetZipDownloadUrl,
  resetSnackbar,
  updateCycleFailureDetail,
} from '../store/cycle-failures';
import { getLocalStorageValue, setLocalStorageValue } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { CustomerRelevanceInfoModal } from '../components/CustomerRelevanceInfoModal';
import InfoIcon from '@material-ui/icons/Info';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Link } from 'react-router-dom';
import LoopIcon from '@material-ui/icons/Loop';
import { PButton } from '@porsche-design-system/components-react';
import { SliderToggle } from '../components/SliderToggle';
import cx from 'clsx';
import { getFilterButtonStyles } from './TestRunList';
import { isFeatureEnabled } from '../environments';
import { makeStyles } from '@material-ui/styles';
import { updateCycleFailure } from '../store/test-runs';
import {
    showAudioPlayButton,
} from '../store/config/selectors';


export const getFormStyles = () =>
  createStyles({
    textArea: {
      marginBottom: 28,
      minWidth: 300,
    },
    contentRow: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: ' space-between',
      marginTop: 50,
    },
  });
const useStyles = makeStyles(
  createStyles({
    ...getFormStyles(),
    header: getFilterButtonStyles().header,
    headerTitle: getFilterButtonStyles().headerTitle,
    contentHeader: {
      fontWeight: 'unset',
    },
    contentRowLast: {
      marginBottom: 117,
    },
    customerRelevantCheckbox: {
      marginBottom: 16,
    },
    customerRelevanceTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    customerRelevanceInfoIcon: {
      marginLeft: 10,
      cursor: 'pointer',
    },
    downloadArea: {
      maxWidth: 500,
    },
    downloadButton: {
      marginRight: 32,
    },

    margin: {
      marginBottom: 20,
    },
    recordingContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 28,
    },
    spectrogramImage: {
      width: 425,
    },
    spectrogramImagesContainer: {
      display: 'flex',
      marginRight: 60,
    },
    topRow: {
      marginTop: 47,
    },
    porscheStyleFix: {
      '&>.pui-Select': {
        '&>.pui-Select-control': {
          '&>.pui-Select-value': {
            marginLeft: '0.75rem',
          },
          '&>.pui-Select-label': {
            marginLeft: '0.75rem',
          },
          '&>.pui-Select-input': {
            marginLeft: '0.75rem',
          },
          '&>.pui-Select-placeholder': {
            marginLeft: '0.75rem',
          },
        },
      },
    },

    navigationWrapper: {
      display: 'inline-block',
      float: 'right',
    },
    arrowButtonLeft: {
      marginRight: 32,
    },
  }),
  { name: 'CycleFailureDetail' }
);

type CombinedProps = RouteComponentProps & {
  match: match<{ id: string; recordingClientId: string }>;
};

export const initialSnackbarStatus: SnackbarStatus = {
  isOpen: false,
  error: undefined,
};

export const CycleFailureDetail: React.FC<CombinedProps> = ({ match, history, location }) => {
  const c = useStyles({});
  const dispatch = useDispatch();
  const cycle = useSelector(getCurrentCycle);
  const isLoading = useSelector(getIsDetailLoading);
  const cycleFailureDetailUpdateStatus = useSelector(getCycleFailureDetailUpdateStatus);
  const zipDownloadStatus = useSelector(getZipDownloadStatus);
  const zipDownloadUrl = useSelector(getZipDownloadUrl);

  const [cycleDescription, setCycleDescription] = useState<string>('');

  const [isFromAllCycles, setIsFromAllCycles] = useState(false);
  const [showIORecordings, setShowIORecordings] = useState(getLocalStorageValue(SHOW_IO) === 'true');

  const showAudioPlay: boolean = useSelector(showAudioPlayButton);

  useEffect(() => {
    const typedState = location.state as { from: string };
    if (typedState?.from === ALL_CYCLES) {
      setIsFromAllCycles(true);
    }
  }, [location]);

  useEffect(() => {
    if (!!zipDownloadUrl) {
      //do fun stuff with opening new tab
      const downloadElement = document.createElement('a');
      downloadElement.setAttribute('href', zipDownloadUrl);
      downloadElement.setAttribute('target', '_blank');
      downloadElement.setAttribute('download', `${cycle.id}.zip`);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      downloadElement.remove();
    }
  }, [zipDownloadUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => () => {
    dispatch(resetSnackbar())
    dispatch(resetZipDownloadUrl())
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onLoadCycle = (event: KeyboardEvent) => {
    let cycleIdToLoad;
    const { keyCode } = event;
    switch (keyCode) {
      case 37:
        //left arrow
        cycleIdToLoad = cycle?.previousAnomalyId;
        break;
      case 39:
        //right arrow
        cycleIdToLoad = cycle?.nextAnomalyId;
        break;
    }

    if (cycleIdToLoad) {
      history.push({
        pathname: createUrlWithParams(sitemap.recordings.detail.path, {
          recordingClientId: match.params.recordingClientId,
          id: cycleIdToLoad,
        }),
      });
    }
  };
  const addOnKeyDown = () => document.addEventListener(KEYDOWN_EVENT, onLoadCycle, false);
  const removeOnKeyDown = () => document.removeEventListener(KEYDOWN_EVENT, onLoadCycle, false);

  useEffect(() => {
    removeOnKeyDown();
    addOnKeyDown();
    return () => removeOnKeyDown();
  }); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const entityId = match.params.id;
    const recordingClientId = match.params.recordingClientId;
    dispatch(
      fetchCycleFailureDetail.request({
        entityId,
        recordingClientId: recordingClientId,
        provideIo: showIORecordings,
      })
    );
  }, [match.params, showIORecordings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cycle || cycleFailureDetailUpdateStatus.error) {
      setCycleDescription(cycle.description);
    }
  }, [cycle, cycleFailureDetailUpdateStatus]);
  useEffect(() => {
    if (cycleFailureDetailUpdateStatus.isSuccess) {
      dispatch(updateCycleFailure(cycle));
    }
  }, [cycleFailureDetailUpdateStatus.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  const [shouldLoop, setShouldLoop] = useState(SHOULD_LOOP_AUDIO_FILE);

  const [snackbarState, setSnackbarState] = useState<SnackbarStatus>(initialSnackbarStatus);
  const closeSnackbar = () => setSnackbarState((prevState) => ({ ...prevState, isOpen: false }));

  useEffect(() => {
    setSnackbarState({
      isOpen: cycleFailureDetailUpdateStatus.isSuccess || !!cycleFailureDetailUpdateStatus.error,
      error: cycleFailureDetailUpdateStatus.error,
    });
  }, [cycleFailureDetailUpdateStatus.isSuccess, cycleFailureDetailUpdateStatus.error]);

  const [isRelevanceModalOpen, setIsRelevanceModalOpen] = useState(false);
  const openRelevanceModal = () => setIsRelevanceModalOpen(true);
  const closeRelevanceModal = () => setIsRelevanceModalOpen(false);

  const onCustomerRelevant = (isCustomerRelevant: boolean) => {
    const updatedTestCycle: Cycle = { ...cycle };
    updatedTestCycle.customerRelevant = isCustomerRelevant;
    dispatch(updateCycleFailureDetail.request({ ...updatedTestCycle, provideIo: showIORecordings }));
  };

  const onUpdateDescription = () => {
    if (!cycleFailureDetailUpdateStatus.isPending) {
      const updatedTestCycle: Cycle = { ...cycle };
      updatedTestCycle.description = cycleDescription ? cycleDescription : ' ';
      dispatch(updateCycleFailureDetail.request({ ...updatedTestCycle, provideIo: showIORecordings }));
    }
  };
  const onToggleLoop = () => {
    setShouldLoop((prevState) => !prevState);
  };
  const handleIOToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalStorageValue(SHOW_IO, event.target.checked.toString());
    setShowIORecordings(event.target.checked);
  };

  const createNavigation = () => {
    const LeftNode = !cycle?.previousAnomalyId ? 'span' : Link;
    const RightNode = !cycle?.nextAnomalyId ? 'span' : Link;
    return (
      <>
        <Tooltip title="Vorherige Aufnahme anzeigen">
          <LeftNode
            to={createUrlWithParams(sitemap.recordings.detail.path, {
              recordingClientId: match.params.recordingClientId,
              id: cycle?.previousAnomalyId,
            })}
          >
            <PButton
              loading={isLoading}
              disabled={!cycle?.previousAnomalyId}
              hideLabel="true"
              icon="arrow-head-left"
              className={c.arrowButtonLeft}
            />
          </LeftNode>
        </Tooltip>
        <Tooltip title="Nächste Aufnahme anzeigen">
          <RightNode
            to={createUrlWithParams(sitemap.recordings.detail.path, {
              recordingClientId: match.params.recordingClientId,
              id: cycle?.nextAnomalyId,
            })}
          >
            <PButton loading={isLoading} disabled={!cycle?.nextAnomalyId} hideLabel="true" icon="arrow-head-right" />
          </RightNode>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <Header
        navigationTitle={
          isFromAllCycles
            ? 'Zurück zu - Alle Aufnahmen'
            : `Zurück zu - Testlauf ${cycle?.humanReadableId?.substring(0, cycle?.humanReadableId?.lastIndexOf('_')) ?? ''
            }`
        }
        navigationPath={
          isFromAllCycles
            ? sitemap.recordings.home.path
            : createUrlWithParams(sitemap.testRuns.detail.path, { id: match?.params?.recordingClientId })
        }
        title={`Aufnahme - ${cycle?.humanReadableId ?? ''}`}
        headerTitleClass={c.headerTitle}
        headerClass={c.header}
      >
        <FormControlLabel
          control={<SliderToggle checked={showIORecordings} onChange={handleIOToggleChange} />}
          label="i.O. Aufnahmen einblenden"
        />
        <div className={c.navigationWrapper}>{createNavigation()}</div>
      </Header>

      <CustomerRelevanceInfoModal isOpen={isRelevanceModalOpen} onClose={closeRelevanceModal} />
      <LoadingMask isLoading={isLoading} />
      <CustomSnackbar
        isSnackbarOpen={snackbarState.isOpen}
        closeSnackbar={closeSnackbar}
        type={snackbarState.error ? 'error' : 'success'}
      >
        <>
          {snackbarState.error ? (
            <span id="message-id">{`Aufnahme konnte nicht bearbeitet werden: ${snackbarState.error.message ?? 'Unbekanter Fehler.'
              }`}</span>
          ) : (
            <span id="message-id">{`Aufnahme wurde erfolgreich bearbeitet.`}</span>
          )}
        </>
      </CustomSnackbar>
      {cycle && !isLoading && (
        <>
          <CycleFailureTable cycles={[cycle]} />
          <main>
            <div className={cx(c.topRow, c.contentRow)}>
              <div>
                <h3 className={c.contentHeader}>Spektrogramm</h3>
                <div className={c.spectrogramImagesContainer}>
                  <div className={c.spectrogramImage}>
                    <img
                      className={c.spectrogramImage}
                      alt={`Diese Aufnahme ${cycle.modelFinding ? '(n.i.O.)' : '(i.O.)'}`}
                      src={cycle.spectrogram}
                    />
                    <p>Diese Aufnahme {cycle.modelFinding ? '(n.i.O.)' : '(i.O.)'}</p>
                  </div>
                  <div className={c.spectrogramImage}>
                    <img className={c.spectrogramImage} alt="Referenz (i.O.)" src={cycle.referenceSpectrogram} />
                    <p>Referenz (i.O.)</p>
                  </div>
                </div>
              </div>
              <div>
                {showAudioPlay &&
                  <div>
                    <h3 className={c.contentHeader}>Aufnahme</h3>
                    {cycle.flacFile && (
                      <div className={c.recordingContainer}>
                        {shouldLoop ? (
                          <LastPageIcon onClick={onToggleLoop} titleAccess="Wiedergabe in Schleife stoppen" />
                        ) : (
                          <LoopIcon onClick={onToggleLoop} titleAccess="In Schleife wiedergeben" />
                        )}
                        <audio src={cycle.flacFile} preload="auto" controls loop={shouldLoop} />
                      </div>
                    )}
                  </div>
                }
                <div onFocus={removeOnKeyDown} onBlur={addOnKeyDown} style={showAudioPlay ? {} : { paddingTop: "115px" }}>
                  <TextArea
                    className={c.textArea}

                    name={'description'}
                    value={cycleDescription ?? ''}
                    placeholder={`${cycleDescription ? 'Beschreibung' : 'Beschreibung hinzufügen (optional)'}`}
                    onChange={(val) => setCycleDescription(val)}
                  />
                  <PButton
                    icon="edit"
                    onClick={onUpdateDescription}
                    loading={cycleFailureDetailUpdateStatus.isPending}
                    disabled={cycleFailureDetailUpdateStatus.isPending}
                  >
                    Speichern
                  </PButton>
                </div>
              </div>
            </div>
            <div className={c.contentRow}>
              <div>
                <h3 className={cx(c.contentHeader, c.customerRelevanceTitle)}>
                  Kundenrelevanz <InfoIcon className={c.customerRelevanceInfoIcon} onClick={openRelevanceModal} />
                </h3>
                <p>
                  Bestätigen Sie die Vorhersage des KI-Modells.
                  <br /> Die gezeigte Aufnahme ist:
                </p>
                <Checkbox
                  className={c.customerRelevantCheckbox}
                  onClick={() => onCustomerRelevant(true)}
                  checked={cycle.customerRelevant === true}
                  disabled={cycleFailureDetailUpdateStatus.isPending}
                >
                  kundenrelevant (n.i.O.)
                </Checkbox>
                <Checkbox
                  onClick={() => onCustomerRelevant(false)}
                  checked={cycle.customerRelevant === false}
                  disabled={cycleFailureDetailUpdateStatus.isPending}
                >
                  nicht Kundenrelevant (i.O.)
                </Checkbox>
              </div>
            </div>
            <div className={cx(c.contentRowLast, c.contentRow)}>
              <div className={c.downloadArea}>
                <h3 className={c.contentHeader}>{`Herunterladen${isFeatureEnabled('showMockImplementations') ? ' oder Teilen' : ''
                  }`}</h3>
                <p>
                  Es wird eine .zip-Datei heruntergeladen, die sowohl die Audio-Datei der{' '}
                  {cycle.modelFinding ? 'n.i.O.' : 'i.O.'}-Aufnahme, das Spektrogramm, als auch die Beschreibung
                  (optional) beinhaltet.
                </p>
                <PButton
                  className={c.downloadButton}
                  variant="tertiary"
                  icon="download"
                  loading={zipDownloadStatus.isPending}
                  onClick={() =>
                    dispatch(fetchDownloadZipKey.request({ cycleId: cycle.id, testRunId: cycle.testRunId }))
                  }
                >
                  Herunterladen
                </PButton>
                {isFeatureEnabled('showMockImplementations') && <PButton icon="share">Teilen</PButton>}
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};
