import * as React from 'react';
import { TestRunAnomalyDetailCard } from './TestRunAnomalyDetailCard';
import { TestRunInfo } from '../models';
import { makeStyles } from '@material-ui/core';
type Props = {
  latestTestRuns: TestRunInfo[];
};
const useStyles = makeStyles(
  {
    cardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  { name: 'TestRunAnomaliesOverview' }
);
export const TestRunAnomaliesOverview: React.FC<Props> = ({ latestTestRuns }) => {
  const c = useStyles({});
  return (
    <div className={c.cardContainer}>
      {latestTestRuns.map((testRunInfo) => (
        <TestRunAnomalyDetailCard {...testRunInfo} key={testRunInfo.humanReadableId} />
      ))}
    </div>
  );
};
