import { getType } from 'typesafe-actions';
import { Cycle, HttpError } from '../../models';
import { FilterRequestType } from '../../models/requestFilter.model';
import { replaceIn, withKey } from '../../utils';
import * as fromActions from './actions';

type State = {
  readonly cycles: Cycle[];
  readonly totalCycleFailures: number;
  readonly isLoading: boolean;
  readonly error: HttpError;

  readonly requestFilter: FilterRequestType;
  readonly previousRequestFilter: FilterRequestType;
  readonly isSameFilter: boolean;
};

const initialState: State = {
  cycles: undefined,
  totalCycleFailures: undefined,
  isLoading: false,
  error: undefined,

  requestFilter: undefined,
  previousRequestFilter: undefined,
  isSameFilter: false,
};
const ID_STR = 'id';

export const cycleFailureListReducer = (state = initialState, action: fromActions.CycleFailureAction): State => {
  switch (action.type) {
    /*
     *------------------------------- Fetch
     */
    case getType(fromActions.fetchCycleFailureList.request):
      return {
        ...state,
        isLoading: true,
        error: null,
        requestFilter: action.payload,
        previousRequestFilter: state.previousRequestFilter ? { ...state.requestFilter } : null,
        isSameFilter: state.requestFilter?.page > state.previousRequestFilter?.page,
      };
    case getType(fromActions.fetchCycleFailureList.success):
      return {
        ...state,
        isLoading: false,
        cycles: action.payload.cycles,
        totalCycleFailures: action.payload.total,
        previousRequestFilter: { ...state.requestFilter },
      };
    case getType(fromActions.fetchCycleFailureList.failure):
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        requestFilter: { ...state.previousRequestFilter },
      };

    /*
     *------------------------------- Triggered on updating a cycle failure detail
     */
    case getType(fromActions.updateCycleFailureDetail.success):
      return {
        ...state,
        cycles: replaceIn(state.cycles, withKey(action.payload.id, ID_STR), (item) => ({
          ...item,
          ...action.payload,
        })),
      };

    default:
      return state;
  }
};
