import { makeStyles } from '@material-ui/styles';
import * as d3 from 'd3';
import React, { useEffect } from 'react';
import { colors } from '../colors';
import {
  AXIS_MARGIN_INNER,
  BAR_PADDING,
  D3_TOOLTIP_ID,
  DIAGRAM_LEGEND_Y,
  FAILURE_DIAGRAM_HEIGHT,
  FAILURE_DIAGRAM_WIDTH,
} from '../constants';
import { TestRunDataHistory, TestRunDataHistoryBin } from '../models';

export type CycleAnomaliesBarChart = Array<{ bin: number; value: number, binStartTime: number }>;
export type TempAndHumidity = {
  [category: string]: { values: Array<{ cycleCounter: number; value: number }>; label: string };
};

type DiagramProps = {
  id: string;
  clusteredAnomalies: TestRunDataHistory;
  binCount: number;
};
const useStyles = makeStyles(
  {
    tooltip: {
      position: 'absolute',
      textAlign: 'left',
      width: 'auto',
      height: 'fit-content',
      padding: '6px 8px',
      fontSize: 16,
      background: colors.neutralGrey1,
      border: 0,
      borderRadius: 8,
      pointerEvents: 'none',
    },
    rotatedText: {
      transform: 'translate(-7px, 4px) rotate(-45deg)',
      fontFamily: `'Porsche Next', 'Arial Narrow', Arial, sans-serif`,
      fontSize: 12,
      textAnchor: 'end',
    },
    legendLabel: {
      fontSize: 13,
    },
    axisLabel: {
      fontSize: 24,
    },
    yAxisTicks: {
      fontSize: 16,
      fontFamily: `'Porsche Next', 'Arial Narrow', Arial, sans-serif`,
    },
  },
  { name: 'FailureDiagram' }
);


export const FailureDiagram: React.FC<DiagramProps> = ({ id, clusteredAnomalies, binCount }) => {
  const c = useStyles({});
  const formatDate = (dateString: string) => {
    const date = new Date(Date.parse(dateString));
    const minutes = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}, ${minutes} Uhr`;
  }

  useEffect(() => {
    //remove old diagram if available
    d3.selectAll(`#diagram-${id} > svg`).remove();
    d3.selectAll(`#${D3_TOOLTIP_ID}`).remove();
    const drawDiagram = () => {
      const margin = { top: 30, right: 60, bottom: 110, left: 120 },
        width = FAILURE_DIAGRAM_WIDTH - margin.left - margin.right,
        height = FAILURE_DIAGRAM_HEIGHT - margin.top - margin.bottom;


      const data = clusteredAnomalies.intervals;
      const totalAccessor = (d: TestRunDataHistoryBin) => d.total;
      const dateAccessor = (d: TestRunDataHistoryBin) => Date.parse(d.startDate);

      const x = d3.scaleTime()
        .domain([Date.parse(clusteredAnomalies.start), Date.parse(clusteredAnomalies.end)])
        .range([0, width])

      const y = d3.scaleLinear()
        .domain([0, d3.max(data, totalAccessor)])
        .range([height, 0])
        .nice()

      const tooltip = d3
        .select('body')
        .append('div')
        .attr('id', D3_TOOLTIP_ID)
        .attr('class', c.tooltip)
        .style('opacity', 0);

      const svg = d3
        .select('#diagram-' + id)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      // text label for the y axis
      svg
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('x', -40 - height / 2)
        .attr('y', 0 - margin.left)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .attr('class', c.axisLabel)
        .text('Anzahl');

      /**
       * Clustered Anomalies per cycle
       * */
      const barColor = colors.neutralGrey7;
      const nioColor = colors.porscheRed;

      const xAxisTicks = data.map((d) => dateAccessor(d));

      // Add the X Axis
      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(
          d3
            .axisBottom(x)
            .tickValues(xAxisTicks)
            .tickFormat((d: any) => formatDate(new Date(d).toISOString())) //@ts-ignore
        )
        .selectAll('text')
        .attr('class', c.rotatedText);

      //Label the X Axis
      svg
        .append('text')
        .attr('transform', 'translate(' + width / 2 + ' ,' + (height + margin.top + 110) + ')')
        .style('text-anchor', 'middle')
        .attr('class', c.axisLabel)
        .text('Zeit');

      const yAxisTicks = y.ticks().filter((tick) => Number.isInteger(tick));
      svg
        .append('g')
        .attr('stroke', barColor)
        .attr('class', c.yAxisTicks)
        .attr('transform', `translate(${-AXIS_MARGIN_INNER},0)`)
        .call(
          d3
            .axisLeft(y)
            .tickValues(yAxisTicks)
            .tickFormat(d3.format('d'))
        );


      //add clustered anomalies
      const binWidth = width / binCount - BAR_PADDING * binCount;
      const bins = svg.append("g")
      const binGroups = bins.selectAll("g")
        .data(data)
        .enter()
        .append("g")

      // add total bars
      binGroups
        .append('rect')
        .attr("x", d => x(dateAccessor(d)) + BAR_PADDING / 2)
        .attr("width", binWidth)
        .attr("y", d => y(totalAccessor(d)))
        .attr("height", d => {
          return height - y(totalAccessor(d));
        })
        .style('fill', barColor)

      // add nio bars
      binGroups.append("rect")
        .attr("x", d => x(dateAccessor(d)) + BAR_PADDING / 2)
        .attr("width", binWidth)
        .attr("y", d => y(d.nio))
        .attr("fill", nioColor)
        .attr("height", d => height - y(d.nio)
        )

      // add tooltip over
      binGroups.append("rect")
        .attr("x", d => x(dateAccessor(d)) + BAR_PADDING / 2)
        .attr("width", binWidth)
        .attr("y", d => totalAccessor(d) === 0 ? 0 : y(totalAccessor(d)))
        .attr("fill", "transparent")
        .attr("height", d => totalAccessor(d) === 0 ? height : height - y(totalAccessor(d))
        ).on('mouseover', function (d) {
          tooltip
            .transition()
            .duration(200)
            .style('opacity', 0.9);
          tooltip

            .html(`Prüfungen: ${d.total}
          <br>
          Davon Anomalien: ${d.nio}
          <br>
          Von: ${formatDate(d.startDate)}
          <br>
          Bis: ${formatDate(d.endDate)}
          `)
            .style('left', d3.event.pageX + 'px')
            .style('top', d3.event.pageY - 40 + 'px');
        })
        .on('mouseout', function (d) {
          tooltip
            .transition()
            .duration(500)
            .style('opacity', 0);
        })

      // Add Prüfungen legend
      svg
        .append('circle')
        .attr('cx', 20)
        .attr('cy', DIAGRAM_LEGEND_Y)
        .attr('r', 8.5)
        .style('fill', barColor);
      svg
        .append('text')
        .attr('x', 40)
        .attr('y', DIAGRAM_LEGEND_Y + 2)
        .text('Prüfungen')
        .attr('class', c.legendLabel)
        .attr('alignment-baseline', 'middle');


      // Add Anomalien legend
      svg
        .append('circle')
        .attr('cx', 120)
        .attr('cy', DIAGRAM_LEGEND_Y)
        .attr('r', 8.5)
        .style('fill', nioColor);
      svg
        .append('text')
        .attr('x', 140)
        .attr('y', DIAGRAM_LEGEND_Y + 2)
        .text('Anomalien')
        .attr('class', c.legendLabel)
        .attr('alignment-baseline', 'middle');

    };
    drawDiagram();
    return () => {
      d3.selectAll(`#diagram-${id} > svg`).remove();
      d3.selectAll(`#${D3_TOOLTIP_ID}`).remove();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={`diagram-${id}`}></div>;
};
