import * as H from 'history';

import { APP_PREFIX, DISPLAY_N_I_O_FILTER } from '../constants';
import { CognitoRole, ColumnName, User, UserRole } from '../models';
import { ConditionDTO, FilterDTO } from '../proxy';
import { FilterCondition, FilterRequestType } from '../models/requestFilter.model';

export const isEmptyObject = (obj: Record<string, unknown>): boolean =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const setLocalStorageValue = (key: string, value: Record<string, unknown> | string): void =>
  window.localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));

export const getLocalStorageValue = <T>(key: string): T =>
  JSON.parse(window.localStorage.getItem(`${APP_PREFIX}${key}`)) as T;

export const removeLocalStorageValue = (key: string): void => window.localStorage.removeItem(`${APP_PREFIX}${key}`);

export const withKey = <T>(targetId: string | number, key: string): ((item: T) => boolean) => (item: T): boolean =>
  targetId === item[key];

export const extractQueryParamAsJSON = (queryParams: string, queryKey: string): FilterCondition[] =>
  JSON.parse(decodeURI(queryParams.split(`?${queryKey}=`)[1]));

export const createFilterQueryParam = (filter: FilterRequestType): string =>
  `?filter=${JSON.stringify(filter.filterConditions)}`;

export const setFilterQueryParamsInURL = (
  filter: FilterRequestType,
  history: H.History<unknown>
): void => history.push({ search: createFilterQueryParam(filter) });

export const hasFilter = (lastRequestFilter: FilterRequestType) => {
  const filterConditionsCount = lastRequestFilter?.filterConditions?.length;
  if (filterConditionsCount === 1) {
    return lastRequestFilter?.filterConditions[0].name !== DISPLAY_N_I_O_FILTER.name;
  } else if (filterConditionsCount > 0) {
    return true;
  } else {
    return false;
  }
};

export const decodeURIEncodedFilterJSONStringAndEnhanceWithIOFilter = (defaultFilter: FilterRequestType, queryParams?: string, showIORecordings?: boolean) => {
  if (!queryParams) {
    return { ...defaultFilter, ...(showIORecordings && { filterConditions: [] }) };
  }
  return {
    ...defaultFilter,
    filterConditions: [
      ...extractQueryParamAsJSON(queryParams, 'filter'),
      ...(showIORecordings ? [] : [DISPLAY_N_I_O_FILTER]),
    ],
  };
};

export const replaceIn = <T>(target: T[], f: (item: T, idx: number) => boolean, modification?: (item: T) => T): T[] => {
  if (!target?.length) {
    return target;
  }

  const targetClone = [...target];
  const indexToRemove = targetClone.findIndex(f);
  const item = targetClone[indexToRemove] as T;
  // https://github.com/Microsoft/TypeScript/issues/14409
  if (indexToRemove >= 0) {
    if (modification) {
      targetClone.splice(indexToRemove, 1, modification(Object.assign({}, item)));
    } else {
      targetClone.splice(indexToRemove, 1);
    }
  }
  return targetClone;
};

/**
 * maps the filter-object received from formik to the one needed by the backend to process the dynamodb-request
 */
export const toFilterDTO = (filter: FilterRequestType): FilterDTO => {
  if (!filter) return {};
  const filterDTO = {
    filterConditions:
      filter.filterConditions &&
      filter.filterConditions.map(
        (c: FilterCondition): ConditionDTO => ({
          name: c.name,
          operator: c.operator,
          values: Array.isArray(c.values) ? c.values : [c.values],
        })
      ),
    size: filter?.size,
    page: filter?.page,
    sortedBy: filter?.sortedBy,
    sortDirection: filter?.sortDirection,
  };
  return filterDTO;
};

type RequestRefType = {
  lastRequestFilterRef: React.MutableRefObject<FilterRequestType>;
  totalResultsCountRef: React.MutableRefObject<number>;
};

export const onArrowKeyPagination = (
  event: KeyboardEvent,
  requestFilter: FilterRequestType,
  totalCount: number,
  fetchCallback: (
    event: React.MouseEvent<HTMLElement, MouseEvent> | KeyboardEvent,
    page: number,
    lastRequestFilterRef?: FilterRequestType
  ) => void
): void => {
  const { keyCode } = event;
  switch (keyCode) {
    case 37:
      //left arrow
      if (requestFilter?.page !== 1) {
        fetchCallback(event, requestFilter?.page - 1);
      }
      break;
    case 39:
      //right arrow
      if (requestFilter?.page * requestFilter?.size < totalCount) {
        fetchCallback(event, requestFilter?.page + 1);
      }
      break;
  }
};

export const mapRoleToUser = (roles: CognitoRole[] = []): User => {
  //we expect that only one role is ever assigned to a user - Cognito does not prevent this!
  const role: CognitoRole = roles?.find((role) => role.startsWith('role_'));
  switch (role) {
    case CognitoRole.ROLE_USER:
      return {
        userRole: UserRole.USER,
        canViewTraining: false,
        canAddRecordingClients: false,
        canDeleteTestRuns: false,
      };
    case CognitoRole.ROLE_ADMIN:
      return {
        userRole: UserRole.ADMIN,
        canViewTraining: true,
        canAddRecordingClients: true,
        canDeleteTestRuns: true,
      };

    default:
      return null;
  }
};


export const findValueInColumns = function (columnName: string, tenantColumnNames: Array<ColumnName>) {
  return tenantColumnNames.find((columnField) => columnField.fieldName === columnName)
    ?.value
}

export const generateDayStartEnd = function (): [string, string] {
  const today = new Date();

  const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0).toISOString();
  const todayEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59).toISOString();

  return [todayStart, todayEnd]
}
