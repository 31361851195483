import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../colors';
import { EMPTY_TEST_RUN_FILTER_STATE } from '../constants';
import { Summary, TestRunStatusEnum } from '../models';
import { sitemap } from '../routes';
import { TestRunService } from '../services';


export const getBorderedCardStyles = () =>
  createStyles({
    cardHeadingRight: {
      float: 'right',
    },
    cardHeading: {
      display: 'inline-block',
      margin: '13.5px 0 34px 0',
    },
    cardContent: {
      margin: '0 22.5px 28.5px 22.5px',
    },
    borderedCard: {
      border: `1px solid ${colors.cardBorder}`,
      boxSizing: 'border-box',
      width: 'fit-content',
      marginBottom: 34.5,
    },
    doughnut: {
      border: `6px solid ${colors.neutralGrey4}`,
      borderRadius: 45,
      height: 45,
      width: 45,
      fontSize: 21,
      fontWeight: 'bold',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '45px',
    },
    centeredDoughnut: {
      margin: '0 auto',
    },
    angelegt: {
      border: `6px solid ${colors.cardBorder}`,
    },
    in_betrieb: {
      border: `6px solid ${colors.snackbarSuccess}`,
    },
    standby: {
      border: `6px solid ${colors.standby}`,
    },
    pausiert: {
      border: `6px solid ${colors.porscheDarkRed}`,
    },
    offline: {
      border: `6px solid ${colors.neutralGrey4}`,
    },
    abgeschlossen: {
      border: `6px solid ${colors.neutralGrey8}`,
    },
    doughnutWrapper: {
      display: 'inline-block',
      marginRight: 33,
      '&:last-child': {
        marginRight: 0,
      },
    },
    doughnutHeader: {
      marginBottom: 0,
      fontWeight: 400,
      textTransform: 'capitalize',
    },
    clickableCard: {
      color: 'unset',
      textDecoration: 'none',
    },
    centeredText: {
      textAlign: 'center',
    },
  });


const useStyles = makeStyles(createStyles({ ...getBorderedCardStyles() }), { name: 'AllTestRunsOverview' });

const getFilteredPath = (filterType: string) => {
  const filter = { ...EMPTY_TEST_RUN_FILTER_STATE, values: [filterType] };

  return `${sitemap.testRuns.home.path}?filter=${JSON.stringify([filter])}`;
};

export const AllTestRunsOverview: React.FC<Summary> = ({ sortedByState }) => {
  const c = useStyles({});

  const displayedStates = [
    TestRunStatusEnum.RUNNING,
    TestRunStatusEnum.STANDBY,
    TestRunStatusEnum.PAUSED,
    TestRunStatusEnum.FINISHED
  ]

  const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
  const total = Object.values(TestRunStatusEnum).map((testRunState) => sortedByState[testRunState]).reduce(reducer, 0)
  return (
    <div className={c.borderedCard}>
      <div className={c.cardContent}>
        <Link className={c.clickableCard} to={sitemap.testRuns.home.path}>
          <div>
            <h4 className={c.cardHeading}>Alle Prüfstationen</h4>
            <h4 className={cx(c.cardHeading, c.cardHeadingRight)}>{`Gesamt: ${total}`}</h4>
          </div>
        </Link>
        {displayedStates.map((testRunState) => (
          <Link
            key={testRunState}
            className={cx(c.clickableCard, c.doughnutWrapper, c.centeredText)}
            to={getFilteredPath(testRunState)}
          >
            <div className={cx(c.doughnut, c.centeredDoughnut, c[TestRunService.toStatus(testRunState)])}>
              {sortedByState[testRunState]}
            </div>
            <h5 className={c.doughnutHeader}>{TestRunService.toStatus(testRunState).replace('_', ' ')}</h5>
          </Link>
        ))}
      </div>
    </div>
  );
};
