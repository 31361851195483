import { createSelector } from 'reselect';
import { NEW_TEST_RUN_FORM, TEST_RUN_CONTROL } from '../../constants';
import { RootState } from '../rootReducer';

const initialConfigState = (state: RootState) => state.configReducer;

const testRunListState = (state: RootState) => state.testRunListReducer;

export const getOverview = createSelector(testRunListState, (state) => state.overview);

export const getTestRuns = createSelector(testRunListState, (state) => state.testRuns);
export const getTotalTestRuns = createSelector(testRunListState, (state) => state.totalTestRuns);
export const newTestRunConfigurationsSelector = createSelector(initialConfigState, (state) =>
  state.config.formPrefillings?.find((op) => op.formName === NEW_TEST_RUN_FORM)
);

export const getTestRunControlPrefilling = createSelector(initialConfigState, (state) =>
  state.config.formPrefillings?.find((op) => op.formName === TEST_RUN_CONTROL)
);

export const getIsLoading = createSelector(testRunListState, (state) => state.isLoading);

export const getCreateTestRunStatus = createSelector(testRunListState, (state) => state.createTestRunStatus);
export const getStartTestRunStatus = createSelector(testRunListState, (state) => state.startTestRunStatus);
export const getPauseTestRunStatus = createSelector(testRunListState, (state) => state.pauseTestRunStatus);
export const getStopTestRunStatus = createSelector(testRunListState, (state) => state.stopTestRunStatus);

export const getLastRequestFilter = createSelector(testRunListState, (state) => state.requestFilter);

const testRunDetailState = (state: RootState) => state.testRunDetailReducer;

export const cycleFailuresForTestRun = createSelector(testRunDetailState, (state) => state.cycles);

export const getLastRequestFilterForTestRun = createSelector(testRunDetailState, (state) => state.requestFilter);

export const getCurrentTestRun = createSelector(testRunDetailState, (state) => state.currentTestRun);

export const getIsLoadingDetails = createSelector(testRunDetailState, (state) => state.isLoading);

export const getIsLoadingTestRunCycles = createSelector(testRunDetailState, (state) => state.isLoadingTestRunCycles);
export const getTotalTestRunCycles = createSelector(testRunDetailState, (state) => state.totalTestRunCycles);

export const getTestRunDetailUpdateState = createSelector(
  testRunDetailState,
  (state) => state.testRunDetailUpdateStatus
);

// Tenant Specific configuration
export const showNewTestrunButton = createSelector(initialConfigState, (state) => {
  return state.config.tenantSettings?.testingStation.showNewTestrunButton ?? false
});

export const testingStationColumnNames = createSelector(initialConfigState, (state) => {
  return state.config.tenantSettings?.testingStation.columnNames ?? []
});
