import { Environment } from './environment.model';

/* eslint-disable no-template-curly-in-string */
export const environment: Environment = {
  version: '${VERSION}',
  build: '${BUILD}',
  release: '${BUILD}-release',
  environment: 'production',
  cognitoUrl:
    'https://aaa.auth.eu-central-1.amazoncognito.com/login?client_id=4ru7e2e5ch54h82re9bd3lfgo6&response_type=token&scope=email+openid+profile&redirect_uri=https://sounce.porsche.digital/login/callback',
  features: {
    showMockImplementations: false,
  },
  apiEndpointUrl: 'https://api.sounce.porsche.digital',
};
