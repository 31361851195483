// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MetaInfo } from "./TestRunDTO";

/**
 * @export
 * @interface TestRunEntity
 */
export interface TestRunEntity {
    /**
     * @type {number}
     * @memberof TestRunEntity
     */
    actualCycleCount: number;
    /**
     * @type {string}
     * @memberof TestRunEntity
     */
    aiModelId?: string;
    /**
     * @type {number}
     * @memberof TestRunEntity
     */
    anomalyCount: number;
    /**
     * @type {string}
     * @memberof TestRunEntity
     */
    carModel: string;
    /**
     * @type {Date}
     * @memberof TestRunEntity
     */
    changeDate: Date;
    /**
     * @type {string}
     * @memberof TestRunEntity
     */
    componentName: string;
    /**
     * @type {string}
     * @memberof TestRunEntity
     */
    description?: string;
    /**
     * @type {Date}
     * @memberof TestRunEntity
     */
    endDate?: Date;
    /**
     * @type {string}
     * @memberof TestRunEntity
     */
    humanReadableId?: string;
    /**
     * @type {string}
     * @memberof TestRunEntity
     */
    id: string;
    /**
     * @type {number}
     * @memberof TestRunEntity
     */
    labelledAnomalyCount?: number;
    /**
     * @type {number}
     * @memberof TestRunEntity
     */
    labelledNotAnomalyCount?: number;
    /**
     * @type {boolean}
     * @memberof TestRunEntity
     */
    legalHold?: boolean;
    /**
     * @type {number}
     * @memberof TestRunEntity
     */
    plannedCycleCount?: number;
    /**
     * @type {string}
     * @memberof TestRunEntity
     */
    recordingClientId?: string;
    /**
     * @type {string}
     * @memberof TestRunEntity
     */
    recordingType?: string;
    /**
     * @type {Date}
     * @memberof TestRunEntity
     */
    startDate: Date;
    /**
     * @type {string}
     * @memberof TestRunEntity
     */
    state: TestRunEntityStateEnum;
    /**
     * @type {number}
     * @memberof TestRunEntity
     */
    trainingNumber?: number;
    /**
     * @type {string}
     * @memberof TestRunEntity
     */
    type: TestRunEntityTypeEnum;
    /**
     * @type {string}
     * @memberof MetaInfo
     */
    metaInfo?: MetaInfo;
}

/**
 * @export
 * @enum {string}
 */
export enum TestRunEntityStateEnum {
    CREATED = 'CREATED',
    RUNNING = 'RUNNING',
    STANDBY = 'STANDBY',
    PAUSED = 'PAUSED',
    FINISHED = 'FINISHED',
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE'
}
/**
 * @export
 * @enum {string}
 */
export enum TestRunEntityTypeEnum {
    TESTRUN = 'TESTRUN',
    TRAINING = 'TRAINING'
}

