// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    ConfigDTO,
} from '../models';

/**
 * no description
 */
export class ConfigurationControllerApi extends BaseAPI {

    /**
     * getConfiguration
     */
    getConfigurationUsingGET(): Observable<ConfigDTO>
    getConfigurationUsingGET(opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<ConfigDTO>
    getConfigurationUsingGET(opts?: OperationOpts): Observable<RawAjaxResponse<ConfigDTO>>
    getConfigurationUsingGET(opts?: OperationOpts): Observable<ConfigDTO | RawAjaxResponse<ConfigDTO>> {
        return this.request<ConfigDTO>({
            url: '/configuration',
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

}
