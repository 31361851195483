// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CycleUpdateDTO
 */
export interface CycleUpdateDTO {
    /**
     * @type {boolean}
     * @memberof CycleUpdateDTO
     */
    modelFinding?: boolean;
    /**
     * @type {string}
     * @memberof CycleUpdateDTO
     */
    description?: string;
    /**
     * @type {boolean}
     * @memberof CycleUpdateDTO
     */
    customerRelevant?: boolean;
    /**
     * @type {string}
     * @memberof CycleUpdateDTO
     */
    audioFile?: string;
    /**
     * @type {string}
     * @memberof CycleUpdateDTO
     */
    trainingLabel?: CycleUpdateDTOTrainingLabelEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CycleUpdateDTOTrainingLabelEnum {
    IO = 'IO',
    NIO = 'NIO',
    NONE = 'NONE'
}

