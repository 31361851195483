/* eslint-disable @typescript-eslint/no-explicit-any */
// import './polyfills';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { Store } from 'redux';
import App from './App';
import { store } from './store';
import '@porsche/ui-kit-react/css/index.css';
import { ThemeProvider } from '@material-ui/styles';
import { materialTheme } from './theme';
import { hotjar } from 'react-hotjar';

declare global {
  interface Window {
    store: Store;
    __PRELOADED_STATE__: any;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

hotjar.initialize(2874156, 6);

const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={materialTheme}>
      <Router history={history}>
        <App />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
