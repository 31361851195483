// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    FilterDTO,
    NewTrainingDTO,
    TrainingDTO,
    TrainingLabelDTO,
    TrainingListDTO,
    UpdateTrainingDTO,
} from '../models';

export interface CreateTrainingUsingPOSTRequest {
    newTraining: NewTrainingDTO;
}

export interface GetAllUsingPOST1Request {
    filterDTO: FilterDTO;
}

export interface GetTrainingByIdUsingGETRequest {
    trainingId: string;
    limit?: number;
    offset?: number;
}

export interface PauseTrainingUsingGETRequest {
    recordingClientId: string;
    trainingId: string;
}

export interface StartTrainingUsingGETRequest {
    recordingClientId: string;
    trainingId: string;
}

export interface StopTrainingUsingGETRequest {
    recordingClientId: string;
    trainingId: string;
}

export interface UpdateLabelsUsingPOSTRequest {
    trainingId: string;
    labels: Array<TrainingLabelDTO>;
}

export interface UpdateTrainingUsingPATCHRequest {
    trainingId: string;
    updates: UpdateTrainingDTO;
}

/**
 * no description
 */
export class TrainingControllerApi extends BaseAPI {

    /**
     * createTraining
     */
    createTrainingUsingPOST({ newTraining }: CreateTrainingUsingPOSTRequest): Observable<TrainingDTO>
    createTrainingUsingPOST({ newTraining }: CreateTrainingUsingPOSTRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TrainingDTO>
    createTrainingUsingPOST({ newTraining }: CreateTrainingUsingPOSTRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TrainingDTO>>
    createTrainingUsingPOST({ newTraining }: CreateTrainingUsingPOSTRequest, opts?: OperationOpts): Observable<TrainingDTO | RawAjaxResponse<TrainingDTO>> {
        throwIfNullOrUndefined(newTraining, 'createTrainingUsingPOST');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<TrainingDTO>({
            url: '/trainings/new',
            method: 'POST',
            headers,
            body: newTraining,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * getAll
     */
    getAllUsingPOST1({ filterDTO }: GetAllUsingPOST1Request): Observable<TrainingListDTO>
    getAllUsingPOST1({ filterDTO }: GetAllUsingPOST1Request, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TrainingListDTO>
    getAllUsingPOST1({ filterDTO }: GetAllUsingPOST1Request, opts?: OperationOpts): Observable<RawAjaxResponse<TrainingListDTO>>
    getAllUsingPOST1({ filterDTO }: GetAllUsingPOST1Request, opts?: OperationOpts): Observable<TrainingListDTO | RawAjaxResponse<TrainingListDTO>> {
        throwIfNullOrUndefined(filterDTO, 'getAllUsingPOST1');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<TrainingListDTO>({
            url: '/trainings',
            method: 'POST',
            headers,
            body: filterDTO,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * getTrainingById
     */
    getTrainingByIdUsingGET({ trainingId, limit, offset }: GetTrainingByIdUsingGETRequest): Observable<TrainingDTO>
    getTrainingByIdUsingGET({ trainingId, limit, offset }: GetTrainingByIdUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TrainingDTO>
    getTrainingByIdUsingGET({ trainingId, limit, offset }: GetTrainingByIdUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TrainingDTO>>
    getTrainingByIdUsingGET({ trainingId, limit, offset }: GetTrainingByIdUsingGETRequest, opts?: OperationOpts): Observable<TrainingDTO | RawAjaxResponse<TrainingDTO>> {
        throwIfNullOrUndefined(trainingId, 'getTrainingByIdUsingGET');

        const limitParam = String(limit) ? `limit=${limit}` : "";
        const offsetParam = String(offset) ? `offset=${offset}` : "";

        const queryParams = (limitParam && offsetParam) ? `?${limitParam}&${offsetParam}` : "";

        return this.request<TrainingDTO>({
            url: `/trainings/{trainingId}${queryParams}`.replace('{trainingId}', encodeURI(trainingId)),
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * pauseTraining
     */
    pauseTrainingUsingGET({ recordingClientId, trainingId }: PauseTrainingUsingGETRequest): Observable<TrainingDTO>
    pauseTrainingUsingGET({ recordingClientId, trainingId }: PauseTrainingUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TrainingDTO>
    pauseTrainingUsingGET({ recordingClientId, trainingId }: PauseTrainingUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TrainingDTO>>
    pauseTrainingUsingGET({ recordingClientId, trainingId }: PauseTrainingUsingGETRequest, opts?: OperationOpts): Observable<TrainingDTO | RawAjaxResponse<TrainingDTO>> {
        throwIfNullOrUndefined(recordingClientId, 'pauseTrainingUsingGET');
        throwIfNullOrUndefined(trainingId, 'pauseTrainingUsingGET');

        return this.request<TrainingDTO>({
            url: '/trainings/{trainingId}/pause/{recordingClientId}'.replace('{recordingClientId}', encodeURI(recordingClientId)).replace('{trainingId}', encodeURI(trainingId)),
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * startTraining
     */
    startTrainingUsingGET({ recordingClientId, trainingId }: StartTrainingUsingGETRequest): Observable<TrainingDTO>
    startTrainingUsingGET({ recordingClientId, trainingId }: StartTrainingUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TrainingDTO>
    startTrainingUsingGET({ recordingClientId, trainingId }: StartTrainingUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TrainingDTO>>
    startTrainingUsingGET({ recordingClientId, trainingId }: StartTrainingUsingGETRequest, opts?: OperationOpts): Observable<TrainingDTO | RawAjaxResponse<TrainingDTO>> {
        throwIfNullOrUndefined(recordingClientId, 'startTrainingUsingGET');
        throwIfNullOrUndefined(trainingId, 'startTrainingUsingGET');

        return this.request<TrainingDTO>({
            url: '/trainings/{trainingId}/start/{recordingClientId}'.replace('{recordingClientId}', encodeURI(recordingClientId)).replace('{trainingId}', encodeURI(trainingId)),
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * stopTraining
     */
    stopTrainingUsingGET({ recordingClientId, trainingId }: StopTrainingUsingGETRequest): Observable<TrainingDTO>
    stopTrainingUsingGET({ recordingClientId, trainingId }: StopTrainingUsingGETRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TrainingDTO>
    stopTrainingUsingGET({ recordingClientId, trainingId }: StopTrainingUsingGETRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TrainingDTO>>
    stopTrainingUsingGET({ recordingClientId, trainingId }: StopTrainingUsingGETRequest, opts?: OperationOpts): Observable<TrainingDTO | RawAjaxResponse<TrainingDTO>> {
        throwIfNullOrUndefined(recordingClientId, 'stopTrainingUsingGET');
        throwIfNullOrUndefined(trainingId, 'stopTrainingUsingGET');

        return this.request<TrainingDTO>({
            url: '/trainings/{trainingId}/stop/{recordingClientId}'.replace('{recordingClientId}', encodeURI(recordingClientId)).replace('{trainingId}', encodeURI(trainingId)),
            method: 'GET',
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
     * updateLabels
     */
    updateLabelsUsingPOST({ trainingId, labels }: UpdateLabelsUsingPOSTRequest): Observable<TrainingDTO>
    updateLabelsUsingPOST({ trainingId, labels }: UpdateLabelsUsingPOSTRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TrainingDTO>
    updateLabelsUsingPOST({ trainingId, labels }: UpdateLabelsUsingPOSTRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TrainingDTO>>
    updateLabelsUsingPOST({ trainingId, labels }: UpdateLabelsUsingPOSTRequest, opts?: OperationOpts): Observable<TrainingDTO | RawAjaxResponse<TrainingDTO>> {
        throwIfNullOrUndefined(trainingId, 'updateLabelsUsingPOST');
        throwIfNullOrUndefined(labels, 'updateLabelsUsingPOST');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<TrainingDTO>({
            url: '/trainings/{trainingId}/labels'.replace('{trainingId}', encodeURI(trainingId)),
            method: 'POST',
            headers,
            body: labels,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

    /**
 * updateTraining
 */
    updateTrainingUsingPATCH({ trainingId, updates }: UpdateTrainingUsingPATCHRequest): Observable<TrainingDTO>
    updateTrainingUsingPATCH({ trainingId, updates }: UpdateTrainingUsingPATCHRequest, opts?: Pick<OperationOpts, 'progressSubscriber'>): Observable<TrainingDTO>
    updateTrainingUsingPATCH({ trainingId, updates }: UpdateTrainingUsingPATCHRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TrainingDTO>>
    updateTrainingUsingPATCH({ trainingId, updates }: UpdateTrainingUsingPATCHRequest, opts?: OperationOpts): Observable<TrainingDTO | RawAjaxResponse<TrainingDTO>> {
        throwIfNullOrUndefined(trainingId, 'updateTrainingUsingPATCH');
        throwIfNullOrUndefined(updates, 'updateTrainingUsingPATCH');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<TrainingDTO>({
            url: '/trainings/{trainingId}'.replace('{trainingId}', encodeURI(trainingId)),
            method: 'PATCH',
            headers,
            body: updates,
            progressSubscriber: opts?.progressSubscriber,
        }, opts?.responseOpts);
    };

}
